import {
  IonAlert,
  IonButton,
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
} from "@ionic/react";
import { registerNewStaff } from "app/firebase";
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "app/slices/authSlice";
import { IOrgDetails, IStaff } from "app/variables";

import BackFooter from "components/BackFooter";
import { ModalHeader } from "components/InputComponents";
import { collection, doc, getDocs, getFirestore, query, updateDoc } from "firebase/firestore";
import { arrToStr, dupCheck, handleDistName } from "helpers/arrayToString";
import { fromDB } from "helpers/date";
import { allDistricts } from "helpers/districts";
import { addCircleOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { Layout } from "./Page";
import { useTranslation } from "react-i18next";
import { defaultNewStaff } from "app/defaultValues";

const defaultAlert = { message: "", buttons: [{ text: "", handler: () => {} }] };

function StaffManagement() {
  const authUser = useAppSelector(selectAuthUser);
  const [isLoading, setIsLoading] = useState(false);
  const [filterType, setFilterType] = useState<"active" | "disabled">("active");
  const [allStaff, setAllStaff] = useState<IStaff[]>([]);
  const [allOrgs, setAllOrgs] = useState<IOrgDetails[]>([]);
  const [districtModal, setDistrictModal] = useState(false);
  const [orgsModal, setOrganisationModal] = useState(false);
  const [newStaff, setNewStaff] = useState<IStaff | null>(null);
  const [viewStaff, setViewStaff] = useState<IStaff | null>(null);
  const [alertDetails, setAlertDetails] = useState(defaultAlert);
  const [dSearch, setDSearch] = useState("");
  const [pw, setPw] = useState("");
  const { t } = useTranslation();

  const fetchAllStaff = useCallback(async () => {
    setIsLoading(true);
    const db = getFirestore();
    const q = query(collection(db, `staffs`));
    try {
      const sss = await getDocs(q);
      let temp: IStaff[] = [];
      sss.forEach((i) => {
        const data = fromDB(i.data());
        temp.push({ ...data, id: i.id } as IStaff);
      });
      setAllStaff(temp);
    } catch (error) {
      console.error(Error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchAllOrganisations = useCallback(async () => {
    if (!!allOrgs.length) return;
    setIsLoading(true);
    const db = getFirestore();
    const q = query(collection(db, `organizations`));
    try {
      const sss = await getDocs(q);
      let temp: IOrgDetails[] = [];
      sss.forEach((i) => {
        const data = fromDB(i.data());
        temp.push({ ...data, id: i.id } as IOrgDetails);
      });
      setAllOrgs(temp);
    } catch (error) {
      console.error(Error);
    } finally {
      setIsLoading(false);
    }
  }, [allOrgs.length]);

  useEffect(() => {
    fetchAllStaff();
    fetchAllOrganisations();
  }, [fetchAllOrganisations, fetchAllStaff]);

  const handleDisableStaff = useCallback(async () => {
    if (!viewStaff) return;
    const db = getFirestore();
    setIsLoading(true);
    try {
      await updateDoc(doc(db, `staffs/${viewStaff.id}`), { disabled: !viewStaff.disabled });
      let temp: IStaff[] = [...allStaff];
      temp.splice(temp.indexOf(viewStaff), 1, { ...viewStaff, disabled: !viewStaff.disabled });
      setAllStaff(temp);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setAlertDetails(defaultAlert);
      setViewStaff(null);
    }
  }, [allStaff, viewStaff]);

  const closeSubModals = useCallback(() => {
    setDistrictModal(false);
    setOrganisationModal(false);
    setDSearch("");
  }, []);

  const handleCreateNewStaff = useCallback(async () => {
    if (authUser?.role !== "super-admin") return;
    if (!newStaff) return;
    const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,24}$/;

    if (!regex.test(pw)) {
      setAlertDetails({
        message: "Password must contain at least 1 capital letter, 1 small letter, 1 number, 1 special character and 8 characters in length",
        buttons: [{ text: "OK", handler: () => setAlertDetails(defaultAlert) }],
      });
    } else {
      setIsLoading(true);
      try {
        const res = await registerNewStaff(newStaff, pw);
        if (res === "success") {
          setAlertDetails({
            message: "New staff created",
            buttons: [{ text: "OK", handler: () => setAlertDetails(defaultAlert) }],
          });
          setAllStaff([...allStaff, newStaff]);
          setNewStaff(null);
          setPw("");
        } else {
          setAlertDetails({
            message: res,
            buttons: [{ text: "OK", handler: () => setAlertDetails(defaultAlert) }],
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  }, [allStaff, authUser?.role, newStaff, pw]);

  // const handleResetPassword = useCallback(async () => {
  // RESET PASSWORD FUNCTION HERE
  // staff = viewStaff
  // can use viewStaff.id / viewStaff.email
  // }, []);

  return (
    <Layout
      name={t("m-StaffMan")}
      extraButton={
        <IonButton slot="end" className="ion-margin-horizontal" onClick={() => setNewStaff(defaultNewStaff)}>
          <IonIcon icon={addCircleOutline} slot={"icon-only"} />
          {window.innerWidth > 470 && <IonText className="ml-1">New staff</IonText>}
        </IonButton>
      }
    >
      <IonCard>
        <IonRow>
          <IonCol>
            <IonSegment color={`primary`} value={filterType} onIonChange={(e) => setFilterType(e.detail.value as "active" | "disabled")}>
              <IonSegmentButton value="active">
                <IonLabel>Active Accounts</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="disabled">
                <IonLabel>Disabled accounts</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
      </IonCard>
      <IonList>
        {!!allStaff.length &&
          allStaff
            .filter((i) => (filterType === "active" ? !i.disabled : i.disabled))
            .map((i) => (
              <IonItem key={i.id} slot="content" button onClick={() => setViewStaff(i)}>
                <IonLabel>
                  <h3>{i.email}</h3>
                  <p>{i.communityType === "nationwide" ? `${arrToStr(i.organisation)} (Nationwide)` : ""}</p>
                  <p>{!!i.district.length ? `District(s): ${arrToStr(i.district)}` : ""}</p>
                </IonLabel>
                {i.role === "super-admin" && (
                  <IonChip slot="end" color="danger">
                    Super admin
                  </IonChip>
                )}
              </IonItem>
            ))}
      </IonList>

      {/* New staff modal */}
      <IonModal
        isOpen={!!newStaff}
        onDidDismiss={() => {
          setNewStaff(null);
          setPw("");
        }}
      >
        <ModalHeader
          title="Create new staff"
          closeButton
          closeFunction={() => {
            setNewStaff(null);
            setPw("");
          }}
        />
        {!!newStaff && (
          <IonContent>
            <IonList>
              <IonItem>
                <IonLabel position="floating" className="font">
                  Organisations (choose from existing)
                </IonLabel>
                <IonInput value={arrToStr(newStaff.organisation)} onIonFocus={() => setOrganisationModal(true)} />
              </IonItem>
              <IonItem>
                <IonLabel className="font">Community Type</IonLabel>
                <IonCol size="6" slot="end">
                  <IonButton expand="block" color={newStaff.communityType === "local" ? "primary" : "medium"} onClick={() => setNewStaff({ ...newStaff, communityType: "local" })}>
                    Local
                  </IonButton>
                  <IonButton expand="block" color={newStaff.communityType === "regional" ? "primary" : "medium"} onClick={() => setNewStaff({ ...newStaff, communityType: "regional" })}>
                    Regional
                  </IonButton>
                  <IonButton
                    expand="block"
                    color={newStaff.communityType === "nationwide" ? "primary" : "medium"}
                    onClick={() => setNewStaff({ ...newStaff, communityType: "nationwide", district: [] })}
                  >
                    Nationwide
                  </IonButton>
                </IonCol>
              </IonItem>
              <IonItem>
                <IonLabel position="floating" className="font">
                  District Name (Ignore if community type is Nationwide)
                </IonLabel>
                <IonInput value={arrToStr(newStaff.district.map((i) => handleDistName(i)))} onIonFocus={() => setDistrictModal(true)} disabled={newStaff.communityType === "nationwide"} />
              </IonItem>
              <IonItem>
                <IonLabel position="floating" className="font">
                  Email
                </IonLabel>
                <IonInput value={newStaff.email} onIonChange={(e) => setNewStaff({ ...newStaff, email: e.detail.value! })} />
              </IonItem>
              <IonItem>
                <IonLabel position="floating" className="font">
                  Password
                </IonLabel>
                <IonInput value={pw} onIonChange={(e) => setPw(e.detail.value!)} />
              </IonItem>
            </IonList>
          </IonContent>
        )}
        <IonFooter>
          <IonButton expand="block" color="primary" onClick={handleCreateNewStaff} disabled={!newStaff || !newStaff.email || !pw}>
            Submit
          </IonButton>
          <IonButton
            expand="block"
            color="medium"
            onClick={() =>
              setAlertDetails({
                message: "Confirm to cancel?",
                buttons: [
                  {
                    text: "Confirm",
                    handler: () => {
                      setNewStaff(null);
                      setPw("");
                    },
                  },
                  { text: "Cancel", handler: () => setAlertDetails(defaultAlert) },
                ],
              })
            }
          >
            Cancel
          </IonButton>
        </IonFooter>
      </IonModal>

      {/* View staff modal */}
      <IonModal isOpen={!!viewStaff ? true : false} onDidDismiss={() => setViewStaff(null)}>
        <ModalHeader title="View staff" closeButton closeFunction={() => setViewStaff(null)} />
        {viewStaff && (
          <IonContent>
            <IonList>
              <StaffRow disabled name={"Organisation(s)"} value={arrToStr(viewStaff.organisation)} />
              <StaffRow disabled name={"User role"} value={viewStaff.role} />
              <StaffRow disabled name={"Email"} value={viewStaff.email} />
              <StaffRow disabled name={"Community Type"} value={viewStaff.communityType.toLocaleUpperCase()} />
              {viewStaff.communityType === "local" && <StaffRow disabled name={"District(s)"} value={arrToStr(viewStaff.district)} />}
            </IonList>
          </IonContent>
        )}
        <IonFooter>
          {/* <IonButton
            expand="block"
            color="warning"
            onClick={() =>
              setAlertDetails({
                message: "Confirm to reset staff password?",
                buttons: [
                  { text: "Confirm", handler: handleResetPassword },
                  { text: "Cancel", handler: () => setAlertDetails(defaultAlert) },
                ],
              })
            }
          >
            Reset staff password
          </IonButton> */}

          <IonButton
            expand="block"
            color={!viewStaff?.disabled ? "danger" : "success"}
            onClick={() =>
              setAlertDetails({
                message: !viewStaff?.disabled ? "Confirm to disable staff?" : "Confirm to re-enable staff?",
                buttons: [
                  { text: "Confirm", handler: handleDisableStaff },
                  { text: "Cancel", handler: () => setAlertDetails(defaultAlert) },
                ],
              })
            }
          >
            {!viewStaff?.disabled ? "Disable staff account" : "Re-enable staff"}
          </IonButton>
        </IonFooter>
      </IonModal>

      {/* // District Modal */}
      <IonModal isOpen={districtModal} onDidDismiss={closeSubModals}>
        <ModalHeader closeButton title={"Select district"} closeFunction={closeSubModals} />
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonInput placeholder={"Filter district"} value={dSearch} className="inputBorder" onIonChange={(e) => setDSearch(e.detail.value!)} />
              </IonCol>
            </IonRow>
            <IonItem>
              <IonCol>
                <IonText>Selected:</IonText>
                {newStaff?.district.map((i, idx) => (
                  <IonChip
                    key={"selected_" + i}
                    color="success"
                    onClick={() => {
                      let temp = [...newStaff.district];
                      temp.splice(idx, 1);
                      setNewStaff({ ...newStaff, district: temp });
                    }}
                  >
                    <IonText>{handleDistName(i)}</IonText>
                  </IonChip>
                ))}
              </IonCol>
            </IonItem>
            <IonRow>
              <IonCol>
                {allDistricts
                  .filter((i) => i.toLowerCase().includes(dSearch.toLowerCase()))
                  .filter((j) => !newStaff?.district.includes(j))
                  .map((district, idx) => (
                    <IonChip
                      key={district + idx}
                      color="tertiary"
                      onClick={() => {
                        if (!!newStaff) {
                          setNewStaff({ ...newStaff, district: dupCheck(district, newStaff.district) });
                          setDSearch("");
                        }
                      }}
                    >
                      <IonText>{handleDistName(district)}</IonText>
                    </IonChip>
                  ))}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <BackFooter onClick={closeSubModals} />
      </IonModal>

      {/* // Organisation Modal */}
      <IonModal isOpen={orgsModal} onDidDismiss={closeSubModals}>
        <ModalHeader closeButton title={"Select organisation"} closeFunction={closeSubModals} />
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonInput placeholder={"Organisation name"} value={dSearch} className="inputBorder" onIonChange={(e) => setDSearch(e.detail.value!)} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {allOrgs
                  .filter((i) => i.organizationName.toLowerCase().includes(dSearch.toLowerCase()))
                  .map((org) => (
                    <IonChip
                      key={org.id}
                      color="tertiary"
                      onClick={() => {
                        if (!!newStaff) {
                          setNewStaff({ ...newStaff, organisation: [org.organizationName], district: [...org.districts], communityType: org.communityType });
                          closeSubModals();
                        }
                      }}
                    >
                      <IonText>{org.organizationName}</IonText>
                    </IonChip>
                  ))}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <BackFooter onClick={closeSubModals} />
      </IonModal>

      <IonLoading isOpen={isLoading} message={"Loading"} />
      <IonAlert isOpen={!!alertDetails.message ? true : false} onDidDismiss={() => setAlertDetails(defaultAlert)} message={alertDetails.message} buttons={alertDetails.buttons} />
    </Layout>
  );
}

export default StaffManagement;

interface ViewStaffProps {
  value: string;
  name: string;
  disabled?: boolean;
}

export const StaffRow = (props: ViewStaffProps) => {
  return (
    <IonItem lines="none">
      <IonLabel position="floating">{props.name}</IonLabel>
      <IonInput disabled={props.disabled} value={props.value} />
    </IonItem>
  );
};
