import React from "react";
import { IonCard, IonCardContent, IonCardHeader, IonItem, IonList, IonText } from "@ionic/react";
import { IOrgDetails, preWrapTxt, orgCardInfo } from "app/variables";
import { arrToStr, handleDistName } from "helpers/arrayToString";
import { InfoFields } from "./InfoFields";

export const OrgCardContent = ({ org, showName }: { org: IOrgDetails; showName?: boolean }) => {
  return (
    <IonCardContent className="ion-no-padding">
      <IonList>
        {showName && <InfoFields name={preWrapTxt.orgName} value={org.organizationName} />}
        {!!org.districts.length && <InfoFields name={preWrapTxt.districts} value={arrToStr(org.districts.map((i) => handleDistName(i)))} />}
        {!!org.phone && <InfoFields name={"Phone"} value={org.phone} />}
        {!!org.whatsapp && <InfoFields name={orgCardInfo.whatsapp.name} value={org.whatsapp} />}
        {!!org.signal && <InfoFields name={orgCardInfo.signal.name} value={org.signal} />}
        {!!org.telegram && <InfoFields name={orgCardInfo.telegram.name} value={org.telegram} />}
        {!!org.address && <InfoFields name={preWrapTxt.address} value={org.address} />}

        {!!org.email1 && <InfoFields name={orgCardInfo.email.name} value={org.email1} tag={org.email1_label} />}
        {!!org.email2 && <InfoFields name={orgCardInfo.email2.name} value={org.email2} tag={org.email2_label} />}
        {!!org.email3 && <InfoFields name={orgCardInfo.email3.name} value={org.email3} tag={org.email3_label} />}
        {!!org.email4 && <InfoFields name={orgCardInfo.email3.name} value={org.email4} tag={org.email4_label} />}

        {!!org.facebook && <InfoFields name={orgCardInfo.facebook.name} value={org.facebook} />}
        {!!org.instagram && <InfoFields name={orgCardInfo.instagram.name} value={org.instagram} />}
        {!!org.twitter && <InfoFields name={orgCardInfo.twitter.name} value={org.twitter} />}
        {!!org.youtube && <InfoFields name={orgCardInfo.youtube.name} value={org.youtube} />}
        {!!org.website && <InfoFields name={orgCardInfo.website.name} value={org.website} />}
        {!!org.website2 && <InfoFields name={orgCardInfo.website2.name} value={org.website2} />}
        {!!org.website3 && <InfoFields name={orgCardInfo.website3.name} value={org.website3} />}

        {!!org.eventbrite && <InfoFields name={orgCardInfo.eventbrite.name} value={org.eventbrite} />}

        {!!org.description && <InfoFields name={preWrapTxt.description} value={org.description} />}
      </IonList>
      <br />
    </IonCardContent>
  );
};

export const OrgCard = ({ org }: { org: IOrgDetails }) => {
  return (
    <IonCard className="ion-no-padding">
      <IonCardHeader className="ion-no-padding">
        <IonItem lines="none">
          <IonText>
            <h5>
              <b>{`${org.organizationName}`}</b>
            </h5>
          </IonText>
        </IonItem>
      </IonCardHeader>
      <OrgCardContent org={org} />
    </IonCard>
  );
};
