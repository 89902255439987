// Import the functions you need from the SDKs you need
import axios from "axios";
import dayjs from "dayjs";
import { FirebaseError, deleteApp, getApps, initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { AuthError, AuthErrorCodes, createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import randomize from "randomatic";
import { fromDB } from "../helpers/date";
import { setAuthUser, setAuthUserIsInit } from "./slices/authSlice";
import { store } from "./store";
import { IAnnouncement, IContactDev, IEventDetails, IIntEvent, IOrgDetails, IStaff } from "./variables";
import { setAllNWOrgs } from "./slices/orgSlices";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_AREACT_APP_MESSAGE_SENDER_IDPP_,
  appId: process.env.REACT_APP_APP_ID,
};

export const collections = {
  contactDev: "contactDev",
  events: "events",
  intEvents: "int-events",
  notify: "notify",
  organizations: "organizations",
  staffEnquiries: "staffEnquiries",
  staffs: "staffs",
  announcements: "announcements",
};

// export const app = initializeApp(firebaseConfig);

export const initFirebase = async () => {
  if (getApps().length <= 0) {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // console.log(`New Firebase App initialised`);
    const auth = getAuth(app);
    onAuthStateChanged(auth, async (user) => {
      // console.log("auth changed");
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        const db = getFirestore();
        const docRef = doc(db, collections.staffs, uid);
        const ss = await getDoc(docRef);

        if (ss.exists()) {
          const unSub = onSnapshot(docRef, async (sp) => {
            const data = fromDB(sp.data());
            const id = sp.id;
            const authStaff: IStaff = {
              id,
              ...data,
            } as IStaff;
            store.dispatch(setAuthUser(authStaff));
            store.dispatch(setAuthUserIsInit(true));
          });
          // GlobalListener();
        } else {
          console.log("no auth user found in DB");
          store.dispatch(setAuthUser(null));
          store.dispatch(setAuthUserIsInit(true));
        }
      } else {
        console.log("user signed out");
        store.dispatch(setAuthUser(null));
        store.dispatch(setAuthUserIsInit(true));
      }
    });
  }
};

export function isFirebaseError(obj: unknown): obj is FirebaseError {
  return obj instanceof Error && "code" in Error;
}

export const loginStaff = async (email: string, password: string, forMFA: boolean = false) => {
  const adminApp = initializeApp(firebaseConfig, "admin");
  let auth;
  if (forMFA) {
    auth = getAuth(adminApp);
  } else {
    auth = getAuth();
  }

  try {
    const userAuth = await signInWithEmailAndPassword(auth, email, password);

    if (!forMFA) {
      return "success";
    }

    await axios.post(`${process.env.REACT_APP_FUNCTIONS_API}/getMFA`, { uid: userAuth.user.uid, sender: process.env.REACT_APP_SENDGRID_SENDER });
    return {
      message: "pending_mfa",
      uid: userAuth.user.uid,
    };
  } catch (rawErr) {
    const error = rawErr as AuthError;
    const msg = error.message;
    // console.log(msg);
    if (msg.includes(AuthErrorCodes.USER_DELETED)) {
      return "User not found";
    }
    if (msg.includes(AuthErrorCodes.INVALID_PASSWORD)) {
      return "Incorrect email or password";
    }
    if (msg.includes(AuthErrorCodes.INVALID_EMAIL)) {
      return "Incorrect email or password";
    }
    return "Unknown Error";
  }

  // const auth = getAuth();
  // try {
  //   await signInWithEmailAndPassword(auth, email, password);
  //   return "success";
  // } catch (rawErr) {
  //   const error = rawErr as AuthError;
  //   const msg = error.message;
  //   // console.log(msg);
  //   if (msg.includes(AuthErrorCodes.USER_DELETED)) {
  //     return "User not found";
  //   }
  //   if (msg.includes(AuthErrorCodes.INVALID_PASSWORD)) {
  //     return "Incorrect email or password";
  //   }
  //   if (msg.includes(AuthErrorCodes.INVALID_EMAIL)) {
  //     return "Incorrect email or password";
  //   }
  //   return "Unknow Error";
  // }
};

export const logoutStaff = async () => {
  const auth = getAuth();
  await signOut(auth);
  return "success";
};

export const registerNewStaff = async (user: IStaff, password: string) => {
  // Create an admin app
  const adminApp = initializeApp(firebaseConfig, "temp");
  // get auth
  const auth = getAuth(adminApp);
  try {
    // create user in auth
    const userCredential = await createUserWithEmailAndPassword(auth, user.email, password);
    // create user in DB
    const { id, ...userData } = user;
    await setDoc(doc(getFirestore(), collections.staffs, userCredential.user.uid), {
      ...userData,
      vcode: {
        code: randomize(`000000`),
        expire: dayjs().toDate(),
      },
      createdAt: new Date(),
    });
    // delete admin app
    deleteApp(adminApp);
    return "success";
  } catch (error: any) {
    return error.code;
  }
};

export const uploadImage = async (file: File, to?: string) => {
  const storage = getStorage();
  const storageRef = ref(storage, `${to ? to : "images"}/${new Date().valueOf()}`);
  const res = await uploadBytes(storageRef, file);
  const url = await getDownloadURL(res.ref);
  return url;
};

export const addNewEvent = async (newEvent: IEventDetails) => {
  const db = getFirestore();
  try {
    const res = await addDoc(collection(db, collections.events), {
      ...newEvent,
      createdAt: new Date(),
    });
    return { msg: "success", id: res.id };
  } catch (error: any) {
    console.error(error);
    return error.code;
  }
};

export const addNewIntEvent = async (newEvent: IIntEvent) => {
  const db = getFirestore();
  try {
    const res = await addDoc(collection(db, collections.intEvents), {
      ...newEvent,
      createdAt: new Date(),
    });
    return { msg: "success", id: res.id };
  } catch (error: any) {
    console.error(error);
    return error.code;
  }
};

export const updateIntEvent = async (newEvent: IIntEvent) => {
  const db = getFirestore();
  const { eventId, ...data } = newEvent;
  try {
    await updateDoc(doc(db, `${collections.intEvents}/${eventId}`), { ...data });
    // await updateDoc(doc(db, `${collections.events}/${id}`), { ...data });
    return "success";
  } catch (error: any) {
    console.error(error);
    return error.code;
  }
};

export const getAllEvts = async () => {
  const q = query(collection(getFirestore(), collections.events), orderBy("createdAt", "desc"));
  try {
    const sss = await getDocs(q);
    let temp: IEventDetails[] = [];
    sss.forEach((i) => {
      const data = fromDB(i.data());
      temp.push({ ...data, eventId: i.id } as IEventDetails);
    });
    return { msg: "success", data: temp };
  } catch (error) {
    console.error(error);
  }
};
export const getAllIntEvts = async () => {
  const q = query(collection(getFirestore(), collections.intEvents), orderBy("createdAt", "desc"));
  try {
    const sss = await getDocs(q);
    let temp: IIntEvent[] = [];
    sss.forEach((i) => {
      const data = fromDB(i.data());
      temp.push({ ...data, eventId: i.id } as IIntEvent);
    });
    return { msg: "success", data: temp };
  } catch (error) {
    console.error(error);
  }
};
export const delEvt = async (evt: IEventDetails) => {
  const db = getFirestore();
  try {
    await deleteDoc(doc(db, collections.events, evt.eventId));
    return "success";
  } catch (error) {
    console.error(error);
  }
};

export const getEvtById = async (eventId: string) => {
  const db = getFirestore();
  const docRef = doc(db, collections.events, eventId);
  try {
    const res = await getDoc(docRef);
    return fromDB(res.data()) as IEventDetails;
  } catch (error) {
    console.error(error);
  }
};

export const editEvent = async (id: string, event: IEventDetails) => {
  const db = getFirestore();
  const { eventId, ...data } = event;
  try {
    await updateDoc(doc(db, `${collections.events}/${id}`), { ...data });
    return "success";
  } catch (error: any) {
    console.error(error);
    return error.code;
  }
};

export const getIntEvtById = async (eventId: string) => {
  const db = getFirestore();
  const docRef = doc(db, collections.intEvents, eventId);
  try {
    const res = await getDoc(docRef);
    return { ...fromDB(res.data()), eventId } as IIntEvent;
  } catch (error) {
    console.error(error);
  }
};

export const fetchAllNWOrgs = async () => {
  const db = getFirestore();
  const q = query(collection(db, collections.organizations), where("communityType", "==", "nationwide"), orderBy("organizationName", "asc"));
  try {
    const res = await getDocs(q);
    const temp: IOrgDetails[] = [];
    res.forEach((i) => {
      temp.push({ ...i.data(), id: i.id } as IOrgDetails);
    });
    store.dispatch(setAllNWOrgs(temp));
  } catch (error) {
    console.error(error);
  }
};

export const reportError = async (contactMsg: Omit<IContactDev, "id">) => {
  const db = getFirestore();
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    const userIp = res.data.ip;
    await addDoc(collection(db, collections.staffEnquiries), {
      admin: null,
      subject: contactMsg.subject,
      message: contactMsg.message,
      userIp,
      createdAt: new Date(),
    });
    await addDoc(collection(db, collections.contactDev), {
      ...contactMsg,
      userIp,
      createdAt: new Date(),
    });
    return "success";
  } catch (error: any) {
    console.error(error);
    return error.code as string;
  }
};

export const fetchAllOrgs = async () => {
  const db = getFirestore();
  const q = query(collection(db, "organizations"), orderBy("organizationName", "asc"));
  try {
    const sss = await getDocs(q);
    let temp: IOrgDetails[] = [];
    sss.forEach((ss) => {
      temp.push({ id: ss.id, ...ss.data() } as IOrgDetails);
    });
    return temp;
  } catch (error) {
    console.error(error);
  }
};

export const setNewAnnouncement = async (announcement: IAnnouncement) => {
  const DB = getFirestore();
  const { id, ...data } = announcement;
  const path = id === "new" ? doc(collection(DB, collections.announcements)) : doc(DB, `${collections.announcements}/${id}`);
  try {
    await setDoc(path, data, { merge: true });
    return "success";
  } catch (error: any) {
    console.error(error);
    return error.code as string;
  }
};

export const getAllAnnouncements = async () => {
  const q = query(collection(getFirestore(), collections.announcements), orderBy("createdAt", "desc"));
  try {
    const sss = await getDocs(q);
    const temp: IAnnouncement[] = [];
    sss.forEach((i) => {
      const data = fromDB(i.data());
      temp.push({ ...data, id: i.id } as IAnnouncement);
    });
    return { msg: "success", data: temp };
  } catch (error) {
    console.error(error);
  }
};

export const getAnnouncementById = async (id: string) => {
  const db = getFirestore();
  const docRef = doc(db, collections.announcements, id);
  try {
    const res = await getDoc(docRef);
    return { ...fromDB(res.data()), id } as IAnnouncement;
  } catch (error) {
    console.error(error);
  }
};

export const generalDelete = async (coll: string, id: string) => {
  const db = getFirestore();
  try {
    await deleteDoc(doc(db, `${coll}/${id}`));

    return "success";
  } catch (error: any) {
    console.error(error);
  }
};
