import {
  IonAccordion,
  IonAccordionGroup,
  IonAlert,
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "app/slices/authSlice";
import dayjs from "dayjs";
import { collection, getDocs, getFirestore, orderBy, query } from "firebase/firestore";
import { addCircleOutline, checkmarkCircleOutline, informationCircleOutline, searchOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import { useTranslation } from "react-i18next";
import Resizer from "react-image-file-resizer";
import { addNewEvent, uploadImage } from "../app/firebase";
import { EventDateFormat, EventDateType, GUnit, IEventDetails, IEventDetailsInput, IOrgDetails } from "../app/variables";
import { defaultLocationDetailInput, defaultNewDateInput } from "app/defaultValues";
import { getLongName } from "../helpers/address";
import { sortDatesAsc, sortEventsAsc } from "../helpers/date";
import { Layout } from "../pages/Page";
import BackFooter from "./BackFooter";
import { AddressInput, ModalHeader, StringInput } from "./InputComponents";
import { UploadFilesType } from "./NewIntEvent";
import { EvtPosterEdit } from "./EvtPosterDisplay";
import OrgSelectModal, { OrgFieldTypes, OrgSelect } from "./OrgSelectModal";

const newEMsg = "New event created";

function NewEvent() {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [newEvt, setNewEvt] = useState<IEventDetailsInput>(defaultLocationDetailInput);
  const [message, setMessage] = useState("");
  const [openInfo, setOpenInfo] = useState(false);
  const [tempRecurringDates, setTempRecurringDates] = useState([""]);
  const [tempRecurringTimes, setTempRecurringTimes] = useState(defaultNewDateInput);
  const [dateRange, setDateRange] = useState(defaultNewDateInput);
  const [dateIsComplete, setDateIsComplete] = useState(false);
  const [allOrgs, setAllOrgs] = useState<IOrgDetails[]>([]);
  const [orgType, setOrgType] = useState<OrgFieldTypes>(null);
  const [isLoading, setIsLoading] = useState(false);
  const uploadRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<UploadFilesType[]>([]);
  // const [file, setFile] = useState<File | null>(null);
  // const [imageData, setImageData] = useState("");
  const [addressSearch, setAddressSearch] = useState("");
  const [multiAddress, setMultiAddress] = useState<GUnit[]>([]);

  const handleReset = useCallback(() => {
    setDateRange(defaultNewDateInput);
    setTempRecurringDates([""]);
    setTempRecurringTimes(defaultNewDateInput);
    setNewEvt(defaultLocationDetailInput);
    setFiles([]);
  }, []);

  const compressImage = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        4000,
        4000,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const imageInput = async (files: FileList) => {
    const tmpFiles: UploadFilesType[] = [];
    for await (const file of files) {
      const compressed = await compressImage(file);
      if (compressed instanceof File) {
        const tmp: UploadFilesType = { imageData: URL.createObjectURL(compressed), file: compressed };
        tmpFiles.push(tmp);
      }
    }
    setFiles(tmpFiles);
  };

  const handleAddNewEvent = useCallback(async () => {
    if (!authUser) return;
    setIsLoading(true);
    let tmpEvt: any = { ...newEvt };
    const imgLinks: string[] = [];
    if (files && files.length) {
      for await (const f of files) {
        if (f.file) {
          const fileStr = await uploadImage(f.file);
          imgLinks.push(fileStr);
        }
      }
      tmpEvt.eventPoster = imgLinks;
    }
    try {
      let evtDates: EventDateFormat[] = [];
      let res: any;
      if (newEvt.eventDateType === "range") {
        const cutSDate = dayjs(dateRange.start).format("YYYY-MM-DD");
        const cutEDate = dayjs(dateRange.end).format("YYYY-MM-DD");
        const start = new Date(cutSDate.concat(dayjs(tempRecurringTimes.start).format("THH:mm")));
        const end = new Date(cutEDate.concat(dayjs(tempRecurringTimes.end).format("THH:mm")));
        evtDates.push({ start, end });
        tmpEvt.eventDates = evtDates;
        res = await addNewEvent({ ...tmpEvt, createdAt: new Date(), updatedAt: new Date(), updatedBy: authUser, lastDatetime: end });
      } else if (newEvt.eventDateType === "recurring") {
        let lastDate = new Date();
        for (let evtDate of tempRecurringDates) {
          const start = new Date(evtDate.concat(dayjs(tempRecurringTimes.start).format("THH:mm")));
          const end = new Date(evtDate.concat(dayjs(tempRecurringTimes.end).format("THH:mm")));
          lastDate = end;
          evtDates.push({ start, end });
        }
        tmpEvt.eventDates = evtDates;
        res = await addNewEvent({ ...tmpEvt, createdAt: new Date(), updatedAt: new Date(), updatedBy: authUser, lastDatetime: lastDate });
      } else if (newEvt.eventDateType === "flexible") {
        tmpEvt.eventDates = await sortEventsAsc(newEvt.eventDates, true);
        res = await addNewEvent({ ...tmpEvt, createdAt: new Date(), updatedAt: new Date(), updatedBy: authUser, lastDatetime: tmpEvt.eventDates[tmpEvt.eventDates.length - 1] });
      } else {
        const evtDateS = new Date(newEvt.eventDates[0].start);
        const evtDateE = new Date(dayjs(newEvt.eventDates[0].start).format("YYYY-MM-DD").concat(dayjs(newEvt.eventDates[0].end).format("THH:mm")));
        const tmp: IEventDetails = { ...tmpEvt, eventDates: [{ start: evtDateS, end: evtDateE }], createdAt: new Date(), updatedAt: new Date(), updatedBy: authUser, lastDatetime: evtDateE };
        res = await addNewEvent(tmp);
      }
      if (res.msg === "success" && !!res.id) {
        // handleReset();
        setMessage(newEMsg);
      } else {
        setMessage(res);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [authUser, dateRange.end, dateRange.start, files, newEvt, tempRecurringDates, tempRecurringTimes.end, tempRecurringTimes.start]);

  const fetchPostcode = useCallback(async () => {
    if (!addressSearch) return;
    try {
      const res = await Geocode.fromAddress(addressSearch);
      if (!res.results.length) return;
      if (res.results.length === 1) {
        if (res.status === "OK") {
          const temp = getLongName(res.results[0].address_components);
          setNewEvt({ ...newEvt, eventAddress: temp });
        }
      } else {
        let tempArr: GUnit[] = [];
        for (let i of res.results) {
          tempArr.push(getLongName(i.address_components));
        }
        setMultiAddress(tempArr);
      }
    } catch (error) {
      setMessage("No result");
    }
  }, [addressSearch, newEvt]);

  const handleFetchGeocode = useCallback(async () => {
    Geocode.fromAddress(`${newEvt.eventAddress.streetNo} ${newEvt.eventAddress.route}, ${newEvt.eventAddress.postcode}`).then((res) => {
      if (res.status === "OK") {
        const geo = res.results[0].geometry.location;
        setNewEvt({ ...newEvt, geolocation: { lat: geo.lat, lng: geo.lng } });
      }
    });
  }, [newEvt]);

  const handleFetchOrgs = useCallback(async () => {
    const db = getFirestore();
    const q = query(collection(db, "organizations"), orderBy("organizationName", "asc"));
    try {
      const sss = await getDocs(q);
      let temp: IOrgDetails[] = [];
      sss.forEach((ss) => {
        temp.push({ id: ss.id, ...ss.data() } as IOrgDetails);
      });
      setAllOrgs(temp);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleRemoveImage = (img: string) => {
    if (!files.length) return;
    const foundIdx = files.findIndex((i) => i.imageData === img);
    if (foundIdx >= 0) {
      const tmp = [...files];
      tmp.splice(foundIdx, 1);
      setFiles(tmp);
    }
  };

  useEffect(() => {
    if (!allOrgs.length) {
      handleFetchOrgs();
    }
  }, [allOrgs.length, handleFetchOrgs]);

  useEffect(() => {
    if (
      (newEvt.eventDateType === "single" && new Date(newEvt.eventDates[0].start) > new Date()) ||
      (newEvt.eventDateType === "range" && new Date(dateRange.start) > new Date() && new Date(dateRange.end) > new Date()) ||
      (newEvt.eventDateType === "recurring" && tempRecurringDates.length > 1) ||
      (newEvt.eventDateType === "flexible" && newEvt.eventDates.length > 1)
    ) {
      setDateIsComplete(true);
    } else {
      setDateIsComplete(false);
    }
  }, [dateRange.end, dateRange.start, newEvt.eventDateType, newEvt.eventDates, tempRecurringDates.length]);

  return (
    <Layout name={t("m-NewEvt")}>
      <IonGrid>
        <IonList>
          <IonItem lines={files.length ? "none" : "full"}>
            <IonLabel className="font">Event Poster</IonLabel>
            <IonButton slot="end" expand="block" onClick={() => uploadRef.current?.click()}>
              UPLOAD
            </IonButton>
            <input type="file" accept="image/*" style={{ display: "none" }} multiple ref={uploadRef} onChange={(e) => (e.target.files ? imageInput(e.target.files) : {})} />
          </IonItem>
          {!!files.length && <EvtPosterEdit eventPoster={files.map((i) => i.imageData)} isEdit onRemove={handleRemoveImage} />}
          <IonItem>
            <IonLabel position="floating" className="font">
              Event Name
            </IonLabel>
            <IonInput value={newEvt?.eventName} onIonChange={(e) => setNewEvt({ ...newEvt, eventName: e.detail.value! })} />
          </IonItem>
          <IonItem>
            <IonLabel position="floating" className="font">
              Event Name in Chinese
            </IonLabel>
            <IonInput value={newEvt?.eventNameChi} onIonChange={(e) => setNewEvt({ ...newEvt, eventNameChi: e.detail.value! })} />
          </IonItem>
          <OrgSelect name={"Organisation name"} value={newEvt?.organisation?.organizationName ?? ""} onFocus={() => setOrgType("host")} />
          <IonAccordionGroup>
            <IonAccordion value="evtAddress">
              <IonItem slot="header">
                <IonText>
                  <h5>
                    <b>Event Address</b>
                  </h5>
                </IonText>
                {!newEvt.geolocation.lat || !newEvt.geolocation.lng || !newEvt.eventAddress.postcode || !newEvt.eventAddress.route ? (
                  <IonCardSubtitle color="danger" className="ion-margin-horizontal">
                    REQUIRED
                  </IonCardSubtitle>
                ) : (
                  <IonIcon className="ion-margin-horizontal" icon={checkmarkCircleOutline} color="success" size={"large"} />
                )}
              </IonItem>
              <IonList slot="content">
                <IonItem>
                  <IonLabel position="floating" className="font">
                    Address search
                  </IonLabel>
                  <IonInput placeholder="Postcode or part of the address" value={addressSearch} onIonChange={(e) => setAddressSearch(e.detail.value!)} />

                  <IonButtons slot="end">
                    <IonButton disabled={!addressSearch} onClick={fetchPostcode} color="primary">
                      <IonIcon icon={searchOutline} />
                      SEARCH & COMPLETE
                    </IonButton>
                  </IonButtons>
                </IonItem>
                <AddressInput evt={newEvt} title={"Postcode"} field={"postcode"} onChange={setNewEvt} postcodeFunc={fetchPostcode} />
                <AddressInput evt={newEvt} title={"House no. / Street no."} field={"streetNo"} onChange={setNewEvt} />
                <AddressInput evt={newEvt} title={"Street name"} field={"route"} onChange={setNewEvt} />
                <AddressInput evt={newEvt} title={"Town"} field={"postalTown"} onChange={setNewEvt} />
                <AddressInput evt={newEvt} title={"Administrative area lv 2 (e.g. Greater London)"} field={"adminArea2"} onChange={setNewEvt} />
                <AddressInput evt={newEvt} title={"Administrative area lv 1 (England)"} field={"adminArea1"} onChange={setNewEvt} />
                <AddressInput evt={newEvt} title={"Country"} field={"country"} onChange={setNewEvt} />
                <IonList>
                  <IonItem slot="header" lines="none">
                    <IonLabel className="font">
                      Geolocation
                      <IonButton expand="block" disabled={!newEvt.eventAddress.postcode && !newEvt.eventAddress.route && !newEvt.eventAddress.streetNo} onClick={() => handleFetchGeocode()}>
                        Get my geo coordinates
                      </IonButton>
                    </IonLabel>
                  </IonItem>
                  <IonItem slot="content">
                    <IonCol>
                      <IonText color={!newEvt.geolocation.lat ? "danger" : "dark"}>
                        Lat:
                        {newEvt?.geolocation.lat ?? "Unknown"}
                      </IonText>
                    </IonCol>
                    <IonCol>
                      <IonText color={!newEvt.geolocation.lat ? "danger" : "dark"}>Lng: {newEvt?.geolocation.lng ?? "Unknown"}</IonText>
                    </IonCol>
                  </IonItem>
                </IonList>
              </IonList>
            </IonAccordion>
            <IonAccordion value="evtDate">
              <IonItem slot="header">
                <IonText>
                  <h5>
                    <b>Event Date & Time</b>
                  </h5>
                </IonText>
                {!dateIsComplete ? (
                  <IonCardSubtitle color="danger" className="ion-margin-horizontal">
                    REQUIRED
                  </IonCardSubtitle>
                ) : (
                  <IonIcon className="ion-margin-horizontal" icon={checkmarkCircleOutline} color="success" size={"large"} />
                )}
              </IonItem>
              <IonItem slot="content" lines="none" className="ion-text-center">
                <IonLabel className="font">Select Event Date Type</IonLabel>
                <IonButtons slot="end">
                  <IonButton onClick={() => setOpenInfo(!openInfo)}>
                    <IonIcon slot="icon-only" icon={informationCircleOutline} size="large" color="medium" />
                  </IonButton>
                </IonButtons>
              </IonItem>
              <IonItem slot="content" lines="none">
                <IonSegment
                  value={newEvt.eventDateType}
                  onIonChange={(e) => {
                    setDateRange(defaultNewDateInput);
                    setTempRecurringDates([""]);
                    setNewEvt({ ...newEvt, eventDateType: e.detail.value as EventDateType, eventDates: [defaultNewDateInput] });
                  }}
                >
                  <IonSegmentButton value="single">Single</IonSegmentButton>
                  <IonSegmentButton value="range">Range</IonSegmentButton>
                  <IonSegmentButton value="recurring">Recurring</IonSegmentButton>
                  <IonSegmentButton value="flexible">Flexible</IonSegmentButton>
                </IonSegment>
              </IonItem>
              {newEvt.eventDateType === "single" && (
                <>
                  <IonItem slot="content" lines="none">
                    <IonLabel className="font">Event Start</IonLabel>
                    <IonDatetimeButton datetime="evtStart" />
                    <IonModal keepContentsMounted>
                      <ModalHeader title={"Select Start Date & Time"} />
                      <IonDatetime
                        minuteValues={[0, 15, 30, 45]}
                        id="evtStart"
                        presentation="date-time"
                        hourCycle="h23"
                        value={newEvt.eventDates[0].start}
                        onIonChange={(e) => {
                          setNewEvt({
                            ...newEvt,
                            eventDates: [{ ...newEvt.eventDates[0], start: e.detail.value! as string }],
                          });
                        }}
                      />
                    </IonModal>
                  </IonItem>
                  <IonItem slot="content">
                    <IonLabel className="font">Event End</IonLabel>
                    <IonDatetimeButton datetime="evtEnd" />
                    <IonModal keepContentsMounted>
                      <ModalHeader title={"Select End Date & Time"} />
                      <IonDatetime
                        presentation="time"
                        hourCycle="h23"
                        minuteValues={[0, 15, 30, 45]}
                        id="evtEnd"
                        value={newEvt.eventDates[0].end}
                        onIonChange={(e) => {
                          setNewEvt({
                            ...newEvt,
                            eventDates: [
                              {
                                ...newEvt.eventDates[0],
                                end: e.detail.value! as string,
                              },
                            ],
                          });
                        }}
                      />
                    </IonModal>
                  </IonItem>
                </>
              )}
              {newEvt.eventDateType === "range" && (
                <>
                  <IonItem slot="content" lines="none">
                    <IonLabel className="font">Start Date</IonLabel>
                    <IonDatetimeButton datetime="evtStartDate" />
                    <IonModal keepContentsMounted>
                      <ModalHeader title={"Select Start Date"} />
                      <IonDatetime
                        presentation="date"
                        hourCycle="h23"
                        defaultValue={"1970-01-01T00:00:00Z"}
                        min={defaultNewDateInput.start}
                        id="evtStartDate"
                        value={dateRange.start}
                        onIonChange={(e) => setDateRange({ ...dateRange, start: e.detail.value as string })}
                      />
                    </IonModal>
                  </IonItem>
                  <IonItem slot="content" lines="none">
                    <IonLabel className="font">End Date</IonLabel>
                    <IonDatetimeButton datetime="evtEndDate" />
                    <IonModal keepContentsMounted>
                      <ModalHeader title={"Select End Date"} />
                      <IonDatetime
                        presentation="date"
                        hourCycle="h23"
                        defaultValue={"1970-01-01T00:00:00Z"}
                        min={dateRange.start}
                        id="evtEndDate"
                        value={dateRange.end}
                        onIonChange={(e) => setDateRange({ ...dateRange, end: e.detail.value as string })}
                      />
                    </IonModal>
                  </IonItem>
                  <br />
                  <IonItem slot="content" lines="none">
                    <IonLabel className="font">Daily Start Time</IonLabel>
                    <IonDatetimeButton datetime="evtStart" />
                    <IonModal keepContentsMounted>
                      <ModalHeader title={"Start Time"} />
                      <IonDatetime
                        presentation="time"
                        hourCycle="h23"
                        defaultValue={"1970-01-01T00:00:00Z"}
                        minuteValues={[0, 15, 30, 45]}
                        id="evtStart"
                        value={tempRecurringTimes.start}
                        onIonChange={(e) => setTempRecurringTimes({ ...tempRecurringTimes, start: e.detail.value as string })}
                      />
                    </IonModal>
                  </IonItem>
                  <IonItem slot="content">
                    <IonLabel className="font">Daily End Time</IonLabel>
                    <IonDatetimeButton datetime="evtEnd" />
                    <IonModal keepContentsMounted>
                      <ModalHeader title={"End Time"} />
                      <IonDatetime
                        presentation="time"
                        hourCycle="h23"
                        defaultValue={"1970-01-01T00:00:00Z"}
                        minuteValues={[0, 15, 30, 45]}
                        id="evtEnd"
                        value={tempRecurringTimes.end}
                        onIonChange={(e) => setTempRecurringTimes({ ...tempRecurringTimes, end: e.detail.value as string })}
                      />
                    </IonModal>
                  </IonItem>
                </>
              )}
              {newEvt.eventDateType === "recurring" && (
                <>
                  <IonItem slot="content" lines="none">
                    <IonLabel className="font" style={{ whiteSpace: "pre-wrap" }}>
                      Select Recurring Dates
                    </IonLabel>
                    <IonDatetime
                      id="multi-date"
                      multiple
                      min={defaultNewDateInput.start}
                      presentation="date"
                      hourCycle="h23"
                      // value={newEvt.multiDates}
                      onIonChange={async (e) => {
                        const tmp = [...(e.detail.value as string[])];
                        setTempRecurringDates(await sortDatesAsc(tmp));
                      }}
                    />
                  </IonItem>
                  <br />
                  <IonItem slot="content" lines="none">
                    <IonLabel className="font">Daily Start Time</IonLabel>
                    <IonDatetimeButton datetime="evtStart" />
                    <IonModal keepContentsMounted>
                      <ModalHeader title={"Select Start Time"} />
                      <IonDatetime
                        presentation="time"
                        hourCycle="h23"
                        defaultValue={"1970-01-01T00:00:00Z"}
                        minuteValues={[0, 15, 30, 45]}
                        id="evtStart"
                        value={tempRecurringTimes.start}
                        onIonChange={(e) => {
                          setTempRecurringTimes({ ...tempRecurringTimes, start: e.detail.value as string });
                        }}
                      />
                    </IonModal>
                  </IonItem>
                  <IonItem slot="content">
                    <IonLabel className="font">Daily End Time</IonLabel>
                    <IonDatetimeButton datetime="evtEnd" />
                    <IonModal keepContentsMounted>
                      <ModalHeader title={"Select End Time"} />
                      <IonDatetime
                        presentation="time"
                        hourCycle="h23"
                        defaultValue={"1970-01-01T00:00:00Z"}
                        minuteValues={[0, 15, 30, 45]}
                        id="evtEnd"
                        value={tempRecurringTimes.end}
                        onIonChange={(e) => {
                          setTempRecurringTimes({ ...tempRecurringTimes, end: e.detail.value as string });
                        }}
                      />
                    </IonModal>
                  </IonItem>
                </>
              )}
              {newEvt.eventDateType === "flexible" && (
                <>
                  <IonRow slot="content">
                    <IonCol className="ion-text-end">
                      <IonButton
                        onClick={() => {
                          const lastEntryIdx = newEvt.eventDates.length - 1;
                          setNewEvt({ ...newEvt, eventDates: [...newEvt.eventDates, { ...newEvt.eventDates[lastEntryIdx] }] });
                        }}
                      >
                        <IonIcon icon={addCircleOutline} className="ion-margin-horizontal" />
                        Add new event date
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  {newEvt.eventDates.map((date, idx) => (
                    <IonList slot="content" key={idx}>
                      <IonText>
                        <h5>
                          <b>{"#" + (idx + 1)}</b>
                        </h5>
                      </IonText>
                      <IonItem lines="none" button={false}>
                        <IonLabel className="font">Event Start</IonLabel>
                        <IonDatetimeButton datetime={`evtStart${idx}`} />
                        <IonModal keepContentsMounted>
                          <ModalHeader title={`#${idx + 1} Start Date & Time`} />
                          <IonDatetime
                            minuteValues={[0, 15, 30, 45]}
                            id={`evtStart${idx}`}
                            presentation="date-time"
                            hourCycle="h23"
                            value={date.start}
                            onIonChange={(e) => {
                              const temp = [...newEvt.eventDates];
                              temp.splice(idx, 1, { ...newEvt.eventDates[idx], start: e.detail.value! as string });
                              setNewEvt({ ...newEvt, eventDates: temp });
                            }}
                          />
                        </IonModal>
                      </IonItem>
                      <IonItem slot="content">
                        <IonLabel className="font">Event End</IonLabel>
                        <IonDatetimeButton datetime={`evtEnd${idx}`} />
                        <IonModal keepContentsMounted>
                          <ModalHeader title={`#${idx + 1} End Date & Time`} />
                          <IonDatetime
                            presentation="time"
                            hourCycle="h23"
                            minuteValues={[0, 15, 30, 45]}
                            id={`evtEnd${idx}`}
                            value={date.end}
                            onIonChange={(e) => {
                              const temp = [...newEvt.eventDates];
                              temp.splice(idx, 1, { ...newEvt.eventDates[idx], end: e.detail.value! as string });
                              setNewEvt({ ...newEvt, eventDates: temp });
                            }}
                          />
                        </IonModal>
                      </IonItem>
                    </IonList>
                  ))}
                  <br />
                </>
              )}
            </IonAccordion>
          </IonAccordionGroup>

          <IonItem>
            <IonLabel position="floating" className="font">
              Event description
            </IonLabel>
            <IonTextarea autoGrow value={newEvt?.description} rows={6} onIonChange={(e) => setNewEvt({ ...newEvt, description: e.detail.value! })} />
          </IonItem>
          <IonItem>
            <IonLabel position="floating" className="font">
              Event description (Chinese)
            </IonLabel>
            <IonTextarea autoGrow value={newEvt?.descriptionChi} rows={6} onIonChange={(e) => setNewEvt({ ...newEvt, descriptionChi: e.detail.value! })} />
          </IonItem>
          <StringInput evt={newEvt} title={"Event website (optional)"} value={newEvt?.website || ""} field={"website"} onChange={setNewEvt} />
          <OrgSelect name={"Co-organiser (optional)"} value={newEvt?.coOrg?.organizationName ?? ""} onFocus={() => setOrgType("co-org")} />
          <OrgSelect name={"Supporting Organisation (optional)"} value={newEvt?.supportOrg?.organizationName ?? ""} onFocus={() => setOrgType("support-org")} />
          {/* <StringInput evt={newEvt} title={"Co-organiser (optional)"} value={newEvt?.coOrg || ""} field={"coOrg"} onChange={setNewEvt} /> */}
          {/* <StringInput evt={newEvt} title={"Supporting Organisation (optional)"} value={newEvt?.supportOrg || ""} field={"supportOrg"} onChange={setNewEvt} /> */}
          <StringInput evt={newEvt} title={"Funder (optional)"} value={newEvt?.funder || ""} field={"funder"} onChange={setNewEvt} />
        </IonList>
      </IonGrid>
      <IonRow>
        <IonCol>
          <IonButton
            expand="block"
            disabled={!newEvt.eventAddress || !newEvt.eventName || !newEvt.geolocation.lat || !newEvt.geolocation.lng || !newEvt.organisation || !dateIsComplete}
            onClick={handleAddNewEvent}
          >
            SUBMIT
          </IonButton>
          <IonButton color="medium" expand="block" onClick={handleReset}>
            CLEAR FORM
          </IonButton>
        </IonCol>
      </IonRow>

      {/* INFORMATION MODAL */}
      <IonModal isOpen={openInfo} onDidDismiss={() => setOpenInfo(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Event Date Types</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRow>
            <IonCol>
              <p>
                <b>Single: </b>One time event
              </p>
              <p>
                <b>Range: </b>Same time-range within 2 selected dates
              </p>
              <p>
                <b>Recurring: </b>Different dates with same time-range
              </p>
              <p>
                <b>Flexible: </b>Multiple dates with all different time-ranges
              </p>
            </IonCol>
          </IonRow>
        </IonContent>
        <BackFooter onClick={() => setOpenInfo(false)} />
      </IonModal>
      <IonModal isOpen={!!multiAddress.length} onDidDismiss={() => setMultiAddress([])}>
        <ModalHeader title="Please select address" />
        <IonContent>
          <IonList>
            {multiAddress.map((adr, idx) => (
              <IonItem
                key={idx}
                button
                onClick={() => {
                  setNewEvt({ ...newEvt, eventAddress: adr });
                  setMultiAddress([]);
                }}
              >
                <IonLabel>
                  <p>{adr.streetNo + " " + adr.route}</p>
                  <p>{adr.postalTown}</p>
                  <p>{adr.adminArea2}</p>
                  <p>{adr.adminArea1}</p>
                  <p>{adr.postcode}</p>
                  <p>{adr.country}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
        <BackFooter onClick={() => setMultiAddress([])} />
      </IonModal>
      <IonLoading isOpen={isLoading} message={"Loading"} />
      <IonAlert
        isOpen={!!message}
        message={message}
        onDidDismiss={() => setMessage("")}
        buttons={[
          {
            text: "OK",
            handler: () => {
              setMessage("");
              if (message === newEMsg) {
                window.location.reload();
              }
            },
          },
        ]}
      />
      <OrgSelectModal orgSelectModalType={{ modalType: "event", newEvt: newEvt, setNewEvt: setNewEvt }} orgType={orgType} setOrgType={setOrgType} allOrgs={allOrgs} />
    </Layout>
  );
}

export default NewEvent;
