import { IonAlert, IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonLoading, IonRow, IonText, IonTextarea } from "@ionic/react";
import axios from "axios";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { useCallback, useState } from "react";
import { IContactDev } from "../app/variables";
import "../css/ContactDev.css";
import { Layout } from "../pages/Page";
import { useTranslation } from "react-i18next";
import { defaultContactDev } from "app/defaultValues";

function ContactDev() {
  const [contactMsg, setContactMsg] = useState<IContactDev>(defaultContactDev);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const db = getFirestore();
    const { id, ...data } = contactMsg;
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      const userIp = res.data.ip;
      await addDoc(collection(db, "contactDev"), {
        ...data,
        userIp,
        createdAt: new Date(),
      });
      setContactMsg(defaultContactDev);
      setMessage("Thanks for your message!");
    } catch (error: any) {
      setMessage("Oops! Something's wrong, please try again");
      console.error(error);
      return error.code;
    } finally {
      setIsLoading(false);
    }
  }, [contactMsg]);

  return (
    <Layout name={t("m-ContactDev")}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonText className="ion-text-center">
              <p>We understand it's always frustrating to get the right person to your problems</p>
              <p>
                <b>Don't worry~ You're now messaging the core members of this site</b>
              </p>
            </IonText>
          </IonCol>
        </IonRow>
        <IonCard>
          <IonCardContent>
            <IonList>
              <CustomInput title={"Your email address"} value={contactMsg?.fromEmail} field="fromEmail" obj={contactMsg} onChange={setContactMsg} />
              <CustomInput title={"Subject"} value={contactMsg?.subject} field="subject" obj={contactMsg} onChange={setContactMsg} />
              <IonItem className="ion-no-padding">
                <IonLabel position="floating" className="font">
                  Message
                </IonLabel>
                <IonTextarea autoGrow value={contactMsg?.message} rows={6} onIonChange={(e) => setContactMsg({ ...contactMsg, message: e.detail.value! })} />
              </IonItem>
              <IonGrid className="ion-margin-vertical">
                <IonRow>
                  <IonCol className="flex-row-s-center">
                    <IonText>
                      <h2>Would you like us to reply you?</h2>
                    </IonText>
                  </IonCol>
                  <IonCol className="ion-text-end">
                    <div className="flex-column-end switchButtonContainer">
                      <div
                        className={`switchButton ${contactMsg.needReply ? "optionYes" : "optionNo"}`}
                        style={{ top: contactMsg.needReply ? "0px" : "-32px" }}
                        onClick={() => setContactMsg({ ...contactMsg, needReply: !contactMsg.needReply })}
                      >
                        YES
                      </div>
                      <div
                        className={`switchButton ${contactMsg.needReply ? "optionYes" : "optionNo"}`}
                        style={{ top: contactMsg.needReply ? "32px" : "0px" }}
                        onClick={() => setContactMsg({ ...contactMsg, needReply: !contactMsg.needReply })}
                      >
                        NO
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonRow>
                <IonCol>
                  <IonButton expand="block" onClick={handleSubmit}>
                    Submit
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonGrid>
      <IonLoading isOpen={isLoading} message={"Loading"} />
      <IonAlert message={message} isOpen={!!message} buttons={[{ text: "Ok", handler: () => setMessage("") }]} />
    </Layout>
  );
}

export default ContactDev;

interface CustomInputProps {
  obj: IContactDev;
  field: keyof IContactDev;
  value: string;
  title: string;
  onChange: (args0: IContactDev) => void;
}

export const CustomInput = (props: CustomInputProps) => {
  const { value, title, field, onChange, obj } = props;
  return (
    <IonItem className="ion-no-padding">
      <IonLabel position="floating" className="font">
        {title}
      </IonLabel>
      <IonInput value={value} onIonChange={(e) => onChange({ ...obj, [field]: e.detail.value! })} />
    </IonItem>
  );
};
