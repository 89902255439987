export const EN = {
  hkerMap: "Admin platform",
  lang: "切換至中文",
  "m-evt": "Events",
  "m-netEvt": "Net Events",
  "m-newNetEvt": "Create New Net Event",
  "m-NewEvt": "Create New Event",
  "m-NewIEvt": "Create New Internet Event",
  "m-Control": "Event Control",
  "m-Org": "Organisations",
  "m-NewOrg": "Create New Organisation",
  "m-editOrg": "Edit Organisation",
  "m-OrgMan": "Org Management",
  "m-StaffMan": "Staff Management",
  "m-StaffEnq": "Staff Enquiry",
  "m-ContactDev": "Contact Dev Team",
  "m-ContactUs": "Contact Us",
  "m-ReadStaffEnq": "Read Staff Enquiries",
  "m-ReadDevTeamMsg": "Read Dev Team msg",
  "m-announcements": "Announcements",
  "m-newAnnouncement": "New Announcement",
  announcements: "Announcement",
  editAnnouncement: "Edit Announcement",
  event: "Event",
  resetPw: "Reset Password",
  editOrg: "Edit Organisation",
  host: "Host",
  date: "Date",
  time: "Time",
  address: "Address",
  eventDes: "Event description",
  noEvt: "No event yet",
  listView: "List view",
  mapView: "Map view",
  mapAngle: "Map angle",
  mapTilt: "Map tilt",
  listEvtSearch: "Event name / part of event address",
  partOfAdd: "Part of address",
  orgSearchMsg: "This will tell you the organization(s) in-charge of your searched place",
  showNationwideOrg: "Show nationwide organisations",
  search: "Search",
  nationwideOrg: "Nationwide Organisations",
  unknownAdrMsg: "Unknown address, please make sure it's an UK address.",
  specificAdrMsg: "Please try a more specific address",
  hideMap: "Hide map",
  showMap: "Show map",
};
