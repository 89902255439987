import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRange,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import { GoogleMap, Marker, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import dayjs from "dayjs";
import { chevronUpCircle, closeOutline, locateOutline, pencilOutline } from "ionicons/icons";
// import { Overlay } from "pigeon-maps";
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "app/slices/authSlice";
import { arrToStr } from "helpers/arrayToString";
import { useCallback, useEffect, useRef, useState } from "react";
import { getAllEvts } from "../app/firebase";
import { EventDateType, IEventDetails, IOrgDetails } from "../app/variables";
import { defaultOrg } from "app/defaultValues";
import gMap from "../media/gMapLogo.png";
import { Layout } from "../pages/Page";
import { ModalHeader } from "./InputComponents";
import { OrgCardContent } from "./OrgManagement";
import BackFooter from "./BackFooter";
import { useTranslation } from "react-i18next";
import greenDot from "media/greenDot.svg";
import i18n from "i18n";
import EvtPosterDisplay from "./EvtPosterDisplay";
import EvtDateDisplay from "./EvtDateDisplay";
// import { Overlay } from "pigeon-maps";

const MAP_HEIGHT = window.innerHeight * 0.6;
const DEFAULT_TILT = 25;

function MapContent() {
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    mapIds: [process.env.REACT_APP_MAP_ID!],
    googleMapsApiKey: String(process.env.REACT_APP_GEO_KEY),
  });
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    lat: 53.474677,
    lng: -2.252303,
  });
  const [focusEvt, setFocusEvt] = useState("");
  const [blink, setBlink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [zoomValue, setZoomValue] = useState(5.5);
  const [viewCenter, setViewCenter] = useState({
    lat: 54.4316679,
    lng: -4.3016133,
  });
  const [incidentPoints, setIncidentPoints] = useState<IEventDetails[]>([]);
  const [hoverAnimate, setHoverAnimate] = useState<{
    onHover: boolean;
    point: IEventDetails | null;
  }>({
    onHover: false,
    point: null,
  });
  const [viewOrg, setViewOrg] = useState<IOrgDetails | null>(null);
  const [tilt, setTilt] = useState(DEFAULT_TILT);
  const [angle, setAngle] = useState(0);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const eventDetailsRef = useRef<HTMLIonGridElement>(null);

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getAllEvts();
      if (res && res.msg === "success" && !!res.data.length) {
        setIncidentPoints(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition((e) => {
      const coords = e.coords;
      setUserCurrentLocation({ lat: coords.latitude, lng: coords.longitude });
      setViewCenter({
        lat: coords.latitude,
        lng: coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    if (!!blink) {
      setTimeout(() => setBlink(""), 1000);
    }
  }, [blink]);

  return (
    <Layout name={t("m-evt")}>
      <IonContent ref={contentRef}>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{ height: MAP_HEIGHT, width: "100%" }}
            center={!!viewCenter ? viewCenter : userCurrentLocation}
            zoom={zoomValue}
            heading={angle}
            tilt={tilt}
            options={{
              streetViewControl: false,
              mapId: process.env.REACT_APP_MAP_ID,
            }}
          >
            {incidentPoints.map((point, idx) => (
              <Marker
                key={point.eventId + "marker" + idx}
                options={{ icon: greenDot, opacity: 0.8 }}
                animation={focusEvt === point.eventId ? 1 : 0}
                position={{ lat: point.geolocation.lat ?? 0, lng: point.geolocation.lng ?? 0 }}
                // style={{ transition: "color 0.3s" }}
                // color={blink === point.eventId ? "#FF7777" : "#5563f6"}
                onMouseDown={() =>
                  setHoverAnimate({
                    onHover: true,
                    point,
                  })
                }
                onMouseOver={() =>
                  setHoverAnimate({
                    onHover: true,
                    point,
                  })
                }
                onMouseOut={() => setHoverAnimate({ onHover: false, point: null })}
                // width={50}
                // onClick={() => {
                onMouseUp={() => {
                  setHoverAnimate({ onHover: false, point: null });
                  if (focusEvt === point.eventId) {
                    setFocusEvt("");
                  } else {
                    setFocusEvt(point.eventId);
                    let temp = [...(eventDetailsRef.current?.children as any)];
                    const foundEl = temp.find((x) => x.id === point.eventId) as HTMLIonCardElement;
                    foundEl.scrollIntoView({ behavior: "smooth", block: "center" });
                    setBlink(point.eventId);
                    setZoomValue(11);
                    setViewCenter({ lat: point.geolocation.lat ?? 0, lng: point.geolocation.lng ?? 0 });
                  }
                }}
              />
            ))}

            {hoverAnimate.onHover && !!hoverAnimate.point && (
              <OverlayView position={{ lat: hoverAnimate.point.geolocation.lat ?? 0, lng: hoverAnimate.point.geolocation.lng ?? 0 }} mapPaneName={"overlayMouseTarget"}>
                <div>
                  <IonList style={{ borderRadius: "8px", maxWidth: "360px" }}>
                    <IonItem lines="none" slot="header">
                      <IonText>
                        <b>{hoverAnimate.point.eventName}</b>
                      </IonText>
                    </IonItem>
                    <ToolTipDisplay name={"Host:"} value={hoverAnimate.point.organisation?.organizationName || ""} />
                    <DateDisplay dates={hoverAnimate.point.eventDates} eventDateType={hoverAnimate.point.eventDateType} />
                    <ToolTipDisplay name={"Address:"} value={`${hoverAnimate.point.eventAddress.streetNo} ${hoverAnimate.point.eventAddress.route}, ${hoverAnimate.point.eventAddress.postcode}`} />
                  </IonList>
                </div>
              </OverlayView>
            )}
          </GoogleMap>
        )}
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonRange
                className="ion-padding-horizontal ion-no-padding"
                aria-label="Angle range"
                pin
                min={-180}
                max={180}
                defaultValue={0}
                ticks={true}
                snaps={true}
                pinFormatter={(value: number) => `${value}º`}
                onIonChange={(e) => setAngle(e.detail.value as number)}
              />
              <IonLabel>{`Map angle ${angle}º`}</IonLabel>
            </IonCol>
            <IonCol className="ion-text-center">
              <IonRange
                className="ion-padding-horizontal ion-no-padding"
                aria-label="Tilt range"
                pin
                min={0}
                max={68}
                defaultValue={DEFAULT_TILT}
                ticks={true}
                snaps={true}
                pinFormatter={(value: number) => `${value}º`}
                onIonChange={(e) => setTilt(e.detail.value as number)}
              ></IonRange>
              <IonLabel>{`Map tilt ${tilt}º`}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        {/* <br /> */}
        <IonGrid ref={eventDetailsRef}>
          <IonRow>
            <IonCol className="ion-no-padding">
              <IonTitle>Event details:</IonTitle>
            </IonCol>
          </IonRow>
          {incidentPoints.map((i, idx) => (
            <EventCard
              event={i}
              idx={idx}
              mapLink
              color={blink === i.eventId ? "tertiary" : ""}
              key={i.eventId + "eventPoint" + idx}
              viewOrg={viewOrg}
              setViewOrg={setViewOrg}
              onClick={() => {
                setFocusEvt(i.eventId);
                setBlink(i.eventId);
                setViewCenter({ lat: i.geolocation.lat!, lng: i.geolocation.lng! });
                setZoomValue(16);
                contentRef.current?.scrollToTop(600);
              }}
            />
          ))}
        </IonGrid>
        <IonFab slot="fixed" vertical="bottom" horizontal="end" className="ion-margin ion-padding">
          <IonFabButton onClick={() => contentRef.current?.scrollToTop(600)}>
            <IonIcon icon={chevronUpCircle} />
          </IonFabButton>
        </IonFab>
        <br />
        <br />
        <br />
        <OrgModal viewOrg={viewOrg} setViewOrg={setViewOrg} />
      </IonContent>
      <IonLoading isOpen={isLoading} message={"Loading"} />
    </Layout>
  );
}

export default MapContent;

interface EventCardProps {
  event: IEventDetails;
  idx: number;
  onClick?: () => void;
  color?: string;
  adminControl?: boolean;
  mapLink?: boolean;
  editButton?: () => void;
  deleteButton?: (args0: IEventDetails) => void;
  viewOrg?: IOrgDetails | null;
  setViewOrg?: (args0: IOrgDetails | null) => void;
}

export const EventCard = (props: EventCardProps) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const { event, idx, onClick, color = "", adminControl = false, mapLink = false } = props;
  return (
    <>
      <IonCard id={event.eventId} color={color} style={{ transition: "all 0.8s" }}>
        <IonCardHeader>
          <IonItem className="ion-no-padding" lines="none" color={color} style={{ transition: "all 0.8s" }}>
            <IonText>
              <h5>{i18n.language === "en" ? <b>{event.eventName}</b> : <b>{!!event.eventNameChi ? event.eventNameChi : event.eventName}</b>}</h5>
            </IonText>
            {adminControl && (
              <>
                <IonButton slot="end" color="primary" onClick={props.editButton}>
                  <IonIcon icon={pencilOutline} />
                </IonButton>
                <IonButton
                  className="ion-no-margin"
                  slot="end"
                  color="danger"
                  onClick={() => {
                    if (props.deleteButton) props.deleteButton(event);
                  }}
                >
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </>
            )}
            {mapLink && (
              <IonButtons slot="end" className="ion-no-margin">
                <IonButton onClick={onClick}>
                  <IonIcon icon={locateOutline} slot="icon-only" />
                </IonButton>
                {/* <IonButton onClick={() => window.open(`https://maps.google.com?q=${event.geolocation.lat},${event.geolocation.lng}`)}> */}
                <IonButton onClick={() => window.open(`https://www.google.com/maps?saddr=My+Location&daddr=${event.geolocation.lat},${event.geolocation.lng}`)} size="small">
                  <img src={gMap} alt={gMap} style={{ maxWidth: "20px" }} />
                  {/* <IonIcon icon={navigateOutline} slot="icon-only" /> */}
                </IonButton>
              </IonButtons>
            )}
          </IonItem>
          {event.eventPoster && event.eventPoster.length && <EvtPosterDisplay eventPoster={event.eventPoster} />}
        </IonCardHeader>
        <IonList key={event.eventId + "incident" + idx}>
          {event.organisation && <OrgFieldDisplay name={t("host") + " :"} org={event.organisation} setViewOrg={props.setViewOrg} />}
          <EvtDateDisplay eventDateType={event.eventDateType} eventDates={event.eventDates} />
          <IonItem lines="none">
            <IonText>
              <b>{`${t("address")} :`}</b>
              {` ${event.eventAddress.streetNo} ${event.eventAddress.route}, ${event.eventAddress.postalTown}, ${event.eventAddress.adminArea2}, ${event.eventAddress.adminArea1}, ${event.eventAddress.postcode}`}
            </IonText>
          </IonItem>
          {!!event.website && (
            <IonItem lines="none">
              <IonText>
                <b>Website:</b>
              </IonText>
              <IonText
                className="ion-margin-horizontal"
                style={{
                  cursor: "pointer",
                  textTransform: "lowercase",
                  textDecoration: "underline",
                }}
                color="primary"
                onClick={() => {
                  if (event.website?.startsWith("http")) {
                    window.open(event.website);
                  } else {
                    window.open(`http://${event.website}`);
                  }
                }}
              >
                {event.website}
              </IonText>
            </IonItem>
          )}
          {!!event.description && (
            <IonItem lines="none">
              <IonText>
                <b>{t("eventDes") + " :"}</b>
                {i18n.language === "en" ? (
                  <p style={{ whiteSpace: "pre-wrap" }}>{`${event.description}`}</p>
                ) : (
                  <p style={{ whiteSpace: "pre-wrap" }}>{`${!!event.descriptionChi ? event.descriptionChi : event.description}`}</p>
                )}
              </IonText>
            </IonItem>
          )}
          {!!event.coOrg?.organizationName && <OrgFieldDisplay name={"Co-organiser:"} org={event.coOrg} setViewOrg={props.setViewOrg} />}
          {!!event.supportOrg?.organizationName && <OrgFieldDisplay name={"Support organisation:"} org={event.supportOrg} setViewOrg={props.setViewOrg} />}
        </IonList>
        <br />
        {!!authUser && authUser.role === "super-admin" && adminControl && (
          <IonAccordionGroup>
            <IonAccordion value={`${event.eventId}`}>
              <IonItem slot="header">
                <IonText>
                  <b>Last updated information</b>
                </IonText>
              </IonItem>
              <IonItem slot="content" lines="none">
                <b>Updated at: </b>
                <IonText className="ml-1">{dayjs(event.updatedAt).format("YYYY-MM-DD HH:mm")}</IonText>
              </IonItem>
              <IonItem slot="content" lines="none">
                <b>Email: </b>
                <IonText className="ml-1">{event.updatedBy?.email}</IonText>
              </IonItem>
              <IonItem slot="content" lines="none">
                <b>Organisation(s): </b>
                <IonText className="ml-1">
                  <p>{arrToStr(!!event.updatedBy?.organisation.length ? event.updatedBy?.organisation : []) || ""}</p>
                </IonText>
              </IonItem>
              {event.updatedBy?.communityType === "nationwide" ? (
                <IonItem slot="content" lines="none">
                  <b>Community Type: </b>
                  <IonText className="ml-1">{event.updatedBy?.communityType}</IonText>
                </IonItem>
              ) : (
                <IonItem slot="content" lines="none">
                  <b>District: </b>
                  <IonText className="ml-1">{!!event.updatedBy?.district.length ? arrToStr(event.updatedBy?.district) : ""}</IonText>
                </IonItem>
              )}
            </IonAccordion>
          </IonAccordionGroup>
        )}
        <br />
      </IonCard>
    </>
  );
};

interface DateDisplayProps {
  dates: { start: Date; end: Date }[];
  eventDateType: EventDateType;
}

export const DateDisplay = (props: DateDisplayProps) => {
  if (props.eventDateType === "single") {
    return <ToolTipDisplay name={"Event Date:"} value={`${dayjs(props.dates[0].start).format("YYYY-MM-DD  HH:mm")}~${dayjs(props.dates[0].end).format("HH:mm")}`} />;
  } else if (props.eventDateType === "recurring") {
    let str = "";
    props.dates.forEach((i, idx) => {
      if (idx !== props.dates.length - 1) {
        str = str.concat(dayjs(i.start).format("YYYY-MM-DD").toString()) + ", ";
      } else {
        str = str.concat(dayjs(i.start).format("YYYY-MM-DD").toString());
      }
    });
    return (
      <>
        <ToolTipDisplay name={"Event Dates:"} value={str} />;
        <ToolTipDisplay name={"Time:"} value={`${dayjs(props.dates[0].start).format("HH:mm")} - ${dayjs(props.dates[0].end).format("HH:mm")}`} />;
      </>
    );
  } else if (props.eventDateType === "range") {
    return (
      <>
        <ToolTipDisplay name={"Event Dates:"} value={props.dates.map((i) => dayjs(i.start).format(" YYYY-MM-DD")).toString()} />
        <ToolTipDisplay name={"Time:"} value={`${dayjs(props.dates[0].start).format("HH:mm")} - ${dayjs(props.dates[0].end).format("HH:mm")}`} />;
      </>
    );
  } else {
    return (
      <>
        {props.dates.map((i, idx) => (
          <ToolTipDisplay key={i.start.toLocaleString() + idx} name={`Day #${idx + 1}`} value={`${dayjs(i.start).format("YYYY-MM-DD HH:mm")}~${dayjs(i.end).format("HH:mm")}`} />
        ))}
      </>
    );
  }
};

interface ToolTipDisplayProps {
  name: string;
  value: string;
}

export const ToolTipDisplay = (props: ToolTipDisplayProps) => {
  return (
    <IonItem lines="none" className="ion-no-margin">
      <IonCol className="ion-no-padding">
        <b>{props.name}</b>
        <IonText className="mx-1">{props.value}</IonText>
      </IonCol>
    </IonItem>
  );
};

interface OrgFieldDisplayProps {
  name: string;
  org: IOrgDetails;
  setViewOrg?: (args0: IOrgDetails) => void;
}

export const OrgFieldDisplay = (props: OrgFieldDisplayProps) => {
  return (
    <IonItem lines="none">
      <IonText>
        <b>{props.name}</b>
        {" " + props.org.organizationName}
      </IonText>
      {props.setViewOrg && (
        <IonButton className="mx-1" onClick={() => props.setViewOrg!(props.org)}>
          View
        </IonButton>
      )}
    </IonItem>
  );
};

interface OrgModalProps {
  viewOrg: IOrgDetails | null;
  setViewOrg: (args0: IOrgDetails | null) => void;
}

export const OrgModal = (props: OrgModalProps) => {
  const { viewOrg, setViewOrg } = props;
  return (
    <IonModal isOpen={!!viewOrg} onDidDismiss={() => setViewOrg(null)}>
      <ModalHeader title={`Organisation details`} closeButton closeFunction={() => setViewOrg(null)} />
      <IonContent>
        <OrgCardContent org={viewOrg ?? defaultOrg} showName />
      </IonContent>
      <BackFooter onClick={() => setViewOrg(null)} />
    </IonModal>
  );
};
