import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonTextarea,
  useIonRouter,
} from "@ionic/react";
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "app/slices/authSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Resizer from "react-image-file-resizer";
import { collections, fetchAllOrgs, generalDelete, getAnnouncementById, setNewAnnouncement, uploadImage } from "../app/firebase";
import { IAnnouncement, IOrgDetails, emptyAnnouncement } from "../app/variables";
import { Layout } from "../pages/Page";

import { useParams } from "react-router";
import { EvtPosterEdit } from "./EvtPosterDisplay";
import { ModalHeader } from "./InputComponents";
import { UploadFilesType } from "./NewIntEvent";
import OrgSelectModal, { OrgFieldTypes, OrgSelect } from "./OrgSelectModal";
import dayjs from "dayjs";
import { trashOutline } from "ionicons/icons";

const newEMsg = "New announcement created";
const editMsg = "Save success";

export default function EditNewAnnouncement() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [isLoading, setIsLoading] = useState(false);
  const [dataState, setDataState] = useState<undefined | null | IAnnouncement>(undefined);
  const [allOrgs, setAllOrgs] = useState<IOrgDetails[]>([]);
  const [message, setMessage] = useState("");
  const [delMsg, setDelMsg] = useState("");
  const uploadRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<UploadFilesType[]>([]);
  const [orgType, setOrgType] = useState<OrgFieldTypes>(null);
  const [needDeadline, setNeedDeadline] = useState(false);
  const router = useIonRouter();

  const fetchAnnouncement = useCallback(async (id: string) => {
    setIsLoading(true);
    try {
      const res = await getAnnouncementById(id);
      if (res) {
        if (res.validTill) {
          setNeedDeadline(true);
        }
        if (res.img && res.img.length) {
          const imgData: UploadFilesType[] = [];
          res.img.forEach((i) => {
            imgData.push({ imageData: i, file: null });
          });
          setFiles(imgData);
        }
        setDataState(res);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchAnnouncement(id);
    } else {
      setDataState(emptyAnnouncement);
    }
  }, [fetchAnnouncement, id]);

  const handleReset = useCallback(() => {
    setFiles([]);
    setDataState(emptyAnnouncement);
  }, []);

  const compressImage = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        4000,
        4000,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const imageInput = async (files: FileList) => {
    const tmpFiles: UploadFilesType[] = [];
    for await (const file of files) {
      const compressed = await compressImage(file);
      if (compressed instanceof File) {
        const tmp: UploadFilesType = { imageData: URL.createObjectURL(compressed), file: compressed };
        tmpFiles.push(tmp);
      }
    }
    setFiles(tmpFiles);
  };

  const handleCreateAnnouncement = useCallback(async () => {
    if (!authUser || !dataState) return;
    setIsLoading(true);
    let tmpAnnouncement: IAnnouncement = { ...dataState };
    tmpAnnouncement.externalLink = tmpAnnouncement.externalLink.toLowerCase();

    const imgLinks: string[] = [...dataState.img];
    if (files && files.length) {
      for await (const f of files) {
        if (f.file) {
          const fileStr = await uploadImage(f.file);
          imgLinks.push(fileStr);
        }
      }
      tmpAnnouncement.img = imgLinks;
    }

    try {
      const res = await setNewAnnouncement(tmpAnnouncement);
      if (res === "success") {
        setMessage(tmpAnnouncement.id === "new" ? newEMsg : editMsg);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [authUser, dataState, files]);

  const handleFetchOrgs = useCallback(async () => {
    try {
      const res = await fetchAllOrgs();
      if (res) {
        setAllOrgs(res);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleRemoveImage = (img: string) => {
    if (!files.length) return;
    const foundIdx = files.findIndex((i) => i.imageData === img);
    if (foundIdx >= 0) {
      const tmp = [...files];
      tmp.splice(foundIdx, 1);
      setFiles(tmp);
    }
  };

  const handleSwitchNeedDeadline = useCallback(
    (val: boolean) => {
      if (!dataState) return;
      setDataState({ ...dataState, validTill: val ? new Date() : null });
      setNeedDeadline(val);
    },
    [dataState]
  );

  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    try {
      await generalDelete(collections.announcements, id);
      setDelMsg("");
      setMessage("Event deleted");
      setTimeout(() => router.goBack(), 1500);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [id, router]);

  useEffect(() => {
    if (!allOrgs.length) {
      handleFetchOrgs();
    }
  }, [allOrgs.length, handleFetchOrgs]);

  return (
    <Layout
      name={!id ? t("m-newAnnouncement") : `${t("editAnnouncement")} - ${dataState?.title}`}
      back
      extraButton={
        <IonButton slot="end" className="ion-margin-horizontal" color="danger" onClick={() => setDelMsg("Confirm to delete this announcement?")}>
          <IonIcon icon={trashOutline} />
        </IonButton>
      }
    >
      {dataState && (
        <IonGrid>
          <IonList>
            {/* Image */}
            <IonItem lines={files.length ? "none" : "full"}>
              <IonLabel className="font">Announcement Poster</IonLabel>
              <IonButton slot="end" expand="block" onClick={() => uploadRef.current?.click()}>
                UPLOAD
              </IonButton>
              <input type="file" accept="image/*" style={{ display: "none" }} multiple ref={uploadRef} onChange={(e) => (e.target.files ? imageInput(e.target.files) : {})} />
            </IonItem>
            {!!files.length && <EvtPosterEdit eventPoster={files.map((i) => i.imageData)} isEdit={!!id} onRemove={handleRemoveImage} />}

            {/* Organisation */}
            <OrgSelect name={"Organisation name"} value={dataState.organisation?.organizationName ?? ""} onFocus={() => setOrgType("host")} />

            {/* ValidTil */}
            <IonItem lines="none">
              <IonLabel className="font">Need deadline ?</IonLabel>
              <IonCheckbox checked={needDeadline} onIonChange={(e) => handleSwitchNeedDeadline(e.detail.checked)} />
            </IonItem>
            {needDeadline && (
              <>
                <IonItem>
                  <IonLabel className="font">Valid until</IonLabel>
                  <IonDatetimeButton defaultValue={dayjs().endOf("day").toISOString()} datetime="validTill" />
                </IonItem>
                <IonModal keepContentsMounted>
                  <ModalHeader title={"Select Start Date & Time"} />
                  <IonDatetime
                    minuteValues={[0, 15, 30, 45, 59]}
                    id="validTill"
                    presentation="date-time"
                    showDefaultTimeLabel
                    hourCycle="h23"
                    value={dataState.validTill ? dataState.validTill.toISOString() : undefined}
                    onIonChange={(e) => {
                      console.log(new Date(e.detail.value!.toString()));
                      console.log(new Date(e.detail.value!.toString()).toISOString());
                      setDataState({
                        ...dataState,
                        validTill: new Date(e.detail.value!.toString()),
                      });
                    }}
                  />
                </IonModal>
              </>
            )}

            {/* Title */}
            <IonItem>
              <IonLabel position="floating" className="font">
                Title
              </IonLabel>
              <IonInput value={dataState.title} onIonChange={(e) => setDataState({ ...dataState, title: e.detail.value! })} />
            </IonItem>

            {/* Description */}
            <IonItem>
              <IonLabel position="floating" className="font">
                Event description
              </IonLabel>
              <IonTextarea autoGrow value={dataState.description} rows={6} onIonChange={(e) => setDataState({ ...dataState, description: e.detail.value! })} />
            </IonItem>

            {/* ExternalLink */}
            <IonItem>
              <IonLabel position="floating" className="font">
                Website / external link
              </IonLabel>
              <IonInput value={dataState.externalLink} onIonChange={(e) => setDataState({ ...dataState, externalLink: e.detail.value! })} />
            </IonItem>

            {/* CoOrg */}
            <OrgSelect name="Co-organisation (optional)" value={dataState.coOrg?.organizationName || ""} onFocus={() => setOrgType("co-org")} />

            {/* SupportOrg */}
            <OrgSelect name="Support organisation (optional)" value={dataState.supportOrg?.organizationName || ""} onFocus={() => setOrgType("support-org")} />

            {/* Funder */}
            <IonItem>
              <IonLabel position="floating" className="font">
                Funder (optional)
              </IonLabel>
              <IonInput value={dataState.funder} onIonChange={(e) => setDataState({ ...dataState, funder: e.detail.value! })} />
            </IonItem>
          </IonList>
        </IonGrid>
      )}
      <IonRow>
        <IonCol>
          <IonButton expand="block" disabled={!dataState || !dataState.organisation || !dataState.title || !dataState.description} onClick={handleCreateAnnouncement}>
            SUBMIT
          </IonButton>
          <IonButton color="medium" expand="block" onClick={handleReset}>
            CLEAR FORM
          </IonButton>
        </IonCol>
      </IonRow>

      <IonLoading isOpen={isLoading} message={"Loading"} />
      <IonAlert
        isOpen={!!message}
        message={message}
        onDidDismiss={() => setMessage("")}
        buttons={[
          {
            text: "OK",
            handler: () => {
              setMessage("");
              if (message === newEMsg) {
                window.location.reload();
              }
            },
          },
        ]}
      />
      <IonAlert
        isOpen={!!delMsg}
        message={delMsg}
        onDidDismiss={() => setDelMsg("")}
        buttons={[
          {
            text: "OK",
            handler: () => handleDelete(),
          },
        ]}
      />
      {dataState && (
        <OrgSelectModal orgSelectModalType={{ modalType: "announcement", newAnnouncement: dataState, setNewAnnouncement: setDataState }} orgType={orgType} setOrgType={setOrgType} allOrgs={allOrgs} />
      )}
    </Layout>
  );
}
