export const ZH = {
  hkerMap: "Admin platform",
  lang: "Switch to English",
  "m-evt": "活動",
  "m-netEvt": "網上活動",
  "m-newNetEvt": "建立網上活動",
  "m-NewEvt": "建立活動",
  "m-NewIEvt": "新網上活動",
  "m-Control": "活動管理",
  "m-Org": "港人機構搜尋",
  "m-NewOrg": "建立機構",
  "m-editOrg": "編輯機構",
  "m-OrgMan": "機構管理",
  "m-StaffMan": "Staff 管理",
  "m-StaffEnq": "通知 Super admin",
  "m-ContactDev": "聯絡開發者",
  "m-ContactUs": "聯絡我們",
  "m-ReadStaffEnq": "Read Staff Enquiries",
  "m-ReadDevTeamMsg": "Read Dev Team msg",
  "m-announcements": "通告",
  "m-newAnnouncement": "建立新通告",
  announcements: "通告",
  editAnnouncement: "編輯通告",
  event: "活動",
  resetPw: "重置密碼",
  editOrg: "編輯機構資料",
  host: "主辦",
  date: "日期",
  time: "時間",
  address: "地點",
  eventDes: "活動描述",
  noEvt: "暫時未有活動",
  listView: "清單",
  mapView: "地圖",
  mapAngle: "左右角度",
  mapTilt: "上下角度",
  listEvtSearch: "活動名稱 / 部份地址",
  partOfAdd: "部份地址",
  orgSearchMsg: "根據您搜尋的地方找出相關的負責機構",
  showNationwideOrg: "顯示nationwide機構",
  search: "搜尋",
  nationwideOrg: "Nationwide 機構",
  unknownAdrMsg: "請確認輸入為英國地址",
  specificAdrMsg: "請輸入更仔細的地址",
  hideMap: "隱藏地圖",
  showMap: "顯示地圖",
};
