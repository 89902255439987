import {
  IonAccordion,
  IonAccordionGroup,
  IonAlert,
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "app/slices/authSlice";
import dayjs from "dayjs";
import { collection, getDocs, getFirestore, orderBy, query } from "firebase/firestore";
import { addCircleOutline, checkmarkCircleOutline, informationCircleOutline, searchOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import { useHistory, useParams } from "react-router";
import { editEvent, getEvtById, uploadImage } from "../app/firebase";
import { EventDateFormat, EventDateType, GUnit, IEventDetails, IEventDetailsInput, IIntEvent, IOrgDetails } from "../app/variables";
import { defaultLocationDetailInput, defaultNewDateInput } from "app/defaultValues";
import { getLongName } from "../helpers/address";
import { sortDatesAsc, sortEventsAsc } from "../helpers/date";
import { Layout } from "../pages/Page";
import BackFooter from "./BackFooter";
import { AddressInput, ModalHeader, StringInput } from "./InputComponents";
import Resizer from "react-image-file-resizer";
import { EvtPosterEdit } from "./EvtPosterDisplay";
import { UploadFilesType } from "./NewIntEvent";
import OrgSelectModal, { OrgFieldTypes, OrgSelect } from "./OrgSelectModal";

function EditEvent() {
  const { id } = useParams<{ id: string }>();
  const authUser = useAppSelector(selectAuthUser);
  const [eventDetails, setEventDetails] = useState<IEventDetailsInput>(defaultLocationDetailInput);
  const [message, setMessage] = useState("");
  const [openInfo, setOpenInfo] = useState(false);
  const [tempRecurringDates, setTempRecurringDates] = useState([new Date().toLocaleDateString("en-CA")]);
  const [tempRecurringTimes, setTempRecurringTimes] = useState(defaultNewDateInput);
  const [dateRange, setDateRange] = useState(defaultNewDateInput);
  const [dateIsComplete, setDateIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allOrgs, setAllOrgs] = useState<IOrgDetails[]>([]);
  const [orgType, setOrgType] = useState<OrgFieldTypes>(null);
  const [addressSearch, setAddressSearch] = useState("");
  const uploadRef = useRef<HTMLInputElement>(null);
  const [multiAddress, setMultiAddress] = useState<GUnit[]>([]);
  const [files, setFiles] = useState<UploadFilesType[]>([]);
  const history = useHistory();

  const handleReset = useCallback(() => {
    setDateRange(defaultNewDateInput);
    setTempRecurringDates([""]);
    setTempRecurringTimes(defaultNewDateInput);
    setEventDetails(defaultLocationDetailInput);
    setMessage("");
    setFiles([]);
    history.goBack();
  }, [history]);

  const compressImage = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        4000,
        4000,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const imageInput = async (files: FileList) => {
    const tmpFiles: UploadFilesType[] = [];
    if (eventDetails.eventPoster && eventDetails.eventPoster.length) {
      eventDetails.eventPoster.forEach((i) => tmpFiles.push({ imageData: i, file: null }));
    }
    for await (const file of files) {
      const compressed = await compressImage(file);
      if (compressed instanceof File) {
        const tmp: UploadFilesType = { imageData: URL.createObjectURL(compressed), file: compressed };
        tmpFiles.push(tmp);
      }
    }
    setFiles(tmpFiles);
  };

  const handleSave = useCallback(async () => {
    if (!authUser) return;
    let evtDates: EventDateFormat[] = [];
    let tmpEvt: any = { ...eventDetails };
    let res: any;
    const imgLinks: string[] = eventDetails.eventPoster ? [...eventDetails.eventPoster] : [];
    if (files && files.length) {
      for await (const f of files) {
        if (f.file) {
          const fileStr = await uploadImage(f.file);
          imgLinks.push(fileStr);
        }
      }
      tmpEvt.eventPoster = imgLinks;
    }
    if (eventDetails.eventDateType === "range") {
      const cutSDate = dayjs(dateRange.start).format("YYYY-MM-DD");
      const cutEDate = dayjs(dateRange.end).format("YYYY-MM-DD");
      const start = new Date(cutSDate.concat(dayjs(tempRecurringTimes.start).format("THH:mm")));
      const end = new Date(cutEDate.concat(dayjs(tempRecurringTimes.end).format("THH:mm")));
      evtDates.push({ start, end });
      tmpEvt.eventDates = evtDates;
      res = await editEvent(id, { ...tmpEvt, updatedAt: new Date(), updatedBy: authUser, lastDatetime: end });
    } else if (eventDetails.eventDateType === "recurring") {
      let lastDate = new Date();
      let temp = await sortDatesAsc(tempRecurringDates);
      for await (let evtDate of temp) {
        const start = new Date(evtDate.concat(dayjs(tempRecurringTimes.start).format("THH:mm")));
        const end = new Date(evtDate.concat(dayjs(tempRecurringTimes.end).format("THH:mm")));
        lastDate = end;
        evtDates.push({ start, end });
      }
      tmpEvt.eventDates = evtDates;
      res = await editEvent(id, { ...tmpEvt, updatedAt: new Date(), updatedBy: authUser, lastDatetime: lastDate });
    } else if (eventDetails.eventDateType === "flexible") {
      tmpEvt.eventDates = await sortEventsAsc(eventDetails.eventDates, true);
      res = await editEvent(id, { ...tmpEvt, updatedAt: new Date(), updatedBy: authUser, lastDatetime: tmpEvt.eventDates[tmpEvt.eventDates.length - 1] });
    } else {
      const evtDateS = new Date(eventDetails.eventDates[0].start);
      const evtDateE = new Date(dayjs(eventDetails.eventDates[0].start).format("YYYY-MM-DD").concat(dayjs(eventDetails.eventDates[0].end).format("THH:mm")));
      const tmp: IEventDetails = { ...tmpEvt, eventDates: [{ start: evtDateS, end: evtDateE }], lastDatetime: evtDateE };
      res = await editEvent(id, { ...tmp, updatedAt: new Date(), updatedBy: authUser });
    }
    if (res === "success") {
      setMessage("Save success");
    } else {
      setMessage(res);
    }
  }, [authUser, dateRange.end, dateRange.start, eventDetails, files, id, tempRecurringDates, tempRecurringTimes.end, tempRecurringTimes.start]);

  const fetchPostcode = useCallback(async () => {
    if (!addressSearch) return;
    try {
      const res = await Geocode.fromAddress(addressSearch);
      if (!res.results.length) return;
      if (res.results.length === 1) {
        if (res.status === "OK") {
          const temp = getLongName(res.results[0].address_components);
          setEventDetails({ ...eventDetails, eventAddress: temp });
        }
      } else {
        let tempArr: GUnit[] = [];
        for (let i of res.results) {
          tempArr.push(getLongName(i.address_components));
        }
        setMultiAddress(tempArr);
      }
    } catch (error) {
      setMessage("No result");
    }
  }, [addressSearch, eventDetails]);

  const handleFetchGeocode = useCallback(async () => {
    if (!eventDetails.eventAddress.streetNo || !eventDetails.eventAddress.postcode) return;
    // if (!eventDetails.eventAddress.postcode) return;
    Geocode.fromAddress(`${eventDetails.eventAddress.streetNo} ${eventDetails.eventAddress.route}, ${eventDetails.eventAddress.postcode}`).then((res) => {
      if (res.status === "OK") {
        const geo = res.results[0].geometry.location;
        setEventDetails({ ...eventDetails, geolocation: { lat: geo.lat, lng: geo.lng } });
      }
    });
  }, [eventDetails]);

  useEffect(() => {
    if (!process.env.REACT_APP_GEO_KEY) return;
    Geocode.setApiKey(process.env.REACT_APP_GEO_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("uk");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
  }, []);

  useEffect(() => {
    if (
      (eventDetails.eventDateType === "single" && new Date(eventDetails.eventDates[0].start) > new Date()) ||
      (eventDetails.eventDateType === "range" && new Date(dateRange.start) > new Date() && new Date(dateRange.end) > new Date()) ||
      (eventDetails.eventDateType === "recurring" && tempRecurringDates.length > 1) ||
      (eventDetails.eventDateType === "flexible" && eventDetails.eventDates.length > 1)
    ) {
      setDateIsComplete(true);
    } else {
      setDateIsComplete(false);
    }
  }, [dateRange.end, dateRange.start, eventDetails.eventDateType, eventDetails.eventDates, tempRecurringDates.length]);

  const handleFetch = async (id: string) => {
    try {
      const res = await getEvtById(id);
      if (res) {
        if (res.eventDateType === "range") {
          setDateRange({ start: res.eventDates[0].start.toISOString(), end: res.eventDates[0].end.toISOString() });
          setTempRecurringTimes({ start: res.eventDates[0].start.toISOString(), end: res.eventDates[0].end.toISOString() });
        }
        if (res.eventDateType === "recurring") {
          let temp: string[] = [];
          res.eventDates.forEach((evtDate) => {
            temp.push(dayjs(evtDate.start).format("YYYY-MM-DD"));
          });
          setTempRecurringDates(temp);
          setTempRecurringTimes({ start: res.eventDates[0].start.toISOString(), end: res.eventDates[0].end.toISOString() });
        }
        const obj = {
          ...res,
          eventDates: [] as { start: string; end: string }[],
        };
        res.eventDates.forEach((evt) => {
          obj.eventDates.push({
            start: evt.start.toISOString(),
            end: evt.end.toISOString(),
          });
        });
        setEventDetails(obj);
        if (obj.eventPoster && obj.eventPoster.length) {
          const imgData: UploadFilesType[] = [];
          obj.eventPoster.forEach((i) => {
            imgData.push({ imageData: i, file: null });
          });
          setFiles(imgData);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      handleFetch(id);
    }
  }, [id]);

  const handleFetchOrgs = useCallback(async () => {
    const db = getFirestore();
    const q = query(collection(db, "organizations"), orderBy("organizationName", "asc"));
    try {
      const sss = await getDocs(q);
      let temp: IOrgDetails[] = [];
      sss.forEach((ss) => {
        temp.push({ id: ss.id, ...ss.data() } as IOrgDetails);
      });
      setAllOrgs(temp);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleRemoveImage = (img: string) => {
    if (!files.length) return;
    const foundIdx = files.findIndex((i) => i.imageData === img);
    if (foundIdx >= 0) {
      const tmp = [...files];
      tmp.splice(foundIdx, 1);
      setFiles(tmp);
    }
  };

  useEffect(() => {
    if (!allOrgs.length) {
      handleFetchOrgs();
    }
  }, [allOrgs.length, handleFetchOrgs]);

  return (
    <Layout back name={isLoading ? "Edit Event" : `Edit Event - ${eventDetails.eventName}`}>
      {isLoading ? (
        <></>
      ) : (
        <>
          <IonGrid>
            <IonRow>
              <IonCol size="12" className="ion-text-center">{`If Chinese is available for the field,`}</IonCol>
              <IonCol size="12" className="ion-text-center">{`please input info in order: English -> Chinese`}</IonCol>
              <IonCol size="12" className="ion-text-center">{`(e.g. Share with you 和你Share / Peter Chan 陳彼得)`}</IonCol>
            </IonRow>
            <IonList>
              <IonItem lines={files.length ? "none" : "full"}>
                <IonLabel className="font">Event Poster</IonLabel>
                <IonButton slot="end" expand="block" onClick={() => uploadRef.current?.click()}>
                  UPLOAD
                </IonButton>
                {/* <input type="file" style={{ display: "none" }} multiple={false} ref={uploadRef} onChange={(e) => (e.target.files ? imageInput(e.target.files[0]) : {})} /> */}
                <input type="file" accept="image/*" style={{ display: "none" }} multiple ref={uploadRef} onChange={(e) => (e.target.files ? imageInput(e.target.files) : {})} />
              </IonItem>
              {!!files.length && <EvtPosterEdit eventPoster={files.map((i) => i.imageData)} isEdit onRemove={handleRemoveImage} />}
              <IonItem>
                <IonLabel position="floating" className="font">
                  Event Name
                </IonLabel>
                <IonInput value={eventDetails?.eventName} onIonChange={(e) => setEventDetails({ ...eventDetails, eventName: e.detail.value! })} />
              </IonItem>
              <IonItem>
                <IonLabel position="floating" className="font">
                  Event Name in Chinese
                </IonLabel>
                <IonInput value={eventDetails?.eventNameChi} onIonChange={(e) => setEventDetails({ ...eventDetails, eventNameChi: e.detail.value! })} />
              </IonItem>
              {/* <IonInput value={eventDetails?.organisation} onIonChange={(e) => setEventDetails({ ...eventDetails, organisation: e.detail.value! })} /> */}

              <OrgSelect name={"Organisation name"} value={eventDetails?.organisation?.organizationName ?? ""} onFocus={() => setOrgType("host")} />
              <IonAccordionGroup>
                <IonAccordion value="evtAddress">
                  <IonItem slot="header">
                    <IonText>
                      <h5>
                        <b>Event Address</b>
                      </h5>
                    </IonText>
                    {!eventDetails.geolocation.lat || !eventDetails.geolocation.lng || !eventDetails.eventAddress.postcode || !eventDetails.eventAddress.route ? (
                      <IonCardSubtitle color="danger" className="ion-margin-horizontal">
                        REQUIRED
                      </IonCardSubtitle>
                    ) : (
                      <IonIcon className="ion-margin-horizontal" icon={checkmarkCircleOutline} color="success" size={"large"} />
                    )}
                  </IonItem>
                  <IonList slot="content">
                    <IonItem>
                      <IonLabel position="floating" className="font">
                        Address search
                      </IonLabel>
                      <IonInput placeholder="Postcode or part of the address" value={addressSearch} onIonChange={(e) => setAddressSearch(e.detail.value!)} />

                      <IonButtons slot="end">
                        <IonButton disabled={!addressSearch} onClick={fetchPostcode} color="primary">
                          <IonIcon icon={searchOutline} />
                          SEARCH & COMPLETE
                        </IonButton>
                      </IonButtons>
                    </IonItem>
                    <AddressInput evt={eventDetails} title={"Postcode"} field={"postcode"} onChange={setEventDetails} postcodeFunc={fetchPostcode} />
                    <AddressInput evt={eventDetails} title={"House no. / Street no."} field={"streetNo"} onChange={setEventDetails} />
                    <AddressInput evt={eventDetails} title={"Street name"} field={"route"} onChange={setEventDetails} />
                    <AddressInput evt={eventDetails} title={"Town"} field={"postalTown"} onChange={setEventDetails} />
                    <AddressInput evt={eventDetails} title={"Administrative area lv 2 (e.g. Greater London)"} field={"adminArea2"} onChange={setEventDetails} />
                    <AddressInput evt={eventDetails} title={"Administrative area lv 1 (England)"} field={"adminArea1"} onChange={setEventDetails} />
                    <AddressInput evt={eventDetails} title={"Country"} field={"country"} onChange={setEventDetails} />
                    <IonList>
                      <IonItem slot="header" lines="none">
                        <IonLabel className="font">
                          Geolocation
                          <IonButton
                            expand="block"
                            disabled={!eventDetails.eventAddress.postcode && !eventDetails.eventAddress.route && !eventDetails.eventAddress.streetNo}
                            onClick={() => handleFetchGeocode()}
                          >
                            Get my geo coordinates
                          </IonButton>
                        </IonLabel>
                      </IonItem>
                      <IonItem slot="content">
                        <IonCol>
                          <IonText color={!eventDetails.geolocation.lat ? "danger" : "dark"}>
                            Latitude:
                            {eventDetails?.geolocation.lat ?? "Unknown"}
                          </IonText>
                        </IonCol>
                        <IonCol>
                          <IonText color={!eventDetails.geolocation.lat ? "danger" : "dark"}>Longitude: {eventDetails?.geolocation.lng ?? "Unknown"}</IonText>
                        </IonCol>
                      </IonItem>
                    </IonList>
                  </IonList>
                </IonAccordion>
                <IonAccordion value="evtDate">
                  <IonItem slot="header">
                    <IonText>
                      <h5>
                        <b>Event Date & Time</b>
                      </h5>
                    </IonText>
                    {!dateIsComplete ? (
                      <IonCardSubtitle color="danger" className="ion-margin-horizontal">
                        REQUIRED
                      </IonCardSubtitle>
                    ) : (
                      <IonIcon className="ion-margin-horizontal" icon={checkmarkCircleOutline} color="success" size={"large"} />
                    )}
                  </IonItem>
                  <IonItem slot="content" lines="none" className="ion-text-center">
                    <IonLabel className="font">Select Event Date Type</IonLabel>
                    <IonButtons slot="end">
                      <IonButton onClick={() => setOpenInfo(!openInfo)}>
                        <IonIcon slot="icon-only" icon={informationCircleOutline} size="large" color="medium" />
                      </IonButton>
                    </IonButtons>
                  </IonItem>
                  <IonItem slot="content" lines="none">
                    <IonSegment
                      value={eventDetails.eventDateType}
                      onIonChange={(e) => {
                        setDateRange(defaultNewDateInput);
                        setTempRecurringDates([""]);
                        setTempRecurringTimes(defaultNewDateInput);
                        setEventDetails({ ...eventDetails, eventDateType: e.detail.value as EventDateType, eventDates: [defaultNewDateInput] });
                      }}
                    >
                      <IonSegmentButton value="single">Single</IonSegmentButton>
                      <IonSegmentButton value="range">Range</IonSegmentButton>
                      <IonSegmentButton value="recurring">Recurring</IonSegmentButton>
                      <IonSegmentButton value="flexible">Flexible</IonSegmentButton>
                    </IonSegment>
                  </IonItem>
                  {eventDetails.eventDateType === "single" && (
                    <>
                      <IonItem slot="content" lines="none">
                        <IonLabel className="font">Event Start</IonLabel>
                        <IonDatetimeButton datetime="evtStart" />
                        <IonModal keepContentsMounted>
                          <ModalHeader title={"Select Start Date & Time"} />
                          <IonDatetime
                            minuteValues={[0, 15, 30, 45]}
                            id="evtStart"
                            presentation="date-time"
                            hourCycle="h23"
                            value={eventDetails.eventDates[0].start}
                            onIonChange={(e) => {
                              setEventDetails({
                                ...eventDetails,
                                eventDates: [{ ...eventDetails.eventDates[0], start: e.detail.value! as string }],
                              });
                            }}
                          />
                        </IonModal>
                      </IonItem>
                      <IonItem slot="content">
                        <IonLabel className="font">Event End</IonLabel>
                        <IonDatetimeButton datetime="evtEnd" />
                        <IonModal keepContentsMounted>
                          <ModalHeader title={"Select End Date & Time"} />
                          <IonDatetime
                            presentation="time"
                            hourCycle="h23"
                            minuteValues={[0, 15, 30, 45]}
                            id="evtEnd"
                            value={eventDetails.eventDates[0].end}
                            onIonChange={(e) =>
                              setEventDetails({
                                ...eventDetails,
                                eventDates: [
                                  {
                                    ...eventDetails.eventDates[0],
                                    end: e.detail.value! as string,
                                  },
                                ],
                              })
                            }
                          />
                        </IonModal>
                      </IonItem>
                    </>
                  )}
                  {eventDetails.eventDateType === "range" && (
                    <>
                      <IonItem slot="content" lines="none">
                        <IonLabel className="font">Start Date</IonLabel>
                        <IonDatetimeButton datetime="evtStartDate" />
                        <IonModal keepContentsMounted>
                          <ModalHeader title={"Select Start Date"} />
                          <IonDatetime
                            presentation="date"
                            hourCycle="h23"
                            defaultValue={"1970-01-01T00:00:00Z"}
                            // min={defaultNewDateInput.start}
                            id="evtStartDate"
                            value={dateRange.start}
                            onIonChange={(e) => setDateRange({ ...dateRange, start: e.detail.value as string })}
                          />
                        </IonModal>
                      </IonItem>
                      <IonItem slot="content" lines="none">
                        <IonLabel className="font">End Date</IonLabel>
                        <IonDatetimeButton datetime="evtEndDate" />
                        <IonModal keepContentsMounted>
                          <ModalHeader title={"Select End Date"} />
                          <IonDatetime
                            presentation="date"
                            hourCycle="h23"
                            defaultValue={"1970-01-01T00:00:00Z"}
                            min={dateRange.start}
                            id="evtEndDate"
                            value={dateRange.end}
                            onIonChange={(e) => setDateRange({ ...dateRange, end: e.detail.value as string })}
                          />
                        </IonModal>
                      </IonItem>
                      <br />
                      <IonItem slot="content" lines="none">
                        <IonLabel className="font">Daily Start Time</IonLabel>
                        <IonDatetimeButton datetime="evtStart" />
                        <IonModal keepContentsMounted>
                          <ModalHeader title={"Start Time"} />
                          <IonDatetime
                            presentation="time"
                            hourCycle="h23"
                            defaultValue={"1970-01-01T00:00:00Z"}
                            minuteValues={[0, 15, 30, 45]}
                            id="evtStart"
                            value={tempRecurringTimes.start}
                            onIonChange={(e) => setTempRecurringTimes({ ...tempRecurringTimes, start: e.detail.value as string })}
                          />
                        </IonModal>
                      </IonItem>
                      <IonItem slot="content">
                        <IonLabel className="font">Daily End Time</IonLabel>
                        <IonDatetimeButton datetime="evtEnd" />
                        <IonModal keepContentsMounted>
                          <ModalHeader title={"End Time"} />
                          <IonDatetime
                            presentation="time"
                            hourCycle="h23"
                            defaultValue={"1970-01-01T00:00:00Z"}
                            minuteValues={[0, 15, 30, 45]}
                            id="evtEnd"
                            value={tempRecurringTimes.end}
                            onIonChange={(e) => setTempRecurringTimes({ ...tempRecurringTimes, end: e.detail.value as string })}
                          />
                        </IonModal>
                      </IonItem>
                    </>
                  )}
                  {eventDetails.eventDateType === "recurring" && (
                    <>
                      <IonItem slot="content" lines="none">
                        <IonLabel className="font" style={{ whiteSpace: "pre-wrap" }}>
                          Select Recurring Dates
                        </IonLabel>
                        <IonDatetime
                          id="multi-date"
                          multiple
                          // min={new Date().toLocaleDateString("en-CA")}
                          presentation="date"
                          hourCycle="h23"
                          defaultValue={[new Date().toLocaleDateString("en-CA")]}
                          value={tempRecurringDates}
                          onIonChange={async (e) => {
                            Array.isArray(e.detail.value!) ? setTempRecurringDates(e.detail.value! as string[]) : setTempRecurringDates([e.detail.value!] as string[]);
                          }}
                        />
                      </IonItem>
                      <br />
                      <IonItem slot="content" lines="none">
                        <IonLabel className="font">Daily Start Time</IonLabel>
                        <IonDatetimeButton datetime="evtStart" />
                        <IonModal keepContentsMounted>
                          <ModalHeader title={"Select Start Time"} />
                          <IonDatetime
                            presentation="time"
                            hourCycle="h23"
                            defaultValue={"1970-01-01T00:00:00Z"}
                            minuteValues={[0, 15, 30, 45]}
                            id="evtStart"
                            value={tempRecurringTimes.start}
                            onIonChange={(e) => {
                              setTempRecurringTimes({ ...tempRecurringTimes, start: e.detail.value as string });
                            }}
                          />
                        </IonModal>
                      </IonItem>
                      <IonItem slot="content">
                        <IonLabel className="font">Daily End Time</IonLabel>
                        <IonDatetimeButton datetime="evtEnd" />
                        <IonModal keepContentsMounted>
                          <ModalHeader title={"Select End Time"} />
                          <IonDatetime
                            presentation="time"
                            hourCycle="h23"
                            defaultValue={"1970-01-01T00:00:00Z"}
                            minuteValues={[0, 15, 30, 45]}
                            id="evtEnd"
                            value={tempRecurringTimes.end}
                            onIonChange={(e) => {
                              setTempRecurringTimes({ ...tempRecurringTimes, end: e.detail.value as string });
                            }}
                          />
                        </IonModal>
                      </IonItem>
                    </>
                  )}
                  {eventDetails.eventDateType === "flexible" && (
                    <>
                      <IonRow slot="content">
                        <IonCol className="ion-text-end">
                          <IonButton
                            onClick={() => {
                              const lastEntryIdx = eventDetails.eventDates.length - 1;
                              setEventDetails({ ...eventDetails, eventDates: [...eventDetails.eventDates, { ...eventDetails.eventDates[lastEntryIdx] }] });
                            }}
                          >
                            <IonIcon icon={addCircleOutline} className="ion-margin-horizontal" />
                            Add new event date
                          </IonButton>
                        </IonCol>
                      </IonRow>
                      {eventDetails.eventDates.map((date, idx) => (
                        <IonList slot="content" key={idx}>
                          <IonTitle>{"DAY #" + (idx + 1)}</IonTitle>
                          <IonItem lines="none" button={false}>
                            <IonLabel className="font">Event Start</IonLabel>
                            <IonDatetimeButton datetime={`evtStart${idx}`} />
                            <IonModal keepContentsMounted>
                              <ModalHeader title={`#${idx + 1} Start Date & Time`} />
                              <IonDatetime
                                minuteValues={[0, 15, 30, 45]}
                                id={`evtStart${idx}`}
                                presentation="date-time"
                                hourCycle="h23"
                                value={date.start}
                                onIonChange={(e) => {
                                  const temp = [...eventDetails.eventDates];
                                  temp.splice(idx, 1, { ...eventDetails.eventDates[idx], start: e.detail.value! as string });
                                  setEventDetails({ ...eventDetails, eventDates: temp });
                                }}
                              />
                            </IonModal>
                          </IonItem>
                          <IonItem slot="content">
                            <IonLabel className="font">Event End</IonLabel>
                            <IonDatetimeButton datetime={`evtEnd${idx}`} />
                            <IonModal keepContentsMounted>
                              <ModalHeader title={`#${idx + 1} End Date & Time`} />
                              <IonDatetime
                                presentation="date-time"
                                hourCycle="h23"
                                defaultValue={date.start}
                                min={date.start}
                                minuteValues={[0, 15, 30, 45]}
                                id={`evtEnd${idx}`}
                                value={date.end}
                                onIonChange={(e) => {
                                  const temp = [...eventDetails.eventDates];
                                  temp.splice(idx, 1, { ...eventDetails.eventDates[idx], end: e.detail.value! as string });
                                  setEventDetails({ ...eventDetails, eventDates: temp });
                                }}
                              />
                            </IonModal>
                          </IonItem>
                        </IonList>
                      ))}
                      <br />
                    </>
                  )}
                </IonAccordion>
              </IonAccordionGroup>

              <IonItem>
                <IonLabel position="floating" className="font">
                  Event description
                </IonLabel>
                <IonTextarea autoGrow value={eventDetails?.description} rows={6} onIonChange={(e) => setEventDetails({ ...eventDetails, description: e.detail.value! })} />
              </IonItem>
              <IonItem>
                <IonLabel position="floating" className="font">
                  Event description (Chinese)
                </IonLabel>
                <IonTextarea autoGrow value={eventDetails?.descriptionChi} rows={6} onIonChange={(e) => setEventDetails({ ...eventDetails, descriptionChi: e.detail.value! })} />
              </IonItem>
              <StringInput evt={eventDetails} title={"Event website (optional)"} value={eventDetails?.website || ""} field={"website"} onChange={setEventDetails} />
              <OrgSelect name={"Co-organiser (optional)"} value={eventDetails?.coOrg?.organizationName ?? ""} onFocus={() => setOrgType("co-org")} />
              <OrgSelect name={"Supporting Organisation (optional)"} value={eventDetails?.supportOrg?.organizationName ?? ""} onFocus={() => setOrgType("support-org")} />
              <StringInput evt={eventDetails} title={"Funder (optional)"} value={eventDetails?.funder || ""} field={"funder"} onChange={setEventDetails} />
            </IonList>
          </IonGrid>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                disabled={!eventDetails.eventAddress || !eventDetails.eventName || !eventDetails.geolocation.lat || !eventDetails.geolocation.lng || !eventDetails.organisation || !dateIsComplete}
                onClick={handleSave}
              >
                SUBMIT
              </IonButton>
              <IonButton color="medium" expand="block" onClick={handleReset}>
                BACK
              </IonButton>
            </IonCol>
          </IonRow>

          <IonModal isOpen={openInfo} onDidDismiss={() => setOpenInfo(false)}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Event Date Types</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonRow>
                <IonCol>
                  <p>
                    <b>Single: </b>One time event
                  </p>
                  <p>
                    <b>Range: </b>Same time-range within 2 selected dates
                  </p>
                  <p>
                    <b>Recurring: </b>Different dates with same time-range
                  </p>
                  <p>
                    <b>Flexible: </b>Multiple dates with all different time-ranges
                  </p>
                </IonCol>
              </IonRow>
            </IonContent>
            <BackFooter onClick={() => setOpenInfo(false)} />
          </IonModal>
        </>
      )}
      <IonModal isOpen={!!multiAddress.length} onDidDismiss={() => setMultiAddress([])}>
        <ModalHeader title="Please select address" />
        <IonContent>
          <IonList>
            {multiAddress.map((adr, idx) => (
              <IonItem
                key={idx}
                button
                onClick={() => {
                  setEventDetails({ ...eventDetails, eventAddress: adr });
                  setMultiAddress([]);
                }}
              >
                <IonLabel>
                  <p>{adr.streetNo + " " + adr.route}</p>
                  <p>{adr.postalTown}</p>
                  <p>{adr.adminArea2}</p>
                  <p>{adr.adminArea1}</p>
                  <p>{adr.postcode}</p>
                  <p>{adr.country}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
        <BackFooter onClick={() => setMultiAddress([])} />
      </IonModal>
      <IonLoading isOpen={isLoading} message={"Loading..."} />
      <IonAlert isOpen={!!message} onDidDismiss={() => setMessage("")} message={message} buttons={[{ text: "OK", handler: handleReset }]} />

      <OrgSelectModal orgSelectModalType={{ modalType: "event", newEvt: eventDetails, setNewEvt: setEventDetails }} orgType={orgType} setOrgType={setOrgType} allOrgs={allOrgs} />
    </Layout>
  );
}

export default EditEvent;
