import { IonButton, IonCol, IonFooter, IonRow } from "@ionic/react";

interface BackFooterProps {
  onClick: () => void;
}

function BackFooter(props: BackFooterProps) {
  return (
    <IonFooter>
      <IonRow>
        <IonCol>
          <IonButton expand="block" color="medium" onClick={props.onClick}>
            BACK
          </IonButton>
        </IonCol>
      </IonRow>
    </IonFooter>
  );
}

export default BackFooter;
