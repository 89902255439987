import { IonSlides, IonSlide, IonCol, IonButton, IonIcon, IonItem } from "@ionic/react";
import { cubeSlideOpts } from "helpers/slide";
import { trashOutline } from "ionicons/icons";
import React from "react";

interface EvtPosterDisplayProps {
  eventPoster: string[];
}

export default function EvtPosterDisplay(props: EvtPosterDisplayProps) {
  const { eventPoster } = props;

  return (
    <>
      {eventPoster && eventPoster.length === 1 ? (
        <IonCol className="flex-center">
          <img src={eventPoster[0]} alt={eventPoster[0]} style={{ maxHeight: "max(64vh, 480px)", objectFit: "contain" }} />
        </IonCol>
      ) : (
        eventPoster &&
        eventPoster.length > 1 && (
          <IonSlides pager={true} options={cubeSlideOpts}>
            {eventPoster.map((i) => (
              <IonSlide key={i}>
                <img src={i} alt={i} style={{ maxHeight: "max(64vh, 480px)", objectFit: "contain", marginBottom: "40px" }} />
              </IonSlide>
            ))}
          </IonSlides>
        )
      )}
    </>
  );
}

interface EvtPosterEditProps {
  eventPoster: string[];
  isEdit?: boolean;
  onRemove?: (args0: string) => void;
}

export function EvtPosterEdit(props: EvtPosterEditProps) {
  const { eventPoster, isEdit, onRemove } = props;

  return (
    <>
      {eventPoster.map((i) => (
        <IonItem key={i}>
          <IonCol className="flex-center">
            <img src={i} alt={i} style={{ height: "max(72vh, 480px)", objectFit: "contain" }} />
            {isEdit && onRemove && (
              <div style={{ position: "absolute", top: 16, right: 16 }}>
                <IonButton color="danger" onClick={() => onRemove(i)}>
                  <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
                </IonButton>
              </div>
            )}
          </IonCol>
        </IonItem>
      ))}
    </>
  );
}
