import { IonButton, IonCol, IonContent, IonGrid, IonItem, IonList, IonLoading, IonRow, IonSlide, IonSlides, IonText } from "@ionic/react";
import { getIntEvtById } from "app/firebase";
import { IIntEvent, IOrgDetails } from "app/variables";
import i18n from "i18n";
import { Layout } from "pages/Page";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { OrgFieldDisplay, OrgModal } from "./MapContent";
import EvtPosterDisplay from "./EvtPosterDisplay";
import EvtDateDisplay from "./EvtDateDisplay";

function EventPage() {
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [viewOrg, setViewOrg] = useState<IOrgDetails | null>(null);
  const [copied, setCopied] = useState(false);
  const [event, setEvent] = useState<IIntEvent | null>(null);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await getIntEvtById(eventId);
      if (res) {
        setEvent(res);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const copyToClipboard = () => {
    if (!event) return;
    navigator.clipboard.writeText(`${event.eventName} - ${event.eventDates[0].start}\n${event.meetingLink}`);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  return (
    <Layout name={`${t("m-netEvt")} ${event ? "- " + event.eventName : ""}`} back>
      <IonContent>
        <IonLoading isOpen={isLoading} message="Loading" />
        {event && (
          <IonGrid>
            <IonList>
              <IonItem lines="none" style={{ transition: "all 0.8s" }}>
                <IonCol className="ion-text-center">
                  <IonText>
                    <h5>{i18n.language === "en" ? <b>{event.eventName}</b> : <b>{!!event.eventNameChi ? event.eventNameChi : event.eventName}</b>}</h5>
                  </IonText>
                </IonCol>
              </IonItem>
              {event.eventPoster && event.eventPoster.length && <EvtPosterDisplay eventPoster={event.eventPoster} />}
              {event.organisation && <OrgFieldDisplay name={t("host") + " :"} org={event.organisation} setViewOrg={() => setViewOrg(event.organisation)} />}
              <EvtDateDisplay eventDateType={event.eventDateType} eventDates={event.eventDates} />
              <IonItem lines="none">
                <IonText>
                  <b>Event / Meeting link:</b>
                </IonText>
                <IonText
                  className="ion-margin-horizontal"
                  style={{
                    cursor: "pointer",
                    textTransform: "lowercase",
                    textDecoration: "underline",
                  }}
                  color="primary"
                  onClick={() => {
                    if (event.website?.startsWith("http")) {
                      window.open(event.website);
                    } else {
                      window.open(`http://${event.website}`);
                    }
                  }}
                >
                  {event.meetingLink}
                </IonText>
              </IonItem>
              {!!event.website && (
                <IonItem lines="none">
                  <IonText>
                    <b>Website:</b>
                  </IonText>
                  <IonText
                    className="ion-margin-horizontal"
                    style={{
                      cursor: "pointer",
                      textTransform: "lowercase",
                      textDecoration: "underline",
                    }}
                    color="primary"
                    onClick={() => {
                      if (event.website?.startsWith("http")) {
                        window.open(event.website);
                      } else {
                        window.open(`http://${event.website}`);
                      }
                    }}
                  >
                    {event.website}
                  </IonText>
                </IonItem>
              )}
              {!!event.description && (
                <IonItem lines="none" className="ion-margin-top">
                  <IonText>
                    <b>{t("eventDes") + " :"}</b>
                    {i18n.language === "en" ? (
                      <p style={{ whiteSpace: "pre-wrap" }}>{`${event.description}`}</p>
                    ) : (
                      <p style={{ whiteSpace: "pre-wrap" }}>{`${!!event.descriptionChi ? event.descriptionChi : event.description}`}</p>
                    )}
                  </IonText>
                </IonItem>
              )}
              {!!event.coOrg?.organizationName && <OrgFieldDisplay name={"Co-organiser:"} org={event.coOrg} setViewOrg={() => setViewOrg(event.coOrg)} />}
              {!!event.supportOrg?.organizationName && <OrgFieldDisplay name={"Support organisation:"} org={event.supportOrg} setViewOrg={() => setViewOrg(event.supportOrg)} />}
            </IonList>
            <br />
            <IonRow>
              <IonCol className="ion-text-end">
                <IonButton size="default" color={copied ? "success" : "primary"} onClick={!copied ? copyToClipboard : () => {}}>
                  {copied ? "Copied to clipboard!" : "Copy event details"}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        <OrgModal viewOrg={viewOrg} setViewOrg={setViewOrg} />
      </IonContent>
    </Layout>
  );
}

export default EventPage;
