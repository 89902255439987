export type CommunityType = "local" | "nationwide" | "regional";
export type RoleType = "super-admin" | "organiser" | "dev" | "guest";

export enum ServiceLocationType {
  HomeBased = "home-based",
  CentreBased = "centre-based",
}
export enum ServiceClassType {
  Group = "group",
  OneToOne = "one-to-one",
}

export interface ICapacity {
  min: number;
  max: number;
}

export interface IServiceFee {
  criteria: string; // age group 8-12 | min 6 pax etc...
  amount: number;
  unit: string; // per class | per pax | per hour etc...
}
export interface IService {
  title: string;
  titleChi: string;
  posters: string[];
  serviceDateType: EventDateType; // "single" | "recurring" | "flexible" | "range";
  classType: ServiceClassType; // group | one-to-one
  dates: EventDateFormatInput[];
  capacity: ICapacity; // min max => IF classType is ONE-TO-ONE, ignore on render
  isFree: boolean;
  serviceFee: IServiceFee[]; // IF isFree is true, only check serviceFee criteria on render
  location: {
    serviceLocationType: ServiceLocationType; // home-based | centre-based
    address: GUnit | null; // IF serviceLocationType is home-based, ignore on render
    geolocation: { lat: number | null; lng: number | null }; // IF serviceLocationType is home-based, ignore on render
  };
  description: string;
  descriptionChi: string;
  whatsapp: string;
  mobile: string;
  email: string;
  signal: string;
  telegram: string;
  website: string;
  website2: string;
  website3: string;
  facebook: string;
  instagram: string;
  twitter: string;
  youtube: string;
  eventbrite: string;
  createdAt: Date;
  updatedAt: Date;
  subscription: EventDateFormatInput; // For future when this is a paid service to Triologic
}

export interface IOrgDetails {
  id: string;
  districts: string[];
  region: string[];
  organizationName: string;
  email1: string;
  email1_label: string;
  email2?: string;
  email2_label?: string;
  email3?: string;
  email3_label?: string;
  email4?: string;
  email4_label?: string;
  website?: string;
  website2?: string;
  website3?: string;

  facebook?: string;
  instagram?: string;
  twitter?: string;
  youtube?: string;
  eventbrite?: string;
  createdAt: Date;
  updatedAt: Date;
  communityType: CommunityType;
  logo?: string;

  whatsapp?: string;
  phone?: string;
  address?: string; // (text pre-wrap)
  signal?: string; // (a link)
  telegram?: string;

  description?: string; // (text pre-wrap)
}

export interface GUnit {
  streetNo: string;
  country: string;
  adminArea1: string;
  adminArea2: string;
  postalTown: string;
  route: string;
  postcode: string;
  formattedAddress?: string;
}

export interface IEventDetails {
  eventId: string;
  eventName: string;
  eventNameChi?: string;
  coOrg: IOrgDetails | null;
  supportOrg: IOrgDetails | null;
  funder: string;
  eventDates: EventDateFormat[];
  eventDateType: EventDateType;
  geolocation: { lat: number | null; lng: number | null };
  organisation: IOrgDetails | null;
  website?: string;
  eventAddress: GUnit;
  description: string;
  descriptionChi?: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: IStaff | null;
  lastDatetime: Date;
  eventPoster?: string[];
}
export interface IEventDetailsInput {
  eventId: string;
  eventName: string;
  eventNameChi?: string;
  funder: string;
  eventDates: EventDateFormatInput[];
  eventDateType: EventDateType;
  geolocation: { lat: number | null; lng: number | null };
  coOrg: IOrgDetails | null;
  supportOrg: IOrgDetails | null;
  organisation: IOrgDetails | null;
  website?: string;
  eventAddress: GUnit;
  description: string;
  descriptionChi?: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: IStaff | null;
  lastDatetime: Date;
  eventPoster?: string[];
}
export interface IIntEvent {
  eventId: string;
  eventName: string;
  eventNameChi?: string;
  coOrg: IOrgDetails | null;
  supportOrg: IOrgDetails | null;
  funder: string;
  eventDates: EventDateFormatInput[];
  eventDateType: EventDateType;
  organisation: IOrgDetails | null;
  website?: string;
  description: string;
  descriptionChi?: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: IStaff | null;
  lastDatetime: Date;
  eventPoster?: string[];
  meetingLink: string;
}

export type EventDateType = "single" | "recurring" | "flexible" | "range";

export interface EventDateFormat {
  start: Date;
  end: Date;
}
export interface EventDateFormatInput {
  start: string;
  end: string;
}

export interface INotify {
  id: string;
  userIp: string;
  type: NotifyType;
  district: string;
  createdAt: Date;
}

export interface IContactDev {
  id: string;
  fromEmail: string;
  subject: string;
  message: string;
  userIp: string;
  needReply: boolean;
  createdAt: Date;
}
export interface IContactAdmin {
  id: string;
  admin: IStaff | null;
  subject: string;
  message: string;
  userIp: string;
  createdAt: Date;
}

type NotifyType = "No organization found";

export interface IStaff {
  id: string;
  email: string;
  communityType: CommunityType;
  district: string[];
  organisation: string[];
  role: RoleType; // create event / edit event for Organiser
  disabled: boolean; // disable all edit/create rights
  vcode?: {
    code: string;
    expire: Date;
  };
}

export interface IAnnouncement {
  id: string;
  title: string;
  description: string;
  img: string[];
  externalLink: string;
  createdAt: Date;
  validTill: Date | null;
  coOrg: IOrgDetails | null;
  supportOrg: IOrgDetails | null;
  organisation: IOrgDetails | null;
  funder: string;
}

export const allRoles: RoleType[] = ["dev", "super-admin", "organiser", "guest"];
export const canCreate: RoleType[] = ["dev", "super-admin", "organiser"];

export const orgCardInfo = {
  facebook: { name: "Facebook", icon: "logos:facebook" },
  instagram: { name: "Instagram", icon: "skill-icons:instagram" },
  whatsapp: { name: "Whatsapp", icon: "logos:whatsapp" },
  telegram: { name: "Telegram", icon: "logos:telegram" },
  signal: { name: "Signal", icon: "logos:signal" },
  twitter: { name: "Twitter", icon: "logos:twitter" },
  youtube: { name: "YouTube", icon: "logos:youtube-icon" },
  eventbrite: { name: "Eventbrite", icon: "logos:eventbrite-icon" },
  website: { name: "Website", icon: "mdi:web" },
  website2: { name: "Website #2", icon: "mdi:web" },
  website3: { name: "Website #3", icon: "mdi:web" },
  email: { name: "Email", icon: "entypo:email" },
  email2: { name: "Email #2", icon: "entypo:email" },
  email3: { name: "Email #3", icon: "entypo:email" },
  email4: { name: "Email #4", icon: "entypo:email" },
  address: { name: "Address", icon: "mdi:address-marker" },
  description: { name: "Description", icon: "uiw:message" },
  districts: { name: "District(s)", icon: "emojione-monotone:letter-d" },
  region: { name: "Region", icon: "emojione-monotone:letter-r" },
};

export const preWrapTxt = {
  districts: "District(s)",
  orgName: "Organisation name",
  description: orgCardInfo.description.name,
  address: orgCardInfo.address.name,
};
// SECURITY
// on submit create / edit event => trigger auto email notification to super-admin & creator

// Cloud function
// delete events after event ends

// About page + copyright disclaimer

// e.g. `All present and future rights to intellectual property
// including inventions and improvement, trademarks (whether registered or common law trademarks),
// patents, designs, copyright, and corresponding property rights under the laws of any jurisdiction.`

// image upload for event page

// Ask about event category
// i18n
// RSVP
// open gmail for this project
// Google analytics
// FB & Google login

export const emptyService: IService = {
  title: "",
  titleChi: "",
  posters: [],
  serviceDateType: "single",
  classType: ServiceClassType.OneToOne,
  dates: [],
  capacity: { min: 1, max: 1 },
  isFree: false,
  serviceFee: [],
  location: {
    serviceLocationType: ServiceLocationType.CentreBased,
    address: null,
    geolocation: { lat: null, lng: null },
  },
  description: "",
  descriptionChi: "",
  whatsapp: "",
  mobile: "",
  email: "",
  signal: "",
  telegram: "",
  website: "",
  website2: "",
  website3: "",
  facebook: "",
  instagram: "",
  twitter: "",
  youtube: "",
  eventbrite: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  subscription: { start: new Date().toISOString(), end: new Date("2025-01-01").toISOString() },
};

export const emptyAnnouncement: IAnnouncement = {
  id: "new",
  title: "",
  description: "",
  img: [],
  externalLink: "",
  createdAt: new Date(),
  validTill: null,
  coOrg: null,
  supportOrg: null,
  organisation: null,
  funder: "",
};
