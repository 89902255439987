import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "./App.css";
import "./theme/variables.css";

import { useJsApiLoader } from "@react-google-maps/api";
import { useAppSelector } from "app/hooks";
import { selectAuthUser, selectAuthUserIsInit } from "app/slices/authSlice";
import AuthCheck from "components/AuthCheck";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import LoginPage from "pages/LoginPage";
import StaffManagement from "pages/StaffManagement";
import { useEffect } from "react";
import Geocode from "react-geocode";
import { initFirebase } from "./app/firebase";
import { RoleType, allRoles, canCreate } from "./app/variables";
import ContactDev from "./components/ContactDev";
import ControlContent from "./components/ControlContent";
import DevMessages from "./components/DevMessages";
import EditEvent from "./components/EditEvent";
import MapContent from "./components/MapContent";
import NewEvent from "./components/NewEvent";
// import NewOrg from "./components/NewOrg";
import OrgManagement from "./components/OrgManagement";
import OrgMapPage from "./components/OrgMapPage";
import ReadStaffEnquiries from "./components/ReadStaffEnquiries";
import StaffEnquiry from "./components/StaffEnquiry";
import NoPermissionPage from "./pages/NoPermissionPage";
import ForgotPassword from "pages/forgotPassword";
import NewIntEvent from "components/NewIntEvent";
import NetEvents from "components/NetEvents";
import EventPage from "components/EventPage";
import EditIntEvent from "components/EditIntEvent";
import OrgCreateEdit from "components/OrgCreateEdit";
import EditNewAnnouncement from "components/EditNewAnnouncement";
import AnnouncementsList from "components/AnnouncementsList";

setupIonicReact({
  mode: "md",
});

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/London");

interface RoleCheckedRouteProps {
  path: string;
  exact?: boolean;
  component: React.FC | any;
  allowedRoles: RoleType[];
}

const RoleCheckedRoute: React.FC<RoleCheckedRouteProps> = (props) => {
  const { path, exact, component, allowedRoles } = props;
  const authUser = useAppSelector(selectAuthUser);
  const authUserIsInit = useAppSelector(selectAuthUserIsInit);
  if (!authUserIsInit || !authUser) {
    return null;
  }
  return (
    <Route path={path} exact={exact || false}>
      {allowedRoles.includes(authUser.role) ? component : <NoPermissionPage />}
    </Route>
  );
};

const App: React.FC = () => {
  const authUserIsInit = useAppSelector(selectAuthUserIsInit);
  const { isLoaded } = useJsApiLoader({
    id: String(process.env.REACT_APP_API_ID),
    mapIds: [String(process.env.REACT_APP_MAP_ID)],
    googleMapsApiKey: String(process.env.REACT_APP_GEO_KEY),
  });
  useEffect(() => {
    initFirebase();
    if (!process.env.REACT_APP_GEO_KEY) return;
    Geocode.setApiKey(process.env.REACT_APP_GEO_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("uk");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
  }, []);

  return (
    <IonApp>
      {authUserIsInit && (
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <AuthCheck>
                <Switch>
                  <Route path="/login" exact component={LoginPage} />
                  <Route path="/forgot-password" exact component={ForgotPassword} />
                  <RoleCheckedRoute path="/event-map" allowedRoles={allRoles} exact component={<MapContent />} />

                  <RoleCheckedRoute path="/new-event" allowedRoles={canCreate} exact component={<NewEvent />} />
                  <RoleCheckedRoute path="/new-net-event" allowedRoles={canCreate} exact component={<NewIntEvent />} />
                  <RoleCheckedRoute path="/edit/:id" allowedRoles={canCreate} component={<EditEvent />} />

                  <RoleCheckedRoute path="/net-events" allowedRoles={allRoles} exact component={<NetEvents />} />
                  <RoleCheckedRoute path="/net-events/edit/:eventId" allowedRoles={allRoles} exact component={<EditIntEvent />} />
                  <RoleCheckedRoute path="/net-events/:eventId" allowedRoles={allRoles} component={<EventPage />} />

                  <RoleCheckedRoute path="/announcements" allowedRoles={allRoles} exact component={<AnnouncementsList />} />
                  <RoleCheckedRoute path="/new-announcement" allowedRoles={allRoles} exact component={<EditNewAnnouncement />} />
                  <RoleCheckedRoute path="/announcement/:id" allowedRoles={allRoles} component={<EditNewAnnouncement />} />

                  <RoleCheckedRoute path="/organisations" allowedRoles={allRoles} exact component={<OrgMapPage />} />
                  <RoleCheckedRoute path="/control" allowedRoles={allRoles} exact component={<ControlContent />} />

                  <RoleCheckedRoute path="/send-staff-enquiry" allowedRoles={canCreate} exact component={<StaffEnquiry />} />
                  <RoleCheckedRoute path="/contact-us" allowedRoles={allRoles} exact component={<ContactDev />} />

                  <RoleCheckedRoute path="/org-manage/new" exact allowedRoles={["super-admin"]} component={<OrgCreateEdit />} />
                  <RoleCheckedRoute path="/org-manage/:id" exact allowedRoles={["super-admin"]} component={<OrgCreateEdit />} />
                  {/* <RoleCheckedRoute path="/new-organisation" allowedRoles={["super-admin"]} exact component={<NewOrg />} /> */}
                  {/* <RoleCheckedRoute path="/org-manage/:id" allowedRoles={["super-admin"]} component={<OrgDetails />} /> */}
                  <RoleCheckedRoute path="/org-manage" allowedRoles={["super-admin"]} exact component={<OrgManagement />} />

                  <RoleCheckedRoute path="/staff-management" allowedRoles={["super-admin"]} exact component={<StaffManagement />} />
                  <RoleCheckedRoute path="/read-staff-enquiries" allowedRoles={["super-admin"]} exact component={<ReadStaffEnquiries />} />
                  <RoleCheckedRoute path="/dev-messages" allowedRoles={["super-admin"]} exact component={<DevMessages />} />
                  <Redirect to={"/event-map"} />
                </Switch>
              </AuthCheck>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      )}
    </IonApp>
  );
};

export default App;
