import {
  IonAccordion,
  IonAccordionGroup,
  IonAlert,
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { defaultIntEvt, defaultNewDateInput } from "app/defaultValues";
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "app/slices/authSlice";
import dayjs from "dayjs";
import { collection, getDocs, getFirestore, orderBy, query } from "firebase/firestore";
import { addCircleOutline, checkmarkCircleOutline, informationCircleOutline, trashBinOutline, trashOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useParams } from "react-router";
import { collections, generalDelete, getIntEvtById, updateIntEvent, uploadImage } from "../app/firebase";
import { EventDateFormat, EventDateFormatInput, EventDateType, IIntEvent, IOrgDetails } from "../app/variables";
import { sortDatesAsc, sortEventsAsc } from "../helpers/date";
import { Layout } from "../pages/Page";
import BackFooter from "./BackFooter";
import { EvtPosterEdit } from "./EvtPosterDisplay";
import { IntStringInput, ModalHeader } from "./InputComponents";
import { UploadFilesType } from "./NewIntEvent";
import OrgSelectModal, { OrgFieldTypes, OrgSelect } from "./OrgSelectModal";

const newEMsg = "Event edit success";

function EditIntEvent() {
  const { eventId } = useParams<{ eventId: string }>();
  const authUser = useAppSelector(selectAuthUser);
  const [eventDetails, setEvtDetails] = useState<IIntEvent | null>(null);
  const [message, setMessage] = useState("");
  const [delMsg, setDelMsg] = useState("");

  const [openInfo, setOpenInfo] = useState(false);
  const [tempRecurringDates, setTempRecurringDates] = useState([""]);
  const [tempRecurringTimes, setTempRecurringTimes] = useState(defaultNewDateInput);
  const [dateRange, setDateRange] = useState(defaultNewDateInput);
  const [dateIsComplete, setDateIsComplete] = useState(false);
  const [allOrgs, setAllOrgs] = useState<IOrgDetails[]>([]);
  const [orgType, setOrgType] = useState<OrgFieldTypes>(null);
  const [isLoading, setIsLoading] = useState(false);
  const uploadRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<UploadFilesType[]>([]);
  const router = useIonRouter();

  const handleReset = useCallback(() => {
    setDateRange(defaultNewDateInput);
    setTempRecurringDates([""]);
    setTempRecurringTimes(defaultNewDateInput);
    setEvtDetails(defaultIntEvt);
    setFiles([]);
  }, []);

  const compressImage = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        4000,
        4000,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const imageInput = async (files: FileList) => {
    if (!eventDetails) return;
    const tmpFiles: UploadFilesType[] = [];
    if (eventDetails.eventPoster && eventDetails.eventPoster.length) {
      eventDetails.eventPoster.forEach((i) => tmpFiles.push({ imageData: i, file: null }));
    }
    for await (const file of files) {
      const compressed = await compressImage(file);
      if (compressed instanceof File) {
        const tmp: UploadFilesType = { imageData: URL.createObjectURL(compressed), file: compressed };
        tmpFiles.push(tmp);
      }
    }
    setFiles(tmpFiles);
  };

  const handleAddNewEvent = useCallback(async () => {
    if (!eventDetails) return;
    if (!authUser) return;
    setIsLoading(true);
    let tmpEvt: any = { ...eventDetails };
    tmpEvt.meetingLink = tmpEvt.meetingLink.toLowerCase();
    const imgLinks: string[] = eventDetails.eventPoster ? [...eventDetails.eventPoster] : [];
    if (files && files.length) {
      for await (const f of files) {
        if (f.file) {
          const fileStr = await uploadImage(f.file);
          imgLinks.push(fileStr);
        }
      }
      tmpEvt.eventPoster = imgLinks;
    }
    try {
      let evtDates: EventDateFormat[] = [];
      let res: any;
      if (eventDetails.eventDateType === "range") {
        const cutSDate = dayjs(dateRange.start).format("YYYY-MM-DD");
        const cutEDate = dayjs(dateRange.end).format("YYYY-MM-DD");
        const start = new Date(cutSDate.concat(dayjs(tempRecurringTimes.start).format("THH:mm")));
        const end = new Date(cutEDate.concat(dayjs(tempRecurringTimes.end).format("THH:mm")));
        evtDates.push({ start, end });
        tmpEvt.eventDates = evtDates;
        res = await updateIntEvent({ ...tmpEvt, createdAt: new Date(), updatedAt: new Date(), updatedBy: authUser, lastDatetime: end });
      } else if (eventDetails.eventDateType === "recurring") {
        let lastDate = new Date();
        for (let evtDate of tempRecurringDates) {
          const start = new Date(evtDate.concat(dayjs(tempRecurringTimes.start).format("THH:mm")));
          const end = new Date(evtDate.concat(dayjs(tempRecurringTimes.end).format("THH:mm")));
          lastDate = end;
          evtDates.push({ start, end });
        }
        tmpEvt.eventDates = evtDates;
        res = await updateIntEvent({ ...tmpEvt, createdAt: new Date(), updatedAt: new Date(), updatedBy: authUser, lastDatetime: lastDate });
      } else if (eventDetails.eventDateType === "flexible") {
        tmpEvt.eventDates = await sortEventsAsc(eventDetails.eventDates, true);
        res = await updateIntEvent({ ...tmpEvt, createdAt: new Date(), updatedAt: new Date(), updatedBy: authUser, lastDatetime: tmpEvt.eventDates[tmpEvt.eventDates.length - 1] });
      } else {
        const evtDateS = new Date(eventDetails.eventDates[0].start);
        const evtDateE = new Date(dayjs(eventDetails.eventDates[0].start).format("YYYY-MM-DD").concat(dayjs(eventDetails.eventDates[0].end).format("THH:mm")));
        const tmp: IIntEvent = { ...tmpEvt, eventDates: [{ start: evtDateS, end: evtDateE }], createdAt: new Date(), updatedAt: new Date(), updatedBy: authUser, lastDatetime: evtDateE };
        res = await updateIntEvent(tmp);
      }
      if (res.msg === "success" && !!res.id) {
        // handleReset();
        setMessage(newEMsg);
      } else {
        setMessage(res);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [authUser, dateRange.end, dateRange.start, files, eventDetails, tempRecurringDates, tempRecurringTimes.end, tempRecurringTimes.start]);

  useEffect(() => {
    if (!eventDetails) return;
    if (
      (eventDetails.eventDateType === "single" && new Date(eventDetails.eventDates[0].start) > new Date()) ||
      (eventDetails.eventDateType === "range" && new Date(dateRange.start) > new Date() && new Date(dateRange.end) > new Date()) ||
      (eventDetails.eventDateType === "recurring" && tempRecurringDates.length > 1) ||
      (eventDetails.eventDateType === "flexible" && eventDetails.eventDates.length > 1)
    ) {
      setDateIsComplete(true);
    } else {
      setDateIsComplete(false);
    }
  }, [dateRange.end, dateRange.start, eventDetails?.eventDateType, eventDetails?.eventDates, tempRecurringDates.length]);

  const handleFetch = useCallback(async (id: string) => {
    try {
      const res = await getIntEvtById(id);
      console.log(res);
      if (res) {
        if (res.eventDateType === "range") {
          setDateRange({ start: new Date(res.eventDates[0].start).toISOString(), end: new Date(res.eventDates[0].end).toISOString() });
          setTempRecurringTimes({ start: new Date(res.eventDates[0].start).toISOString(), end: new Date(res.eventDates[0].end).toISOString() });
        }
        if (res.eventDateType === "recurring") {
          let temp: string[] = [];
          res.eventDates.forEach((evtDate) => {
            temp.push(dayjs(evtDate.start).format("YYYY-MM-DD"));
          });
          setTempRecurringDates(temp);
          setTempRecurringTimes({ start: new Date(res.eventDates[0].start).toISOString(), end: new Date(res.eventDates[0].end).toISOString() });
        }
        const evtDates: EventDateFormatInput[] = [];
        res.eventDates.forEach((evt) => {
          evtDates.push({
            start: new Date(evt.start).toISOString(),
            end: new Date(evt.end).toISOString(),
          });
        });
        const obj = {
          ...res,
          eventDates: evtDates,
        };
        // console.log(obj);
        setEvtDetails(obj);
        if (obj.eventPoster && obj.eventPoster.length) {
          const imgData: UploadFilesType[] = [];
          obj.eventPoster.forEach((i) => {
            imgData.push({ imageData: i, file: null });
          });
          setFiles(imgData);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (eventId) {
      setIsLoading(true);
      handleFetch(eventId);
    }
  }, [eventId, handleFetch]);

  const handleFetchOrgs = useCallback(async () => {
    const db = getFirestore();
    const q = query(collection(db, "organizations"), orderBy("organizationName", "asc"));
    try {
      const sss = await getDocs(q);
      let temp: IOrgDetails[] = [];
      sss.forEach((ss) => {
        temp.push({ id: ss.id, ...ss.data() } as IOrgDetails);
      });
      setAllOrgs(temp);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleRemoveImage = (img: string) => {
    if (!files.length) return;
    const foundIdx = files.findIndex((i) => i.imageData === img);
    if (foundIdx >= 0) {
      const tmp = [...files];
      tmp.splice(foundIdx, 1);
      setFiles(tmp);
    }
  };

  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    try {
      await generalDelete(collections.intEvents, eventId);
      setDelMsg("");
      setMessage("Event deleted");
      setTimeout(() => router.goBack(), 1500);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, router]);

  useEffect(() => {
    if (!allOrgs.length) {
      handleFetchOrgs();
    }
  }, [allOrgs.length, handleFetchOrgs]);

  return (
    <Layout
      back
      name={isLoading || !eventDetails ? "Edit Internet Event" : `Edit Internet Event - ${eventDetails.eventName}`}
      extraButton={
        <IonButton slot="end" className="ion-margin-horizontal" color="danger" onClick={() => setDelMsg("Confirm to delete this internet event?")}>
          <IonIcon icon={trashOutline} />
        </IonButton>
      }
    >
      {isLoading ? (
        <></>
      ) : (
        eventDetails && (
          <>
            <IonGrid>
              <IonList>
                <IonItem lines={files.length ? "none" : "full"}>
                  <IonLabel className="font">Event Poster</IonLabel>
                  <IonButton slot="end" expand="block" onClick={() => uploadRef.current?.click()}>
                    UPLOAD
                  </IonButton>
                  <input type="file" accept="image/*" style={{ display: "none" }} multiple ref={uploadRef} onChange={(e) => (e.target.files ? imageInput(e.target.files) : {})} />
                </IonItem>
                {!!files.length && <EvtPosterEdit eventPoster={files.map((i) => i.imageData)} isEdit onRemove={handleRemoveImage} />}
                <IntStringInput evt={eventDetails} title={"Event Name"} value={eventDetails.eventName || ""} field={"eventName"} onChange={setEvtDetails} />
                <IntStringInput evt={eventDetails} title={"Event Name in Chinese"} value={eventDetails.eventNameChi || ""} field={"eventNameChi"} onChange={setEvtDetails} />
                <IntStringInput evt={eventDetails} title={"Event / Meeting link"} value={eventDetails.meetingLink || ""} field={"meetingLink"} onChange={setEvtDetails} />
                <OrgSelect name={"Organisation name"} value={eventDetails.organisation?.organizationName ?? ""} onFocus={() => setOrgType("host")} />
                <IonAccordionGroup>
                  <IonAccordion value="evtDate">
                    <IonItem slot="header">
                      <IonText>
                        <h5>
                          <b>Event Date & Time</b>
                        </h5>
                      </IonText>
                      {!dateIsComplete ? (
                        <IonCardSubtitle color="danger" className="ion-margin-horizontal">
                          REQUIRED
                        </IonCardSubtitle>
                      ) : (
                        <IonIcon className="ion-margin-horizontal" icon={checkmarkCircleOutline} color="success" size={"large"} />
                      )}
                    </IonItem>
                    <IonItem slot="content" lines="none" className="ion-text-center">
                      <IonLabel className="font">Select Event Date Type</IonLabel>
                      <IonButtons slot="end">
                        <IonButton onClick={() => setOpenInfo(!openInfo)}>
                          <IonIcon slot="icon-only" icon={informationCircleOutline} size="large" color="medium" />
                        </IonButton>
                      </IonButtons>
                    </IonItem>
                    <IonItem slot="content" lines="none">
                      <IonSegment
                        value={eventDetails.eventDateType}
                        onIonChange={(e) => {
                          setDateRange(defaultNewDateInput);
                          setTempRecurringDates([""]);
                          setEvtDetails({ ...eventDetails, eventDateType: e.detail.value as EventDateType, eventDates: [defaultNewDateInput] });
                        }}
                      >
                        <IonSegmentButton value="single">Single</IonSegmentButton>
                        <IonSegmentButton value="range">Range</IonSegmentButton>
                        <IonSegmentButton value="recurring">Recurring</IonSegmentButton>
                        <IonSegmentButton value="flexible">Flexible</IonSegmentButton>
                      </IonSegment>
                    </IonItem>
                    {eventDetails.eventDateType === "single" && (
                      <>
                        <IonItem slot="content" lines="none">
                          <IonLabel className="font">Event Start</IonLabel>
                          <IonDatetimeButton datetime="evtStart" />
                          <IonModal keepContentsMounted>
                            <ModalHeader title={"Select Start Date & Time"} />
                            <IonDatetime
                              minuteValues={[0, 15, 30, 45]}
                              id="evtStart"
                              presentation="date-time"
                              hourCycle="h23"
                              value={eventDetails.eventDates[0].start}
                              onIonChange={(e) => {
                                setEvtDetails({
                                  ...eventDetails,
                                  eventDates: [{ ...eventDetails.eventDates[0], start: e.detail.value! as string }],
                                });
                              }}
                            />
                          </IonModal>
                        </IonItem>
                        <IonItem slot="content">
                          <IonLabel className="font">Event End</IonLabel>
                          <IonDatetimeButton datetime="evtEnd" />
                          <IonModal keepContentsMounted>
                            <ModalHeader title={"Select End Date & Time"} />
                            <IonDatetime
                              presentation="time"
                              hourCycle="h23"
                              minuteValues={[0, 15, 30, 45]}
                              id="evtEnd"
                              value={eventDetails.eventDates[0].end}
                              onIonChange={(e) => {
                                setEvtDetails({
                                  ...eventDetails,
                                  eventDates: [
                                    {
                                      ...eventDetails.eventDates[0],
                                      end: e.detail.value! as string,
                                    },
                                  ],
                                });
                              }}
                            />
                          </IonModal>
                        </IonItem>
                      </>
                    )}
                    {eventDetails.eventDateType === "range" && (
                      <>
                        <IonItem slot="content" lines="none">
                          <IonLabel className="font">Start Date</IonLabel>
                          <IonDatetimeButton datetime="evtStartDate" />
                          <IonModal keepContentsMounted>
                            <ModalHeader title={"Select Start Date"} />
                            <IonDatetime
                              presentation="date"
                              hourCycle="h23"
                              defaultValue={"1970-01-01T00:00:00Z"}
                              min={defaultNewDateInput.start}
                              id="evtStartDate"
                              value={dateRange.start}
                              onIonChange={(e) => setDateRange({ ...dateRange, start: e.detail.value as string })}
                            />
                          </IonModal>
                        </IonItem>
                        <IonItem slot="content" lines="none">
                          <IonLabel className="font">End Date</IonLabel>
                          <IonDatetimeButton datetime="evtEndDate" />
                          <IonModal keepContentsMounted>
                            <ModalHeader title={"Select End Date"} />
                            <IonDatetime
                              presentation="date"
                              hourCycle="h23"
                              defaultValue={"1970-01-01T00:00:00Z"}
                              min={dateRange.start}
                              id="evtEndDate"
                              value={dateRange.end}
                              onIonChange={(e) => setDateRange({ ...dateRange, end: e.detail.value as string })}
                            />
                          </IonModal>
                        </IonItem>
                        <br />
                        <IonItem slot="content" lines="none">
                          <IonLabel className="font">Daily Start Time</IonLabel>
                          <IonDatetimeButton datetime="evtStart" />
                          <IonModal keepContentsMounted>
                            <ModalHeader title={"Start Time"} />
                            <IonDatetime
                              presentation="time"
                              hourCycle="h23"
                              defaultValue={"1970-01-01T00:00:00Z"}
                              minuteValues={[0, 15, 30, 45]}
                              id="evtStart"
                              value={tempRecurringTimes.start}
                              onIonChange={(e) => setTempRecurringTimes({ ...tempRecurringTimes, start: e.detail.value as string })}
                            />
                          </IonModal>
                        </IonItem>
                        <IonItem slot="content">
                          <IonLabel className="font">Daily End Time</IonLabel>
                          <IonDatetimeButton datetime="evtEnd" />
                          <IonModal keepContentsMounted>
                            <ModalHeader title={"End Time"} />
                            <IonDatetime
                              presentation="time"
                              hourCycle="h23"
                              defaultValue={"1970-01-01T00:00:00Z"}
                              minuteValues={[0, 15, 30, 45]}
                              id="evtEnd"
                              value={tempRecurringTimes.end}
                              onIonChange={(e) => setTempRecurringTimes({ ...tempRecurringTimes, end: e.detail.value as string })}
                            />
                          </IonModal>
                        </IonItem>
                      </>
                    )}
                    {eventDetails.eventDateType === "recurring" && (
                      <>
                        <IonItem slot="content" lines="none">
                          <IonLabel className="font" style={{ whiteSpace: "pre-wrap" }}>
                            Select Recurring Dates
                          </IonLabel>
                          <IonDatetime
                            id="multi-date"
                            multiple
                            min={defaultNewDateInput.start}
                            presentation="date"
                            hourCycle="h23"
                            // value={eventDetails.multiDates}
                            onIonChange={async (e) => {
                              const tmp = [...(e.detail.value as string[])];
                              setTempRecurringDates(await sortDatesAsc(tmp));
                            }}
                          />
                        </IonItem>
                        <br />
                        <IonItem slot="content" lines="none">
                          <IonLabel className="font">Daily Start Time</IonLabel>
                          <IonDatetimeButton datetime="evtStart" />
                          <IonModal keepContentsMounted>
                            <ModalHeader title={"Select Start Time"} />
                            <IonDatetime
                              presentation="time"
                              hourCycle="h23"
                              defaultValue={"1970-01-01T00:00:00Z"}
                              minuteValues={[0, 15, 30, 45]}
                              id="evtStart"
                              value={tempRecurringTimes.start}
                              onIonChange={(e) => {
                                setTempRecurringTimes({ ...tempRecurringTimes, start: e.detail.value as string });
                              }}
                            />
                          </IonModal>
                        </IonItem>
                        <IonItem slot="content">
                          <IonLabel className="font">Daily End Time</IonLabel>
                          <IonDatetimeButton datetime="evtEnd" />
                          <IonModal keepContentsMounted>
                            <ModalHeader title={"Select End Time"} />
                            <IonDatetime
                              presentation="time"
                              hourCycle="h23"
                              defaultValue={"1970-01-01T00:00:00Z"}
                              minuteValues={[0, 15, 30, 45]}
                              id="evtEnd"
                              value={tempRecurringTimes.end}
                              onIonChange={(e) => {
                                setTempRecurringTimes({ ...tempRecurringTimes, end: e.detail.value as string });
                              }}
                            />
                          </IonModal>
                        </IonItem>
                      </>
                    )}
                    {eventDetails.eventDateType === "flexible" && (
                      <>
                        <IonRow slot="content">
                          <IonCol className="ion-text-end">
                            <IonButton
                              onClick={() => {
                                const lastEntryIdx = eventDetails.eventDates.length - 1;
                                setEvtDetails({ ...eventDetails, eventDates: [...eventDetails.eventDates, { ...eventDetails.eventDates[lastEntryIdx] }] });
                              }}
                            >
                              <IonIcon icon={addCircleOutline} className="ion-margin-horizontal" />
                              Add new event date
                            </IonButton>
                          </IonCol>
                        </IonRow>
                        {eventDetails.eventDates.map((date, idx) => (
                          <IonList slot="content" key={idx}>
                            <IonText>
                              <h5>
                                <b>{"#" + (idx + 1)}</b>
                              </h5>
                            </IonText>
                            <IonItem lines="none" button={false}>
                              <IonLabel className="font">Event Start</IonLabel>
                              <IonDatetimeButton datetime={`evtStart${idx}`} />
                              <IonModal keepContentsMounted>
                                <ModalHeader title={`#${idx + 1} Start Date & Time`} />
                                <IonDatetime
                                  minuteValues={[0, 15, 30, 45]}
                                  id={`evtStart${idx}`}
                                  presentation="date-time"
                                  hourCycle="h23"
                                  value={date.start}
                                  onIonChange={(e) => {
                                    const temp = [...eventDetails.eventDates];
                                    temp.splice(idx, 1, { ...eventDetails.eventDates[idx], start: e.detail.value! as string });
                                    setEvtDetails({ ...eventDetails, eventDates: temp });
                                  }}
                                />
                              </IonModal>
                            </IonItem>
                            <IonItem slot="content">
                              <IonLabel className="font">Event End</IonLabel>
                              <IonDatetimeButton datetime={`evtEnd${idx}`} />
                              <IonModal keepContentsMounted>
                                <ModalHeader title={`#${idx + 1} End Date & Time`} />
                                <IonDatetime
                                  presentation="time"
                                  hourCycle="h23"
                                  minuteValues={[0, 15, 30, 45]}
                                  id={`evtEnd${idx}`}
                                  value={date.end}
                                  onIonChange={(e) => {
                                    const temp = [...eventDetails.eventDates];
                                    temp.splice(idx, 1, { ...eventDetails.eventDates[idx], end: e.detail.value! as string });
                                    setEvtDetails({ ...eventDetails, eventDates: temp });
                                  }}
                                />
                              </IonModal>
                            </IonItem>
                          </IonList>
                        ))}
                        <br />
                      </>
                    )}
                  </IonAccordion>
                </IonAccordionGroup>

                <IonItem>
                  <IonLabel position="floating" className="font">
                    Event description (Include the RSVP link here if applicable)
                  </IonLabel>
                  <IonTextarea autoGrow value={eventDetails.description} rows={6} onIonChange={(e) => setEvtDetails({ ...eventDetails, description: e.detail.value! })} />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating" className="font">
                    Event description (Chinese)
                  </IonLabel>
                  <IonTextarea autoGrow value={eventDetails.descriptionChi} rows={6} onIonChange={(e) => setEvtDetails({ ...eventDetails, descriptionChi: e.detail.value! })} />
                </IonItem>
                <IntStringInput evt={eventDetails} title={"Event website (optional)"} value={eventDetails.website || ""} field={"website"} onChange={setEvtDetails} />
                <OrgSelect name={"Co-organiser (optional)"} value={eventDetails.coOrg?.organizationName ?? ""} onFocus={() => setOrgType("co-org")} />
                <OrgSelect name={"Supporting Organisation (optional)"} value={eventDetails.supportOrg?.organizationName ?? ""} onFocus={() => setOrgType("support-org")} />
                <IntStringInput evt={eventDetails} title={"Funder (optional)"} value={eventDetails.funder || ""} field={"funder"} onChange={setEvtDetails} />
              </IonList>
            </IonGrid>
            <IonRow>
              <IonCol>
                <IonButton expand="block" disabled={!eventDetails.eventName || !eventDetails.organisation || !dateIsComplete} onClick={handleAddNewEvent}>
                  SUBMIT
                </IonButton>
                <IonButton color="medium" expand="block" onClick={handleReset}>
                  CLEAR FORM
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )
      )}
      {/* INFORMATION MODAL */}
      <IonModal isOpen={openInfo} onDidDismiss={() => setOpenInfo(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Event Date Types</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRow>
            <IonCol>
              <p>
                <b>Single: </b>One time event
              </p>
              <p>
                <b>Range: </b>Same time-range within 2 selected dates
              </p>
              <p>
                <b>Recurring: </b>Different dates with same time-range
              </p>
              <p>
                <b>Flexible: </b>Multiple dates with all different time-ranges
              </p>
            </IonCol>
          </IonRow>
        </IonContent>
        <BackFooter onClick={() => setOpenInfo(false)} />
      </IonModal>
      <IonLoading isOpen={isLoading} message={"Loading"} />
      <IonAlert
        isOpen={!!message}
        message={message}
        onDidDismiss={() => setMessage("")}
        buttons={[
          {
            text: "OK",
            handler: () => {
              setMessage("");
              if (message === newEMsg) {
                window.location.reload();
              }
            },
          },
        ]}
      />
      <IonAlert
        isOpen={!!delMsg}
        message={delMsg}
        onDidDismiss={() => setDelMsg("")}
        buttons={[
          {
            text: "OK",
            handler: () => handleDelete(),
          },
        ]}
      />
      {eventDetails && (
        <OrgSelectModal orgSelectModalType={{ modalType: "int-event", newIntEvt: eventDetails, setNewIntEvt: setEvtDetails }} orgType={orgType} setOrgType={setOrgType} allOrgs={allOrgs} />
      )}
    </Layout>
  );
}

export default EditIntEvent;
