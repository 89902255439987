import {
  IonAccordion,
  IonAccordionGroup,
  IonAlert,
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "app/slices/authSlice";
import { addDoc, collection, doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { arrToStr, dupCheck, handleDistName } from "helpers/arrayToString";
import { useCallback, useEffect, useState } from "react";
import { CommunityType, IOrgDetails } from "app/variables";
import { defaultOrg } from "app/defaultValues";
import { allDistricts } from "../helpers/districts";
import { Layout } from "../pages/Page";
import BackFooter from "./BackFooter";
import { ModalHeader, OrgInput } from "./InputComponents";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { allRegions } from "helpers/regions";
import { useParams } from "react-router";
import { fromDB } from "helpers/date";

function OrgCreateEdit() {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const [tmpOrg, setTmpOrg] = useState<IOrgDetails | null>(null);
  const [orgDetails, setOrgDetails] = useState<IOrgDetails | null>(null);

  const authUser = useAppSelector(selectAuthUser);
  const [isLoading, setIsLoading] = useState(false);
  const [districtModal, setDistrictModal] = useState(false);
  const [regionModal, setRegionModal] = useState(false);
  const [dSearch, setDSearch] = useState("");
  // const [newOrg, setNewOrg] = useState<IOrgDetails>(defaultOrg);
  const [message, setMessage] = useState("");

  const fetchOrg = useCallback(async () => {
    const db = getFirestore();
    try {
      if (id === "new" || !id) {
        setOrgDetails(defaultOrg);
      } else {
        const docRef = doc(db, "organizations", id);
        const res = await getDoc(docRef);
        const org = fromDB(res.data()) as IOrgDetails;
        setTmpOrg({ ...defaultOrg, ...org, id });
        setOrgDetails({ ...defaultOrg, ...org, id });
      }
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useEffect(() => {
    fetchOrg();
  }, [fetchOrg]);

  const handleSwitch = useCallback(
    (communityType: CommunityType) => {
      if (!orgDetails) return;
      setOrgDetails({ ...orgDetails, communityType, region: [], districts: [] });
    },
    [orgDetails]
  );

  const getRegionName = useCallback(async (districtName: string) => {
    let region = "";
    try {
      const getRegionNameApi = `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-kingdom-local-authority-district&q=${districtName}&sort=-rgn_name&facet=ctry_name&facet=rgn_name&facet=ctyua_name&facet=lad_name&facet=lad_type`;
      const res = await axios.get(getRegionNameApi);
      region = res.data.records[0].fields.rgn_name;
    } catch (error) {
      console.error(error);
    } finally {
      return region;
    }
  }, []);

  const createNewOrg = useCallback(async () => {
    if (!authUser || !orgDetails) return;
    setIsLoading(true);
    const db = getFirestore();
    const { id, ...data } = orgDetails;
    let region = "";
    if (data.communityType === "local") {
      try {
        region = await getRegionName(data.districts[0]);
      } catch (error) {
        console.error(error);
      }
    }

    try {
      const orgData: Omit<IOrgDetails, "id"> =
        data.communityType === "regional" || data.communityType === "nationwide"
          ? { ...data, createdAt: new Date(), updatedAt: new Date() }
          : { ...data, region: [region], createdAt: new Date(), updatedAt: new Date() };
      await addDoc(collection(db, "organizations"), orgData);
      setMessage("New organization created");
      setOrgDetails(defaultOrg);
    } catch (error: any) {
      console.error(error);
      return error.code;
    } finally {
      setIsLoading(false);
    }
  }, [authUser, getRegionName, orgDetails]);

  const editOrg = useCallback(async () => {
    if (!authUser || !orgDetails) return;
    setIsLoading(true);
    const db = getFirestore();
    const { id, ...data } = orgDetails;
    console.log("data", data);
    try {
      await updateDoc(doc(db, `organizations/${id}`), { ...data, updatedAt: new Date() });
      setMessage("Organization updated");
      setTmpOrg({ ...orgDetails });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [authUser, orgDetails]);

  const handleSubmit = useCallback(() => {
    if (!orgDetails) return;
    if (orgDetails.id === "new") {
      createNewOrg();
    } else {
      editOrg();
    }
  }, [createNewOrg, editOrg, orgDetails]);

  return (
    <Layout name={!id ? t("m-NewOrg") : `${t("m-editOrg")} - ${orgDetails?.organizationName}`}>
      {orgDetails && (
        <IonGrid>
          <IonList>
            <IonList slot="content">
              {authUser?.role === "super-admin" && (
                <IonItem>
                  <IonLabel className="font">{"Community Type"}</IonLabel>
                  <IonCol>
                    <IonButton expand="block" color={orgDetails.communityType === "local" ? "primary" : "medium"} onClick={() => handleSwitch("local")}>
                      Local
                    </IonButton>
                    <IonButton expand="block" color={orgDetails.communityType === "regional" ? "primary" : "medium"} onClick={() => handleSwitch("regional")}>
                      Regional
                    </IonButton>
                    <IonButton expand="block" color={orgDetails.communityType === "nationwide" ? "primary" : "medium"} onClick={() => handleSwitch("nationwide")}>
                      Nationwide
                    </IonButton>
                  </IonCol>
                </IonItem>
              )}

              {orgDetails.communityType === "local" && (
                <IonItem>
                  <IonLabel position="floating" className="font">
                    Districts
                  </IonLabel>
                  <IonInput value={arrToStr(orgDetails.districts.map((i) => handleDistName(i)))} onIonFocus={() => setDistrictModal(true)} />
                </IonItem>
              )}
              {orgDetails.communityType === "regional" && (
                <IonItem>
                  <IonLabel position="floating" className="font">
                    Region
                  </IonLabel>
                  <IonInput value={arrToStr(orgDetails.region)} onIonFocus={() => setRegionModal(true)} />
                </IonItem>
              )}
              <OrgInput org={orgDetails} title={"Organization name"} field={"organizationName"} onChange={setOrgDetails} required value={orgDetails.organizationName} />
              <IonAccordionGroup>
                <IonAccordion value="org-contacts">
                  <IonItem slot="header">
                    <IonText>
                      <h5>
                        <b>Contacts</b>
                      </h5>
                    </IonText>
                  </IonItem>
                  <IonList slot="content" lines="inset">
                    <OrgInput org={orgDetails} title={"General email"} field={"email1"} onChange={setOrgDetails} required value={orgDetails.email1} />
                    <OrgInput org={orgDetails} title="Phone" field={"phone"} onChange={setOrgDetails} value={orgDetails.phone || ""} />
                    <OrgInput
                      org={orgDetails}
                      title="WhatsApp"
                      placeholder="Input WITHOUT '+' sign and starts with area code (e.g. 447789012345)"
                      field={"whatsapp"}
                      onChange={setOrgDetails}
                      value={orgDetails.whatsapp || ""}
                    />
                    <OrgInput org={orgDetails} title="Signal" field={"signal"} onChange={setOrgDetails} value={orgDetails.signal || ""} />
                    <OrgInput org={orgDetails} title="Telegram" field={"telegram"} onChange={setOrgDetails} value={orgDetails.telegram || ""} />
                    <OrgInput org={orgDetails} title="Address" field={"address"} onChange={setOrgDetails} value={orgDetails.address || ""} />
                    <IonCol>
                      <OrgInput org={orgDetails} title={"Email #2"} field={"email2"} onChange={setOrgDetails} value={orgDetails.email2 || ""} />
                      <OrgInput org={orgDetails} title={"Email #2 label"} field={"email2_label"} onChange={setOrgDetails} value={orgDetails.email2_label || ""} />
                    </IonCol>
                    <IonCol>
                      <OrgInput org={orgDetails} title={"Email #3"} field={"email3"} onChange={setOrgDetails} value={orgDetails.email3 || ""} />
                      <OrgInput org={orgDetails} title={"Email #3 label"} field={"email3_label"} onChange={setOrgDetails} value={orgDetails.email3_label || ""} />
                    </IonCol>
                    <IonCol>
                      <OrgInput org={orgDetails} title={"Email #4"} field={"email4"} onChange={setOrgDetails} value={orgDetails.email4 || ""} />
                      <OrgInput org={orgDetails} title={"Email #4 label"} field={"email4_label"} onChange={setOrgDetails} value={orgDetails.email4_label || ""} />
                    </IonCol>
                  </IonList>
                </IonAccordion>
              </IonAccordionGroup>

              {/* <OrgInput org={orgDetails} title={"General email label"} field={"email1_label"} onChange={setOrgDetails} required value={orgDetails.email1_label} /> */}
              <IonAccordionGroup>
                <IonAccordion value="organisationAccordion">
                  <IonItem slot="header">
                    <IonText>
                      <h5>
                        <b>Social Media & Websites (Optional)</b>
                      </h5>
                    </IonText>
                  </IonItem>
                  <IonList slot="content" lines="inset">
                    <OrgInput org={orgDetails} title={"Facebook"} field={"facebook"} onChange={setOrgDetails} value={orgDetails.facebook || ""} />
                    <OrgInput org={orgDetails} title={"Instagram"} field={"instagram"} onChange={setOrgDetails} value={orgDetails.instagram || ""} />
                    <OrgInput org={orgDetails} title={"Twitter"} field={"twitter"} onChange={setOrgDetails} value={orgDetails.twitter || ""} />
                    <OrgInput org={orgDetails} title={"Telegram"} field={"telegram"} onChange={setOrgDetails} value={orgDetails.telegram || ""} />
                    <OrgInput org={orgDetails} title={"Youtube"} field={"youtube"} onChange={setOrgDetails} value={orgDetails.youtube || ""} />
                    <OrgInput org={orgDetails} title={"Eventbrite"} field={"eventbrite"} onChange={setOrgDetails} value={orgDetails.eventbrite || ""} />
                    <OrgInput org={orgDetails} title={"Website"} field={"website"} onChange={setOrgDetails} value={orgDetails.website || ""} />
                    <OrgInput org={orgDetails} title={"Website #2"} field={"website2"} onChange={setOrgDetails} value={orgDetails.website2 || ""} />
                    <OrgInput org={orgDetails} title={"Website #3"} field={"website3"} onChange={setOrgDetails} value={orgDetails.website3 || ""} />
                  </IonList>
                  <IonList slot="content" lines={"inset"}></IonList>
                </IonAccordion>
              </IonAccordionGroup>
              <br />
            </IonList>
          </IonList>
          <IonItem>
            <IonLabel position="floating" className="font">
              Description (Optional)
            </IonLabel>
            <IonTextarea
              rows={3}
              autoGrow
              onIonChange={(e) => {
                console.log(e.target.value);
                if (e.target.value) setOrgDetails({ ...orgDetails, description: e.target.value });
              }}
            ></IonTextarea>
          </IonItem>
        </IonGrid>
      )}
      {orgDetails && (
        <IonRow>
          <IonCol>
            <IonButton expand="block" disabled={!orgDetails.organizationName || !orgDetails.email1 || !orgDetails.email1_label} onClick={handleSubmit}>
              SUBMIT
            </IonButton>
            <IonButton color="medium" expand="block" onClick={() => setOrgDetails(defaultOrg)}>
              CANCEL
            </IonButton>
          </IonCol>
        </IonRow>
      )}

      {/* Select district modal */}
      {orgDetails && (
        <IonModal isOpen={districtModal} onDidDismiss={() => setDistrictModal(false)}>
          <ModalHeader closeButton title={"Select district"} closeFunction={() => setDistrictModal(false)} />
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonInput placeholder={"Filter district"} value={dSearch} className="inputBorder" onIonChange={(e) => setDSearch(e.detail.value!)} />
                </IonCol>
              </IonRow>
              <IonItem>
                <IonCol>
                  <IonText>Selected:</IonText>
                  {orgDetails?.districts.map((i, idx) => (
                    <IonChip
                      key={"selected_" + i}
                      color="success"
                      onClick={() => {
                        let temp = [...orgDetails.districts];
                        temp.splice(idx, 1);
                        setOrgDetails({ ...orgDetails, districts: temp });
                      }}
                    >
                      <IonText>{handleDistName(i)}</IonText>
                    </IonChip>
                  ))}
                </IonCol>
              </IonItem>
              <IonRow>
                <IonCol>
                  {allDistricts
                    .filter((x) => !orgDetails.districts.includes(x))
                    .filter((i) => i.toLowerCase().includes(dSearch.toLowerCase()))
                    .map((district) => (
                      <IonChip
                        key={district}
                        color="tertiary"
                        onClick={() => {
                          setOrgDetails({ ...orgDetails, districts: dupCheck(district, [...orgDetails.districts]) });
                          setDSearch("");
                        }}
                      >
                        <IonText>{handleDistName(district)}</IonText>
                      </IonChip>
                    ))}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <BackFooter onClick={() => setDistrictModal(false)} />
        </IonModal>
      )}
      {/* Select region modal */}
      {orgDetails && (
        <IonModal isOpen={regionModal} onDidDismiss={() => setRegionModal(false)}>
          <ModalHeader closeButton title={"Select region"} closeFunction={() => setRegionModal(false)} />
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonInput placeholder={"Filter region"} value={dSearch} className="inputBorder" onIonChange={(e) => setDSearch(e.detail.value!)} />
                </IonCol>
              </IonRow>
              <IonItem>
                <IonCol>
                  <IonText>Selected:</IonText>
                  {orgDetails?.region.map((i, idx) => (
                    <IonChip
                      key={"selected_region" + i}
                      color="success"
                      onClick={() => {
                        let temp = [...orgDetails.region];
                        temp.splice(idx, 1);
                        setOrgDetails({ ...orgDetails, region: temp });
                      }}
                    >
                      <IonText>{i}</IonText>
                    </IonChip>
                  ))}
                </IonCol>
              </IonItem>
              <IonRow>
                <IonCol>
                  {allRegions
                    .filter((x) => !orgDetails.region.includes(x))
                    .filter((i) => i.toLowerCase().includes(dSearch.toLowerCase()))
                    .map((region) => (
                      <IonChip
                        key={region}
                        color="tertiary"
                        onClick={() => {
                          setOrgDetails({ ...orgDetails, region: dupCheck(region, [...orgDetails.region]) });
                          setDSearch("");
                        }}
                      >
                        <IonText>{region}</IonText>
                      </IonChip>
                    ))}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <BackFooter onClick={() => setRegionModal(false)} />
        </IonModal>
      )}
      <IonAlert
        message={message}
        isOpen={!!message}
        buttons={[
          {
            text: "OK",
            handler: () => setMessage(""),
          },
        ]}
      />
      <IonLoading isOpen={isLoading} message={"Saving"} />
    </Layout>
  );
}

export default OrgCreateEdit;
