import { IonBackButton, IonButtons, IonContent, IonFooter, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { ReactNode } from "react";
import { useParams } from "react-router";
import ControlContent from "../components/ControlContent";
import MapContent from "../components/MapContent";
import "./Page.css";

const Page = () => {
  const { name } = useParams<{ name: string }>();
  const nameAndPages = [
    { name: "Map", display: "Trafford Event Map", page: <MapContent /> },
    { name: "Control", display: "Control", page: <ControlContent /> },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{nameAndPages.find((x) => x.name === name)!.display}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle style={{ fontSize: "1vw!important" }}>{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {nameAndPages.find((x) => x.name === name)!.page}
      </IonContent>
    </IonPage>
  );
};

export default Page;

interface LayoutProps {
  children: ReactNode;
  name: string;
  back?: boolean;
  extraButton?: ReactNode;
  footer?: ReactNode;
}

export const Layout = (props: LayoutProps) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">{!props.back ? <IonMenuButton /> : <IonBackButton />}</IonButtons>
          <IonTitle>{props.name}</IonTitle>
          {/* <h6>
              <b>{props.name}</b>
            </h6> */}
          {props.extraButton && props.extraButton}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>{props.children}</IonContent>
      {props.footer && <IonFooter>{props.footer}</IonFooter>}
    </IonPage>
  );
};
