import { IonAlert, IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonLoading, IonRow, IonTextarea } from "@ionic/react";
import axios from "axios";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { useCallback, useState } from "react";
import { IContactAdmin } from "../app/variables";
import { defaultContactAdmin } from "app/defaultValues";
import "../css/ContactDev.css";
import { Layout } from "../pages/Page";
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "app/slices/authSlice";
import { useTranslation } from "react-i18next";

function StaffEnquiry() {
  const authUser = useAppSelector(selectAuthUser);
  const [contactMsg, setContactMsg] = useState<IContactAdmin>(defaultContactAdmin);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const handleSubmit = useCallback(async () => {
    if (!authUser?.id) {
      setMessage("Unrecognized access, submit failed");
      return;
    }
    setIsLoading(true);
    const db = getFirestore();
    const { id, ...data } = contactMsg;
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      const userIp = res.data.ip;
      await addDoc(collection(db, "staffEnquiries"), {
        ...data,
        admin: authUser,
        userIp,
        createdAt: new Date(),
      });
      setContactMsg(defaultContactAdmin);
      setMessage("Message sent!");
    } catch (error: any) {
      setMessage("Oops! Something's wrong, please try again");
      console.error(error);
      return error.code;
    } finally {
      setIsLoading(false);
    }
  }, [authUser, contactMsg]);

  return (
    <Layout name={t("m-StaffEnq")}>
      <IonGrid>
        <IonCard>
          <IonCardContent>
            <IonList>
              {/* <IonButton
                onClick={() =>
                  setContactMsg({
                    ...contactMsg,
                    admin: {
                      id: "1",
                      email: "1@gmail.com",
                      district: ["Trafford"],
                      organisation: ["Trafford Council"],
                      role: "super-admin",
                      disabled: false,
                    },
                  })
                }
              >
                TEST
              </IonButton> */}
              <IonItem className="ion-no-padding">
                <IonLabel position="floating" className="font">
                  {"Subject"}
                </IonLabel>
                <IonInput value={contactMsg?.subject} onIonChange={(e) => setContactMsg({ ...contactMsg, subject: e.detail.value! })} />
              </IonItem>
              <IonItem className="ion-no-padding">
                <IonLabel position="floating" className="font">
                  Message
                </IonLabel>
                <IonTextarea autoGrow value={contactMsg?.message} rows={6} onIonChange={(e) => setContactMsg({ ...contactMsg, message: e.detail.value! })} />
              </IonItem>
              <br />
              <IonRow>
                <IonCol className="ion-no-padding">
                  <IonButton expand="block" onClick={handleSubmit}>
                    Submit
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonGrid>
      <IonLoading isOpen={isLoading} message={"Loading"} />
      <IonAlert message={message} isOpen={!!message} buttons={[{ text: "Ok", handler: () => setMessage("") }]} />
    </Layout>
  );
}

export default StaffEnquiry;
