import React, { useRef } from "react";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonText,
} from "@ionic/react";
import { GoogleMap, Marker, Polygon, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { handleDistName } from "helpers/arrayToString";
import { useCallback, useEffect, useState } from "react";
import Geocode from "react-geocode";
import { GUnit, IContactDev, IOrgDetails } from "../app/variables";
import { fromDB } from "../helpers/date";
// import { Layout } from "./Page";
import greenDot from "media/greenDot.svg";
import { useTranslation } from "react-i18next";
// import BackFooter from "components/BackFoorter";
// import { ModalHeader } from "components/ModalHeader";
import { OrgCard } from "components/OrgCardContent";
import { adrObjToStr, getLongName } from "helpers/address";
import i18n from "i18n";
import { useSelector } from "react-redux";
import { selectAllNWOrgs } from "app/slices/orgSlices";
import { fetchAllNWOrgs, reportError } from "app/firebase";
import { defaultContactDev } from "app/defaultValues";
import { Layout } from "pages/Page";
import BackFooter from "./BackFooter";
import { ModalHeader } from "./InputComponents";
import { mapOutline } from "ionicons/icons";

const MAP_HEIGHT = window.innerHeight * 0.5;
const polygonStyle: google.maps.PolygonOptions = {
  fillColor: "lightblue",
  fillOpacity: 0.3,
  strokeColor: "blue",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
};

const notifyMessage = "We apologize for the error, you could notify us with just a few clicks, that would help us making this site better!";

function OrgMapPage() {
  const allNWOrgs = useSelector(selectAllNWOrgs).allNWOrgs;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    mapIds: [process.env.REACT_APP_MAP_ID!],
    googleMapsApiKey: String(process.env.REACT_APP_GEO_KEY),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [notifyMsg, setNotifyMsg] = useState("");
  const [polygonPath, setPolygonPath] = useState<{ lat: number; lng: number }[][]>([]);
  const [orgDetails, setOrgDetails] = useState<IOrgDetails[]>([]);
  const [regionalOrgs, setRegionalOrgs] = useState<IOrgDetails[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [currentDist, setCurrentDist] = useState("");
  const [currentRegion, setCurrentRegion] = useState("");
  const [zoomValue, setZoomValue] = useState(5.5);
  const [viewCenter, setViewCenter] = useState({
    lat: 54.4316679,
    lng: -4.3016133,
  });
  const [showNWOrgs, setShowNWOrgs] = useState(false);
  const [searchedPoint, setSearchedPoint] = useState<{ lat: number; lng: number } | null>(null);
  const [multiAddress, setMultiAddress] = useState<GUnit[]>([]);
  const [viewType, setViewType] = useState<"district" | "region">("district");
  const [notifyForm, setNotifyForm] = useState<IContactDev | null>(null);
  const [useMapView, setUseMapView] = useState(true);
  const { t } = useTranslation();
  const gMapRef = useRef<GoogleMap>(null);

  const handleReset = useCallback(() => {
    setCurrentDist("");
    setCurrentRegion("");
    setOrgDetails([]);
    setPolygonPath([]);
    setSearchedPoint(null);
  }, []);

  const getPolyCenterDistrict = useCallback(
    async (coLng: number, coLat: number) => {
      let districtName = "";
      try {
        // deprecated 13/08/2023
        // const api = `https://services1.arcgis.com/ESMARspQHYMw9BZ9/arcgis/rest/services/LAD_DEC_2022_UK_BFE_V2/FeatureServer/0/query?where=1%3D1&outFields=*&geometry=${coLng}%2C${coLat}%2C${coLng}%2C${coLat}&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=json`;
        const api = `https://services1.arcgis.com/ESMARspQHYMw9BZ9/arcgis/rest/services/LAD_DEC_2020_UK_BGC/FeatureServer/0/query?where=1%3D1&outFields=*&geometry=${coLng}%2C${coLat}%2C${coLng}%2C${coLat}&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=json`;
        const res = await axios.get(api);
        if (!res.data) setMessage("Place not found");
        const features = res.data.features;
        const center = { lat: features[0].attributes.LAT, lng: features[0].attributes.LONG };
        const ring = features[0].geometry.rings.map((ring: any) => ring.map((ll: number[]) => ({ lat: ll[1], lng: ll[0] })));
        setPolygonPath(ring);
        setViewCenter(center);
        setZoomValue(10.5);
        // deprecated 13/08/2023
        // const district = features[0].attributes.LAD22NM;
        districtName = features[0].attributes.LAD20NM;
        setCurrentDist(handleDistName(districtName));
        return districtName;
      } catch (error: any) {
        console.error(error);
        setNotifyForm({ ...defaultContactDev, subject: `Poly-District error: ${error.code}`, message: `Error when searching ${searchInput}` });
        setNotifyMsg(notifyMessage);
        return "";
      }
    },
    [searchInput]
  );

  const getRegionName = useCallback(
    async (districtName: string) => {
      let regionName = "";
      try {
        const getRegionNameApi = `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-kingdom-local-authority-district&q=${districtName}&sort=-rgn_name&facet=ctry_name&facet=rgn_name&facet=ctyua_name&facet=lad_name&facet=lad_type`;
        const regionRes = await axios.get(getRegionNameApi);
        regionName = regionRes.data.records[0].fields.rgn_name;
        setCurrentRegion(regionName);
        // draw Region
        //  if (regionRes) {
        //    const name = regionRes.data.records[0].fields.rgn_name;
        //    const api = `https://services1.arcgis.com/ESMARspQHYMw9BZ9/arcgis/rest/services/Regions_December_2021_EN_BFC_2022/FeatureServer/0/query?where=RGN21NM%20%3D%20'${name}'&outFields=*&geometry=-45.240%2C47.067%2C30.170%2C64.109&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=json`;
        //    const res3 = await axios.get(api);
        //    const features = res3.data.features;
        //    console.log(res3.data);
        //    const ring = features[0].geometry.rings.map((ring: any) => ring.map((ll: number[]) => ({ lat: ll[1], lng: ll[0] })));
        //    // draw region polygon
        //    setPolygonPath(ring);
        //  }
        return regionName;
      } catch (error: any) {
        console.error(error);
        setNotifyForm({ ...defaultContactDev, subject: `Region error: ${error.code}`, message: `Error when searching ${searchInput}` });
        setNotifyMsg(notifyMessage);
        return "";
      }
    },
    [searchInput]
  );

  const getBoundaries = useCallback(
    async (coLng: number, coLat: number) => {
      setIsLoading(true);
      const districtName = await getPolyCenterDistrict(coLng, coLat);
      let regionName = "";
      if (districtName) {
        regionName = await getRegionName(districtName);
      }
      const db = getFirestore();
      // const q = query(collection(db, "organizations"), where("districts", "array-contains", district));
      const q = query(collection(db, "organizations"), where("region", "array-contains", regionName));
      try {
        const sss = await getDocs(q);
        const temp: IOrgDetails[] = [];
        sss.forEach((i) => {
          const data = fromDB(i.data());
          temp.push({ ...data, id: i.id } as IOrgDetails);
        });
        const regionals = temp.filter((i) => i.communityType === "regional");
        const locals = temp.filter((i) => i.communityType === "local" && i.districts.includes(districtName));
        setOrgDetails(locals);
        setRegionalOrgs(regionals);
        if (!locals.length && regionals.length) {
          setViewType("region");
        } else {
          setViewType("district");
        }
        setZoomValue(10.4);
      } catch (error: any) {
        console.error(error);
        setNotifyForm({ ...defaultContactDev, subject: `Database error: ${error.code}`, message: `Error when searching ${searchInput}` });
        setNotifyMsg(notifyMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [getPolyCenterDistrict, getRegionName, searchInput]
  );

  const fetchPostcode = useCallback(
    async (manualInput?: string) => {
      handleReset();
      if (!searchInput) return;
      setIsLoading(true);
      try {
        const res = await Geocode.fromAddress(manualInput || searchInput);
        if (res.results.length === 1) {
          if (!res.results[0].formatted_address.endsWith(", UK")) {
            setMessage(`${t("unknownAdrMsg")}`);
            return;
          }
          const geo = res.results[0].geometry.location;
          setSearchedPoint({ lat: geo.lat, lng: geo.lng });
          getBoundaries(geo.lng, geo.lat);
        } else {
          const onlyUK = res.results.filter((i: any) => i.formatted_address.endsWith(", UK"));
          if (onlyUK.length > 1) {
            const tempArr = [];
            for (const i of onlyUK) {
              tempArr.push(getLongName(i.address_components));
            }
            setMultiAddress(tempArr);
          } else if (onlyUK.length === 1) {
            fetchPostcode(onlyUK[0].formatted_address);
            setSearchInput(onlyUK[0].formatted_address);
          } else {
            setMessage(`${t("unknownAdrMsg")}`);
          }
        }
      } catch (error) {
        setMessage(`${t("specificAdrMsg")}`);
      } finally {
        setIsLoading(false);
      }
    },
    [getBoundaries, handleReset, searchInput, t]
  );

  useEffect(() => {
    if (!allNWOrgs.length) {
      fetchAllNWOrgs();
    }
  }, [allNWOrgs]);

  const handleNotifyUs = useCallback(async () => {
    if (!notifyForm) return;
    setIsLoading(true);
    try {
      const res = await reportError(notifyForm);
      if (res === "success") {
        setNotifyForm(null);
        setNotifyMsg("");
        setMessage("Thank you for notifying us! We will fix the error ASAP!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [notifyForm]);

  return (
    <Layout
      name={t("m-Org")}
      extraButton={
        <IonButtons slot="end" className="mr-1">
          <IonButton color={useMapView ? "danger" : "primary"} onClick={() => setUseMapView(!useMapView)}>
            <IonIcon icon={mapOutline} className="mr-1" />
            {useMapView ? t("hideMap") : t("showMap")}
          </IonButton>
        </IonButtons>
      }
      footer={
        <IonRow>
          <IonCol>
            <IonButton expand="block" onClick={() => setShowNWOrgs(true)}>
              {t("showNationwideOrg")}
            </IonButton>
          </IonCol>
        </IonRow>
      }
    >
      <IonContent>
        <IonRow>
          <IonCol className="flex-between">
            <IonSearchbar
              onIonClear={() => setSearchInput("")}
              placeholder={`${t("partOfAdd")}`}
              value={searchInput}
              onIonChange={(e) => setSearchInput(e.detail.value!)}
              onKeyDown={(e) => {
                // @ts-ignore
                console.log(e);
                // @ts-ignore
                if (e.key === "Enter" && !!e.target.value) {
                  fetchPostcode();
                }
              }}
              showClearButton="always"
            />
            <IonButton onClick={() => fetchPostcode()}>{t("search")}</IonButton>
          </IonCol>
        </IonRow>
        <div style={{ height: useMapView ? MAP_HEIGHT : 0, width: "100%", transition: "all 0.4s" }}>
          {isLoaded ? (
            <GoogleMap
              ref={gMapRef}
              //   mapTypeId={process.env.NEXT_PUBLIC_MAP_ID}
              mapContainerStyle={{ height: useMapView ? MAP_HEIGHT : 0, width: "100%", transition: "all 0.4s" }}
              center={viewCenter}
              zoom={zoomValue}
              options={{
                streetViewControl: false,
                mapId: process.env.REACT_APP_MAP_ID,
              }}
            >
              <Polygon paths={polygonPath} options={polygonStyle} onLoad={(e) => e.setPath(polygonPath.flat())} />
              {searchedPoint && <Marker animation={1} options={{ icon: greenDot, opacity: 0.8 }} position={searchedPoint} />}
            </GoogleMap>
          ) : null}
        </div>

        <IonRow>
          <IonCol>
            <IonSegment className="flex-row-around" value={viewType} onIonChange={(e) => setViewType(e.target.value as "district" | "region")}>
              <IonSegmentButton style={{ width: "100%" }} value="district">
                District
              </IonSegmentButton>
              <IonSegmentButton style={{ width: "100%" }} value="region">
                Regional
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
        <IonGrid>
          <IonRow>
            {viewType === "district" && (
              <IonCol className="ml-1">
                {!orgDetails.length && !!polygonPath.length && !!currentDist && (
                  <IonText>{i18n.language === "zh" ? <h5>抱歉，未有 {`${currentDist}`} 的機構資料</h5> : <h5>Apologies! No organisations entry for {`${currentDist}`} yet</h5>}</IonText>
                )}
                {!orgDetails.length && !polygonPath.length && (
                  <IonText>
                    <h5>{`${t("orgSearchMsg")}`}</h5>
                  </IonText>
                )}
                {!!orgDetails.length && !!polygonPath.length && (
                  <IonText>
                    <h5>{`Organisation(s) of ${currentDist}:`}</h5>
                  </IonText>
                )}
              </IonCol>
            )}
            {viewType === "region" && (
              <IonCol className="ml-1">
                {!regionalOrgs.length && !!currentRegion && (
                  <IonText>{i18n.language === "zh" ? <h5>抱歉，未有 {`${currentRegion}`} 的機構資料</h5> : <h5>Apologies! No regional organisations entry for {`${currentRegion}`} yet</h5>}</IonText>
                )}
                {!regionalOrgs.length && !polygonPath.length && (
                  <IonText>
                    <h5>{`${t("orgSearchMsg")}`}</h5>
                  </IonText>
                )}
                {!!regionalOrgs.length && (
                  <IonText>
                    <h5>{`Organisation(s) of ${currentRegion}:`}</h5>
                  </IonText>
                )}
              </IonCol>
            )}
          </IonRow>
          {viewType === "district" ? orgDetails.map((i) => <OrgCard key={i.id} org={i} />) : regionalOrgs.map((i) => <OrgCard key={i.id} org={i} />)}
        </IonGrid>
      </IonContent>
      <IonModal isOpen={showNWOrgs} onDidDismiss={() => setShowNWOrgs(false)}>
        <ModalHeader title={`${t("nationwideOrg")}`} closeButton closeFunction={() => setShowNWOrgs(false)} />
        <IonContent>
          <IonGrid>
            <IonRow>
              {allNWOrgs.map((i) => (
                <IonCol key={i.id} size="12">
                  <OrgCard key={i.id} org={i} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </IonContent>
        <BackFooter onClick={() => setShowNWOrgs(false)} />
      </IonModal>
      {/* When there are more than 1 postcode search results */}
      <IonModal isOpen={!!multiAddress.length} onDidDismiss={() => setMultiAddress([])}>
        <ModalHeader title="Please select address" />
        <IonContent>
          <IonList>
            {multiAddress.map((adr, idx) => (
              <IonItem
                key={idx}
                button
                onClick={() => {
                  const tmp = adrObjToStr(adr);
                  setSearchInput(adr.formattedAddress ? adr.formattedAddress : tmp);
                  fetchPostcode(tmp);
                  setMultiAddress([]);
                }}
              >
                <IonLabel>
                  {adr.formattedAddress && (
                    <p>
                      <b>{adr.formattedAddress}</b>
                    </p>
                  )}
                  <p>{adr.streetNo + " " + adr.route}</p>
                  <p>{adr.postalTown}</p>
                  <p>{adr.adminArea2}</p>
                  <p>{adr.adminArea1}</p>
                  <p>{adr.postcode}</p>
                  <p>{adr.country}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
        <BackFooter onClick={() => setMultiAddress([])} />
      </IonModal>
      <IonLoading isOpen={isLoading} message={"Loading"} />
      <IonAlert message={message} isOpen={!!message} onDidDismiss={() => setMessage("")} buttons={[{ text: "OK", handler: () => setMessage("") }]} />
      <IonAlert
        message={notifyMsg}
        isOpen={!!notifyMsg}
        onDidDismiss={() => setNotifyMsg("")}
        buttons={[
          { text: "No, thanks", role: "cancel", handler: () => setNotifyMsg("") },
          { text: "Notify", handler: handleNotifyUs },
        ]}
      />
    </Layout>
  );
}

export default OrgMapPage;

interface InfoFieldsProps {
  name: string;
  value: string;
  tag?: string;
}

export const InfoFields = (props: InfoFieldsProps) => {
  return (
    <IonItem lines="none">
      <IonLabel>
        <h4>
          <b>{`${props.tag || props.name}:`}</b>
          <br />
          {props.name.includes("Email") ? (
            <a className="ion-text-wrap pointer " href={`mailto:${props.value}`}>
              {props.value}
            </a>
          ) : props.name === "District(s)" || props.name === "Organisation name" ? (
            <p style={{ whiteSpace: "pre-wrap" }}>{props.value}</p>
          ) : (
            <a color="tertiary" href={props.value}>
              {props.value}
            </a>
          )}
        </h4>
      </IonLabel>
      <br />
      {/* {!!props.tag && (
        <IonChip color={"primary"} style={{ pointerEvent: "none!important", cursor: "default" }}>
          {props.tag}
        </IonChip>
      )} */}
    </IonItem>
  );
};
