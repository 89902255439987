import { IonCard, IonCardContent, IonContent, IonLabel, IonPage } from "@ionic/react";
// import Header from "./Header"

const NoPermissionPage: React.FC = () => {
  return (
    <IonPage>
      {/* <Header title="Unauthorised Page"/> */}
      <IonContent>
        <IonCard>
          <IonCardContent className="ion-text-center">
            <IonLabel>
              <h2>Unauthorised Page</h2>
            </IonLabel>
            <IonLabel>
              <p>Your role is forbidden to access this page</p>
            </IonLabel>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default NoPermissionPage;
