import { IonButton, IonButtons, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonText, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { GUnit, IEventDetailsInput, IIntEvent, IOrgDetails } from "../app/variables";

export const ModalHeader = ({ title, closeButton, closeFunction }: { title: string; closeButton?: boolean; closeFunction?: () => void }) => {
  return (
    <IonHeader>
      <IonToolbar>
        <h5 className="ion-padding-horizontal">
          <b>{title}</b>
        </h5>
        {closeButton && (
          <IonButtons slot="end">
            <IonButton slot="icon-only" onClick={closeFunction}>
              <IonIcon icon={closeOutline} size="large" />
            </IonButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

interface AddressInputProps {
  evt: IEventDetailsInput;
  title: string;
  field: keyof GUnit;
  onChange: (args0: IEventDetailsInput) => void;
  postcodeFunc?: () => void;
}

export const AddressInput = (props: AddressInputProps) => {
  const { evt, title, field, onChange } = props;
  return (
    <IonItem>
      <IonLabel position="floating" className="font">
        {title}
      </IonLabel>
      <IonInput
        value={evt.eventAddress[field]}
        onIonChange={(e) =>
          onChange({
            ...evt,
            eventAddress: {
              ...evt.eventAddress,
              [field]: e.detail.value!,
            },
          })
        }
      />
    </IonItem>
  );
};

interface CustomInputProps {
  evt: IEventDetailsInput;
  title: string;
  value: string;
  field: keyof IEventDetailsInput;
  onChange: (args0: IEventDetailsInput) => void;
}

export const StringInput = (props: CustomInputProps) => {
  const { evt, value, title, field, onChange } = props;
  return (
    <IonItem>
      <IonLabel position="floating" className="font">
        {title}
      </IonLabel>
      <IonInput value={value} onIonChange={(e) => onChange({ ...evt, [field]: e.detail.value! })} />
    </IonItem>
  );
};

interface CustomIntInputProps {
  evt: IIntEvent;
  title: string;
  value: string;
  field: keyof IIntEvent;
  onChange: (args0: IIntEvent) => void;
}

export const IntStringInput = (props: CustomIntInputProps) => {
  const { evt, value, title, field, onChange } = props;
  return (
    <IonItem>
      <IonLabel position="floating" className="font">
        {title}
      </IonLabel>
      <IonInput value={value} onIonChange={(e) => onChange({ ...evt, [field]: e.detail.value! })} />
    </IonItem>
  );
};

interface OrgInputProps {
  org: IOrgDetails;
  title: string;
  value: string;
  field: keyof IOrgDetails;
  onChange: (args0: IOrgDetails) => void;
  required?: boolean;
  placeholder?: string;
}

export const OrgInput = (props: OrgInputProps) => {
  const { org, value, title, field, onChange, required } = props;
  return (
    <IonItem>
      <IonLabel position="floating" className="font">
        {required ? title : title + " (optional)"}

        {required && (
          <IonText color="danger" style={{ opacity: !value ? 1 : 0, transition: "all 0.4s" }}>
            <b>*</b>
          </IonText>
        )}
      </IonLabel>
      <IonInput value={value} placeholder={props.placeholder ? props.placeholder : undefined} onIonChange={(e) => onChange({ ...org, [field]: e.detail.value! })} />
    </IonItem>
  );
};
