import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSpinner, IonText, useIonRouter } from "@ionic/react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("idle");
  const { push } = useIonRouter();

  const handleSubmit = async () => {
    setStatus("loading");
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
    setStatus("sent");
  };

  return (
    <IonPage>
      <IonHeader></IonHeader>
      <IonContent>
        <IonGrid style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
          <IonRow>
            <IonCol sizeXs="0" sizeSm="0" sizeMd="1" sizeLg="3" sizeXl="3"></IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="6" sizeXl="6">
              <IonText className="ion-text-center">
                <h1>HKers in UK</h1>
                <h3>Forgot Password?</h3>
                <br />
                {status === "idle" && <p>Please provide your login email</p>}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXs="0" sizeSm="0" sizeMd="1" sizeLg="3" sizeXl="3"></IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="6" sizeXl="6">
              {status === "idle" ? (
                <>
                  <IonList inset color="light" className="ion-padding">
                    <IonItem color={"light"}>
                      <IonLabel position="stacked">Email Address</IonLabel>
                      <IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)} />
                    </IonItem>
                  </IonList>
                  <IonButton className="ion-margin" expand="block" disabled={!email} onClick={handleSubmit}>
                    Submit
                  </IonButton>
                  <IonButton
                    className="ion-margin" expand="block" fill="clear" color={`medium`}
                    onClick={()=> {
                      push('/')
                    }}
                  >Back to Login</IonButton>
                </>
              ) : status === "loading" ? (
                <>
                  <IonSpinner name="dots" className="ion-margin" />
                </>
              ) : status === "sent" ? (
                <>
                  <IonText className="ion-text-center">
                    <p>
                      Reset Password email has been sent to <b>{email}</b>
                    </p>
                  </IonText>
                  <IonButton
                    className="ion-margin"
                    expand="block"
                    onClick={() => {
                      setStatus("idle");
                      setEmail("");
                      setTimeout(() => {
                        push('/')
                      }, 500);
                    }}
                  >
                    Finish
                  </IonButton>
                </>
              ) : null}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
