import { IonAlert, IonButton, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonItem, IonLabel, IonList, IonLoading, IonModal, IonRow, IonText } from "@ionic/react";
import dayjs from "dayjs";
import { collection, deleteDoc, doc, getDocs, getFirestore, orderBy, query } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { IContactDev } from "../app/variables";
import { fromDB } from "../helpers/date";
import { Layout } from "../pages/Page";
import { ModalHeader } from "./InputComponents";

function DevMessages() {
  const [contactMsg, setContactMsg] = useState<IContactDev[]>([]);
  const [viewMsg, setViewMsg] = useState<IContactDev | null>(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    const db = getFirestore();
    const q = query(collection(db, `contactDev`), orderBy("createdAt", "desc"));
    try {
      const sss = await getDocs(q);
      let temp: IContactDev[] = [];
      sss.forEach((ss) => {
        temp.push({ id: ss.id, ...fromDB(ss.data()) } as IContactDev);
      });
      setContactMsg(temp);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  const handleDelete = useCallback(async () => {
    if (!viewMsg) return;
    setIsLoading(true);
    const db = getFirestore();
    try {
      await deleteDoc(doc(db, "contactDev", viewMsg.id));
      const idx = contactMsg.indexOf(viewMsg);
      let temp: IContactDev[] = [...contactMsg];
      temp.splice(idx, 1);
      setContactMsg(temp);
      setViewMsg(null);
      setMessage("Deleted");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [contactMsg, viewMsg]);

  return (
    <Layout name={"Dev Team Messages"}>
      <IonGrid>
        <IonList>
          {!contactMsg.length ? (
            <IonRow>
              <IonCol className="ion-text-center">
                <IonText color="medium">NOTHING is a good thing!</IonText>
              </IonCol>
            </IonRow>
          ) : (
            contactMsg.map((i) => (
              <IonItem key={i.id} button onClick={() => setViewMsg(i)}>
                <IonLabel>
                  <h3 className="ion-text-wrap">
                    {i.subject} {i.needReply && <IonChip color="primary">Need reply</IonChip>}
                  </h3>
                  <p>{dayjs(i.createdAt).format("YYYY-MM-DD HH:mm")}</p>
                </IonLabel>
              </IonItem>
            ))
          )}
        </IonList>
      </IonGrid>
      <IonModal isOpen={!!viewMsg ? true : false} onDidDismiss={() => setViewMsg(null)}>
        <ModalHeader title={viewMsg?.subject || ""} closeButton closeFunction={() => setViewMsg(null)} />
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow>
              <MsgModalRow name={"User IP"} value={viewMsg?.userIp || ""} />
              <MsgModalRow name={"User email"} value={viewMsg?.fromEmail || ""} />
              <MsgModalRow name={"Subject"} value={viewMsg?.subject || ""} />
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonText>
                  <b>Message : </b>
                </IonText>
              </IonCol>
              <IonCol>
                <IonText style={{ whiteSpace: "pre-wrap" }}>{viewMsg?.message || ""}</IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonRow>
            <IonCol>
              <IonButton color="danger" onClick={handleDelete} expand="block">
                Delete
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonModal>
      <IonLoading isOpen={isLoading} message={"Loading"} />
      <IonAlert message={message} isOpen={!!message} buttons={[{ text: "OK", handler: () => setMessage("") }]} />
    </Layout>
  );
}

export default DevMessages;

export interface MsgModalRowProps {
  name: string;
  value: string;
}

export const MsgModalRow = (props: MsgModalRowProps) => {
  return (
    <IonCol size="12">
      <IonText>
        <b>{`${props.name} :`} </b>
        {props.value}
      </IonText>
    </IonCol>
  );
};
