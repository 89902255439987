import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../App.css";
import { delEvt, getAllEvts } from "../app/firebase";
import { IEventDetails } from "../app/variables";
import { Layout } from "../pages/Page";
import { EventCard } from "./MapContent";
import { useTranslation } from "react-i18next";

function ControlContent() {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [incidentPoints, setIncidentPoints] = useState<IEventDetails[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<IEventDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [delTarget, setDelTarget] = useState<IEventDetails | null>(null);
  const [filterType, setFilterType] = useState<"name" | "date">("name");
  const [filterDate, setFilterDate] = useState({ start: "", end: "" });
  const [filterStr, setFilterStr] = useState("");
  const history = useHistory();

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getAllEvts();
      if (res && res.msg === "success" && !!res.data.length) {
        setIncidentPoints(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  const handleDelete = async (event: IEventDetails) => {
    setIsLoading(true);
    try {
      await delEvt(event);
      const idx = incidentPoints.findIndex((x) => x.eventId === event.eventId);
      const temp = [...incidentPoints];
      temp.splice(idx, 1);
      setIncidentPoints(temp);
      setDelTarget(null);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filterType === "name") {
      setFilteredEvents(
        incidentPoints.filter((i) => i.organisation?.organizationName.toLocaleLowerCase().includes(filterStr.toLowerCase()) || i.eventName.toLocaleLowerCase().includes(filterStr.toLowerCase()))
      );
    } else {
      let temp: IEventDetails[] = [];
      if (!filterDate.start && !filterDate.end) {
        temp = incidentPoints;
      }
      if (!!filterDate.start && !filterDate.end) {
        temp.push(...incidentPoints.filter((evt) => evt.eventDates[0].start >= dayjs(filterDate.start).startOf("D").toDate()));
      }
      if (!!filterDate.end && !filterDate.start) {
        temp.push(...incidentPoints.filter((evt) => evt.eventDates[evt.eventDates.length - 1].end <= dayjs(filterDate.end).endOf("D").toDate()));
      }
      if (!!filterDate.start && filterDate.end) {
        temp.push(
          ...incidentPoints.filter(
            (evt) => evt.eventDates[0].start >= dayjs(filterDate.start).startOf("D").toDate() && evt.eventDates[evt.eventDates.length - 1].end <= dayjs(filterDate.end).endOf("D").toDate()
          )
        );
      }
      setFilteredEvents(temp);
    }
  }, [filterDate.end, filterDate.start, filterStr, filterType, incidentPoints]);

  return (
    <Layout name={t("m-Control")}>
      <>
        <IonGrid>
          <IonCard>
            <IonRow>
              <IonCol>
                <IonSegment
                  color={`primary`}
                  value={filterType}
                  onIonChange={(e) => {
                    setFilterType(e.detail.value as "name" | "date");
                    setFilterDate({ start: "", end: "" });
                    setFilterStr("");
                  }}
                >
                  <IonSegmentButton value="name">
                    <IonLabel>Filter by name</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="date">
                    <IonLabel>Filter by date</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonCol>
            </IonRow>
          </IonCard>
          <IonRow>
            {filterType === "name" ? (
              <IonCol size="12">
                <IonInput className="inputBorder" placeholder={"Event / organisation name"} value={filterStr} onIonChange={(e) => setFilterStr(e.detail.value!)} />
              </IonCol>
            ) : (
              <>
                <IonCol>
                  <IonItem lines="none">
                    <IonLabel className="font flex-center">Filter from</IonLabel>
                    <IonInput type="date" value={filterDate.start} onIonChange={(e) => setFilterDate({ ...filterDate, start: e.detail.value! as string })} />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem lines="none">
                    <IonLabel className="font flex-center">to</IonLabel>
                    <IonInput type="date" value={filterDate.end} onIonChange={(e) => setFilterDate({ ...filterDate, end: e.detail.value! as string })} />
                  </IonItem>
                </IonCol>
                <IonCol size="12">
                  <IonButton expand="block" onClick={() => setFilterDate({ start: "", end: "" })}>
                    Clear dates
                  </IonButton>
                </IonCol>
              </>
            )}
          </IonRow>
          <IonRow>
            <IonCol className="mx-1">
              <IonText>
                <h4>
                  <b>Events</b>
                </h4>
              </IonText>
            </IonCol>
          </IonRow>
          {filteredEvents.map((i, idx) => (
            <IonRow key={i.eventId}>
              <IonCol>
                <EventCard event={i} idx={idx} adminControl={true} editButton={() => history.push(`edit/${i.eventId}`)} deleteButton={(e) => setDelTarget(e)} />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </>
      <IonModal isOpen={!!delTarget} onDidDismiss={() => setDelTarget(null)}>
        <IonHeader className="flex-row-between">
          <IonToolbar>
            <IonTitle>Delete {delTarget?.eventName}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid className="max-h">
            <IonRow className="max-h">
              <IonCol className="flex-center max-h">Confirm to delete {delTarget?.eventName} ?</IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonButton expand="block" color="danger" onClick={() => handleDelete(delTarget!)}>
            Confirm
          </IonButton>
          <IonButton expand="block" color="medium" onClick={() => setDelTarget(null)}>
            Cancel
          </IonButton>
        </IonFooter>
      </IonModal>
      <IonAlert
        message={message}
        header={"System Message"}
        isOpen={!!message}
        buttons={[
          {
            text: "OK",
            handler: () => setMessage(""),
          },
        ]}
      />
      <IonLoading isOpen={isLoading} />
    </Layout>
  );
}

export default ControlContent;
