import {
  IonAccordion,
  IonAccordionGroup,
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonModal,
  IonNote,
  IonRow,
  IonSpinner,
  IonText,
  IonToolbar,
} from "@ionic/react";

import { logoutStaff } from "app/firebase";
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "app/slices/authSlice";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {
  addCircleOutline,
  addCircleSharp,
  languageOutline,
  mailUnreadOutline,
  mailUnreadSharp,
  mapOutline,
  mapSharp,
  peopleOutline,
  peopleSharp,
  searchOutline,
  searchSharp,
  sendOutline,
  sendSharp,
  settingsOutline,
  settingsSharp,
  globeOutline,
} from "ionicons/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./Menu.css";
import FindKonger from "media/find-konger.png";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const Menu: React.FC = () => {
  const authUser = useAppSelector(selectAuthUser);
  const { t, i18n } = useTranslation();

  const generalTabs = [
    {
      title: t("m-evt"),
      pages: [
        {
          title: t("m-evt"),
          url: "/event-map",
          iosIcon: mapOutline,
          mdIcon: mapSharp,
        },
      ],
    },
    {
      title: t(`m-netEvt`),
      pages: [
        {
          title: t(`m-netEvt`),
          url: "/net-events",
          iosIcon: globeOutline,
          mdIcon: globeOutline,
        },
      ],
    },
    {
      title: t("m-OrgMan"),
      pages: [
        {
          title: t("m-Org"),
          url: "/organisations",
          iosIcon: searchOutline,
          mdIcon: searchSharp,
        },
      ],
    },
    {
      title: "Dev",
      pages: [
        {
          title: t("m-ContactDev"),
          url: "/contact-us",
          iosIcon: sendOutline,
          mdIcon: sendSharp,
        },
      ],
    },
  ];

  const adminTabs = [
    {
      title: t("m-evt"),
      pages: [
        {
          title: t("m-evt"),
          url: "/event-map",
          iosIcon: mapOutline,
          mdIcon: mapSharp,
        },
        {
          title: t("m-NewEvt"),
          url: "/new-event",
          iosIcon: addCircleOutline,
          mdIcon: addCircleSharp,
        },
        {
          title: t("m-Control"),
          url: "/control",
          iosIcon: settingsOutline,
          mdIcon: settingsSharp,
        },
      ],
    },
    {
      title: t(`m-netEvt`),
      pages: [
        {
          title: t(`m-netEvt`),
          url: "/net-events",
          iosIcon: globeOutline,
          mdIcon: globeOutline,
        },
        {
          title: t("m-newNetEvt"),
          url: "/new-net-event",
          iosIcon: addCircleOutline,
          mdIcon: addCircleSharp,
        },
      ],
    },
    {
      title: t("m-OrgMan"),
      pages: [
        {
          title: t("m-Org"),
          url: "/organisations",
          iosIcon: searchOutline,
          mdIcon: searchSharp,
        },
      ],
    },
    {
      title: t("m-StaffMan"),
      pages: [
        {
          title: t("m-StaffEnq"),
          url: "/send-staff-enquiry",
          iosIcon: sendOutline,
          mdIcon: sendSharp,
        },
      ],
    },
    {
      title: "Dev",
      pages: [
        {
          title: t("m-ContactDev"),
          url: "/contact-us",
          iosIcon: sendOutline,
          mdIcon: sendSharp,
        },
      ],
    },
  ];

  const allAppPages: AppPage[] = [
    {
      title: t("m-evt"),
      url: "/event-map",
      iosIcon: mapOutline,
      mdIcon: mapSharp,
    },
    {
      title: t(`m-netEvt`),
      url: "/net-events",
      iosIcon: globeOutline,
      mdIcon: globeOutline,
    },
    {
      title: t("m-NewEvt"),
      url: "/new-event",
      iosIcon: addCircleOutline,
      mdIcon: addCircleSharp,
    },
    {
      title: t("m-newNetEvt"),
      url: "/new-net-event",
      iosIcon: addCircleOutline,
      mdIcon: addCircleSharp,
    },
    {
      title: t("m-Control"),
      url: "/control",
      iosIcon: settingsOutline,
      mdIcon: settingsSharp,
    },
    {
      title: t("m-Org"),
      url: "/organisations",
      iosIcon: searchOutline,
      mdIcon: searchSharp,
    },
    {
      title: t("m-NewOrg"),
      // url: "/new-organisation",
      url: "/org-manage/new",
      iosIcon: addCircleOutline,
      mdIcon: addCircleSharp,
    },
    {
      title: t("m-OrgMan"),
      url: "/org-manage",
      iosIcon: settingsOutline,
      mdIcon: settingsSharp,
    },
    {
      title: t("m-StaffMan"),
      url: "/staff-management",
      iosIcon: peopleOutline,
      mdIcon: peopleSharp,
    },
    {
      title: t("m-StaffEnq"),
      url: "/send-staff-enquiry",
      iosIcon: sendOutline,
      mdIcon: sendSharp,
    },
    {
      title: t("m-ContactDev"),
      url: "/contact-us",
      iosIcon: sendOutline,
      mdIcon: sendSharp,
    },
    {
      title: t("m-ReadStaffEnq"),
      url: "/read-staff-enquiries",
      iosIcon: mailUnreadOutline,
      mdIcon: mailUnreadSharp,
    },
    {
      title: t("m-ReadDevTeamMsg"),
      url: "/dev-messages",
      iosIcon: mailUnreadOutline,
      mdIcon: mailUnreadSharp,
    },
  ];

  const allTabsAndPages = [
    {
      title: t("m-evt"),
      pages: [
        {
          title: t("m-evt"),
          url: "/event-map",
          iosIcon: mapOutline,
          mdIcon: mapSharp,
        },
        {
          title: t("m-NewEvt"),
          url: "/new-event",
          iosIcon: addCircleOutline,
          mdIcon: addCircleSharp,
        },
        {
          title: t("m-Control"),
          url: "/control",
          iosIcon: settingsOutline,
          mdIcon: settingsSharp,
        },
      ],
    },
    {
      title: t(`m-netEvt`),
      pages: [
        {
          title: t(`m-netEvt`),
          url: "/net-events",
          iosIcon: globeOutline,
          mdIcon: globeOutline,
        },
        {
          title: t("m-newNetEvt"),
          url: "/new-net-event",
          iosIcon: addCircleOutline,
          mdIcon: addCircleSharp,
        },
      ],
    },
    {
      title: t("m-announcements"),
      pages: [
        {
          title: t("m-announcements"),
          url: "/announcements",
          iosIcon: mapOutline,
          mdIcon: mapSharp,
        },
        {
          title: t("m-newAnnouncement"),
          url: "/new-announcement",
          iosIcon: addCircleOutline,
          mdIcon: addCircleSharp,
        },
      ],
    },
    {
      title: t("m-OrgMan"),
      pages: [
        {
          title: t("m-Org"),
          url: "/organisations",
          iosIcon: searchOutline,
          mdIcon: searchSharp,
        },
        {
          title: t("m-NewOrg"),
          // url: "/new-organisation",
          url: "/org-manage/new",
          iosIcon: addCircleOutline,
          mdIcon: addCircleSharp,
        },
        {
          title: t("m-OrgMan"),
          url: "/org-manage",
          iosIcon: settingsOutline,
          mdIcon: settingsSharp,
        },
      ],
    },
    {
      title: t("m-StaffMan"),
      pages: [
        {
          title: t("m-StaffMan"),
          url: "/staff-management",
          iosIcon: peopleOutline,
          mdIcon: peopleSharp,
        },

        {
          title: t("m-StaffEnq"),
          url: "/send-staff-enquiry",
          iosIcon: sendOutline,
          mdIcon: sendSharp,
        },
      ],
    },
    {
      title: "Dev",
      pages: [
        {
          title: t("m-ContactDev"),
          url: "/contact-us",
          iosIcon: sendOutline,
          mdIcon: sendSharp,
        },
        {
          title: t("m-ReadStaffEnq"),
          url: "/read-staff-enquiries",
          iosIcon: mailUnreadOutline,
          mdIcon: mailUnreadSharp,
        },
        {
          title: t("m-ReadDevTeamMsg"),
          url: "/dev-messages",
          iosIcon: mailUnreadOutline,
          mdIcon: mailUnreadSharp,
        },
      ],
    },
  ];

  const [logoutModal, setLogoutModal] = useState(false);
  const [resetPwModal, setResetPwModal] = useState(false);
  const [sendingResetPw, setSendingResetPw] = useState("idle");
  // const [rotate, setRotate] = useState(false);
  const location = useLocation();

  const handleLogout = async () => {
    await logoutStaff();
    window.location.assign(`/login`);
  };

  const handleSendResetPw = async () => {
    if (!authUser) {
      return;
    }
    setSendingResetPw("sending");
    const auth = getAuth();
    await sendPasswordResetEmail(auth, authUser.email);
    setSendingResetPw("sent");
  };

  // useEffect(() => {
  //   if (rotate) {
  //     setTimeout(() => {
  //       setRotate(false);
  //     }, 5000);
  //   } else {
  //     setTimeout(() => {
  //       setRotate(true);
  //     }, 5000);
  //   }
  // }, [rotate]);

  const renderTab = authUser?.role === "super-admin" ? allTabsAndPages : authUser?.role !== "guest" ? [...adminTabs] : [...generalTabs];

  return (
    <IonMenu contentId="main" type="overlay" hidden={!authUser}>
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader className="ion-padding-horizontal">
            <img src={FindKonger} alt={FindKonger} style={{ maxWidth: "max(200px, 50%)" }} />
            {/* <IonCol>
              <img src={hong} alt={hong} style={{ transition: "all 0.8s cubic-bezier(.58,-0.46,.26,1.39)", transform: rotate ? "rotate(0deg)" : "rotate(-90deg)" }} />
            </IonCol>
            <IonCol>
              <img src={kong} alt={kong} style={{ transition: "all 0.8s cubic-bezier(.58,-0.46,.26,1.39)", transform: rotate ? "rotate(0deg)" : "rotate(-90deg)", transitionDelay: "0.2s" }} />
            </IonCol> */}
          </IonListHeader>
          <IonRow className="ion-text-end ion-padding">
            <IonCol size="12" className="ion-text-start">
              <IonText>{t("hkerMap")}</IonText>
            </IonCol>
            <IonCol>
              <IonNote>by Triologic Ltd</IonNote>
            </IonCol>
          </IonRow>
          {/* <IonRow>
            <IonCol size="12" className="ion-text-center">
              <IonText>{t("hkerMap")}</IonText>
            </IonCol>
            <IonCol className="ion-text-end">
              <IonNote>by Triologic Ltd</IonNote>
            </IonCol>
          </IonRow> */}

          {/* {renderTab.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? "selected" : ""} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })} */}
          <IonAccordionGroup>
            {renderTab.map((tabs) => (
              <IonAccordion key={tabs.title} value={tabs.title}>
                <IonItem slot="header">
                  <IonText>{tabs.title}</IonText>
                </IonItem>
                {tabs.pages.map((appPage, idx) => (
                  <IonItem slot="content" key={appPage.url} lines="none">
                    <IonMenuToggle autoHide={false} style={{ width: "100%" }}>
                      <IonItem className={location.pathname === appPage.url ? "selected" : ""} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                        <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  </IonItem>
                ))}
              </IonAccordion>
            ))}
          </IonAccordionGroup>
        </IonList>
      </IonContent>
      <IonFooter color="medium">
        <IonToolbar>
          <IonButton
            color="medium"
            expand="block"
            fill="clear"
            onClick={() => {
              const newLang = i18n.language === "zh" ? "en" : "zh";
              i18n.changeLanguage(newLang);
              localStorage.setItem("lang", newLang);
            }}
          >
            <IonIcon icon={languageOutline} slot="start" />
            <IonLabel slot="end">{t(`lang`)}</IonLabel>
          </IonButton>
          <IonButton expand="block" color={"medium"} fill="clear" className="ion-margin-bottom" onClick={() => setResetPwModal(true)}>
            {t("resetPw")}
          </IonButton>
          <IonButton expand="block" color="danger" onClick={() => setLogoutModal(true)}>
            Logout
          </IonButton>
        </IonToolbar>
      </IonFooter>
      <IonAlert
        message={"Confirm to logout?"}
        isOpen={logoutModal}
        onDidDismiss={() => setLogoutModal(false)}
        buttons={[
          { text: "Logout", handler: () => handleLogout() },
          { text: "Cancel", handler: () => setLogoutModal(false) },
        ]}
      />
      <IonModal isOpen={resetPwModal} backdropDismiss={false} onDidDismiss={() => setResetPwModal(false)}>
        <IonContent>
          <IonCard>
            <IonCardContent>
              <IonText class="ion-text-center ion-padding ion-margin">
                <p>
                  {sendingResetPw === "idle"
                    ? `Confirm sending reset password link to your email?`
                    : sendingResetPw === "sending"
                    ? "Sending..."
                    : `Reset password link has been sent to ${authUser?.email}!`}
                </p>
              </IonText>
              {sendingResetPw === "sent" ? (
                <>
                  <IonButton
                    expand="block"
                    onClick={() => {
                      setResetPwModal(false);
                      setSendingResetPw("idle");
                    }}
                  >
                    Finish
                  </IonButton>
                </>
              ) : sendingResetPw === "sending" ? (
                <>
                  <IonSpinner name={"dots"} />
                </>
              ) : (
                <>
                  <IonButton expand="block" className="ion-margin-bottom" onClick={handleSendResetPw}>
                    Send
                  </IonButton>
                  <IonButton expand="block" color={"danger"} fill="clear" onClick={() => setResetPwModal(false)}>
                    Cancel
                  </IonButton>
                </>
              )}
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>
    </IonMenu>
  );
};

export default Menu;
