import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonList, IonLoading, IonRow, IonText } from "@ionic/react";
import { collection, getDocs, getFirestore, orderBy, query } from "firebase/firestore";
import { pencilOutline, searchOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { IOrgDetails } from "../app/variables";
import { Layout } from "../pages/Page";
import { InfoFields } from "./OrgMapPage";
import { arrToStr, handleDistName } from "helpers/arrayToString";
import { useTranslation } from "react-i18next";

function OrgManagement() {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orgDetails, setOrgDetails] = useState<IOrgDetails[]>([]);
  const history = useHistory();

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const q = query(collection(getFirestore(), `organizations`), orderBy("organizationName", "desc"));
      const sss = await getDocs(q);
      let temp: IOrgDetails[] = [];
      sss.forEach((i) => {
        temp.push({ ...i.data(), id: i.id } as IOrgDetails);
      });
      setOrgDetails(temp);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return (
    <Layout name={t("m-OrgMan")}>
      <br />
      <IonRow>
        <IonCol size="12">
          <IonInput placeholder="Filter by district name / organization name" value={searchInput} className="inputBorder" onIonChange={(e) => setSearchInput(e.detail.value!)}>
            <IonIcon icon={searchOutline} />
          </IonInput>
        </IonCol>
      </IonRow>
      <IonGrid>
        {orgDetails
          .filter((org) => arrToStr(org.districts).toLowerCase().includes(searchInput.toLowerCase()) || org.organizationName.toLowerCase().includes(searchInput.toLowerCase()))
          .map((i, idx) => (
            <IonCard key={idx + i.organizationName} className="ion-no-padding">
              <IonCardHeader className="ion-no-padding">
                <IonItem lines="none">
                  <IonText>
                    <h5>
                      <b>{`${i.organizationName}`}</b>
                    </h5>
                  </IonText>
                  <div slot="end" className="hidden md:flex">
                    <IonButton onClick={() => history.push(`/org-manage/${i.id}`)}>
                      <IonIcon slot="icon-only" icon={pencilOutline} size="small" />
                      <IonText className="mx-1">Edit</IonText>
                    </IonButton>
                  </div>
                </IonItem>
              </IonCardHeader>
              <OrgCardContent org={i} isAdmin />
            </IonCard>
          ))}
      </IonGrid>
      <IonLoading isOpen={isLoading} message={"Loading"} />
    </Layout>
  );
}

export default OrgManagement;

export const OrgCardContent = ({ org, isAdmin, showName }: { org: IOrgDetails; isAdmin?: boolean; showName?: boolean }) => {
  const history = useHistory();
  return (
    <IonCardContent className="ion-no-padding">
      {isAdmin && (
        <IonItem lines="none" className="flex sm:hidden">
          <IonButton expand="block" onClick={() => history.push(`/org-manage/${org.id}`)}>
            <IonIcon icon={pencilOutline} size="small" />
            <IonText className="mx-1">Edit</IonText>
          </IonButton>
        </IonItem>
      )}
      <IonList>
        {showName && <InfoFields name={"Organisation name"} value={org.organizationName} />}
        <InfoFields name={"Email"} value={org.email1} tag={org.email1_label} />
        {!!org.districts.length && <InfoFields name={"District(s)"} value={arrToStr(org.districts.map((i) => handleDistName(i)))} />}
        {!!org.email2 && <InfoFields name={"Email"} value={org.email2} tag={org.email2_label} />}
        {!!org.email3 && <InfoFields name={"Email"} value={org.email3} tag={org.email3_label} />}
        {!!org.email4 && <InfoFields name={"Email"} value={org.email4} tag={org.email4_label} />}
        {!!org.website && <InfoFields name={"Website"} value={org.website} />}
        {!!org.facebook && <InfoFields name={"Facebook"} value={org.facebook} />}
        {!!org.instagram && <InfoFields name={"Instagram"} value={org.instagram} />}
        {!!org.twitter && <InfoFields name={"Twitter"} value={org.twitter} />}
        {!!org.telegram && <InfoFields name={"Telegram"} value={org.telegram} />}
        {!!org.youtube && <InfoFields name={"Youtube"} value={org.youtube} />}
        {!!org.eventbrite && <InfoFields name={"Eventbrite"} value={org.eventbrite} />}
      </IonList>
      <br />
    </IonCardContent>
  );
};
