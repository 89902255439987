import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { useState } from "react";
import { loginStaff } from "../app/firebase";
import axios from "axios";

function LoginPage() {
  const [userCred, setUserCred] = useState({ email: "", pw: "" });
  const [loading, setLoading] = useState(false);
  const [showMFAModal, setShowMFAModal] = useState(false);
  const [presentToast] = useIonToast();
  const [uid, setUid] = useState("");
  const {push} = useIonRouter();

  const handleSubmit = async () => {
    setLoading(true);
    const res = await loginStaff(userCred.email, userCred.pw, true);
    if (typeof res !== "string" && res.message === "pending_mfa") {
      setLoading(false);
      setShowMFAModal(true);
      setUid(res.uid);
    } else {
      setLoading(false);
      presentToast({
        header: `Error`,
        message: `${res}`,
        duration: 5000,
        position: "bottom",
        color: "danger",
      });
    }

    // if (res) {
    //   window.location.assign(`/event-map`);
    // } else {
    //   setLoading(false);
    //   presentToast({
    //     header: `Error`,
    //     message: `${res}`,
    //     duration: 5000,
    //     position: "bottom",
    //     color: "danger",
    //   });
    // }
  };

  return (
    <IonPage>
      <IonContent style={{ overflowY: "hidden" }}>
        <IonGrid style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
          <IonRow>
            <IonCol sizeXs="0" sizeSm="0" sizeMd="1" sizeLg="3" sizeXl="3"></IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="6" sizeXl="6">
              <IonText className="ion-text-center">
                <h1>HKers in UK</h1>
              </IonText>
              <IonList>
                <IonItem>
                  <IonLabel position="floating" className="font">
                    Email
                  </IonLabel>
                  <IonInput
                    type="email"
                    autocomplete="username"
                    value={userCred.email}
                    onIonChange={(e) => {
                      setUserCred((v) => Object.assign({ ...v }, { email: e.detail.value! }));
                    }}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating" className="font">
                    Password
                  </IonLabel>
                  <IonInput
                    type="password"
                    value={userCred.pw}
                    autocomplete="current-password"
                    onIonChange={(e) => {
                      setUserCred((v) => Object.assign({ ...v }, { pw: e.detail.value! }));
                    }}
                  />
                </IonItem>
                <br />
                <IonButton expand="block" onClick={handleSubmit} className="ion-margin" >
                  Login
                </IonButton>
                <IonButton
                  expand="block" fill="clear"
                  className="ion-margin"
                  onClick={() => push(`/forgot-password`)}
                >Forgot Password?</IonButton>
              </IonList>
            </IonCol>
            <IonCol sizeXs="0" sizeSm="0" sizeMd="1" sizeLg="3" sizeXl="3"></IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={loading} spinner={"dots"} message={"Signing in"} />

      <MFAModal open={showMFAModal} setOpen={setShowMFAModal} setLoading={setLoading} uid={uid} userCred={userCred} />
    </IonPage>
  );
}

export default LoginPage;

// modal for MFA
interface MFAModalProps {
  open: boolean;
  setOpen: (b: boolean) => void;
  setLoading: (b: boolean) => void;
  userCred: { email: string; pw: string };
  uid: string;
}

const MFAModal: React.FC<MFAModalProps> = ({ open, setOpen, setLoading, userCred, uid }) => {
  const [passcode, setPasscode] = useState("");

  const handleSubmit = async () => {
    if (!passcode) {
      return;
    }
    setLoading(true);
    // check with Server if the passcode is good
    const check = await axios.post(`${process.env.REACT_APP_FUNCTIONS_API}/checkMFA`, { uid, code: passcode });

    if (check.data !== "ok") {
      console.log("MFA code error");
      // do some UI here

      return;
    }

    const res = await loginStaff(userCred.email, userCred.pw);
    if (res === "success") {
      window.location.assign(`/event-map`);
    }
  };

  return (
    <>
      <IonModal isOpen={open} onDidDismiss={() => setOpen(false)} backdropDismiss={false}>
        <IonHeader>
          <IonToolbar>
            <IonButton slot="end" fill="clear" onClick={() => setOpen(false)}>
              <IonLabel>Cancel</IonLabel>
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonCardContent>
              <IonLabel>
                <h3>Please Check your email and provide the one time passcode</h3>
              </IonLabel>
              <IonList>
                <IonItem color={"medium"}>
                  <IonLabel position="stacked">Passcode</IonLabel>
                  <IonInput value={passcode} onIonChange={(e) => setPasscode(e.detail.value!)} />
                </IonItem>
              </IonList>
              <IonButton expand="block" onClick={handleSubmit}>
                Submit
              </IonButton>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>
    </>
  );
};
