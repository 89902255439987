import { IonButton, IonCard, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonIcon, IonItem, IonList, IonLoading, IonText, useIonRouter } from "@ionic/react";
import { chevronUpCircle, closeOutline, pencilOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllAnnouncements } from "../app/firebase";
import { IAnnouncement, IOrgDetails } from "../app/variables";
import { Layout } from "../pages/Page";
import { OrgFieldDisplay, OrgModal } from "./MapContent";
import dayjs from "dayjs";
import EvtPosterDisplay from "./EvtPosterDisplay";

export default function AnnouncementList() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [dataState, setDataState] = useState<IAnnouncement[]>([]);
  const [viewOrg, setViewOrg] = useState<IOrgDetails | null>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getAllAnnouncements();
      if (res && res.msg === "success" && !!res.data.length) {
        setDataState(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return (
    <Layout name={t("m-announcements")}>
      <IonContent ref={contentRef}>
        <IonGrid>
          {dataState.map((i, idx) => (
            <AnnouncementCard ann={i} key={i.id + "announcement" + idx} viewOrg={viewOrg} setViewOrg={setViewOrg} />
          ))}
        </IonGrid>
        <IonFab slot="fixed" vertical="bottom" horizontal="end" className="ion-margin ion-padding">
          <IonFabButton onClick={() => contentRef.current?.scrollToTop(600)}>
            <IonIcon icon={chevronUpCircle} />
          </IonFabButton>
        </IonFab>
        <br />
        <br />
        <br />
        <OrgModal viewOrg={viewOrg} setViewOrg={setViewOrg} />
      </IonContent>
      <IonLoading isOpen={isLoading} message={"Loading"} />
    </Layout>
  );
}

interface AnnouncementCardProps {
  ann: IAnnouncement;
  adminControl?: boolean;
  editButton?: () => void;
  deleteButton?: (args0: IAnnouncement) => void;
  viewOrg?: IOrgDetails | null;
  setViewOrg?: (args0: IOrgDetails | null) => void;
}

export const AnnouncementCard = (props: AnnouncementCardProps) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const { ann, adminControl = false } = props;

  return (
    <>
      <IonCard id={ann.id} className="ion-padding-horizontal" style={{ transition: "all 0.8s" }}>
        <IonGrid>
          <IonList>
            <IonItem button lines="none" style={{ transition: "all 0.8s" }} onClick={() => router.push(`/announcement/${ann.id}`)}>
              <IonCol className="ion-text-center">
                <IonText>
                  <h5>
                    <b>{ann.title}</b>
                  </h5>
                </IonText>
              </IonCol>

              {adminControl && (
                <>
                  <IonButton slot="end" color="primary" onClick={props.editButton}>
                    <IonIcon icon={pencilOutline} />
                  </IonButton>
                  <IonButton
                    className="ion-no-margin"
                    slot="end"
                    color="danger"
                    onClick={() => {
                      if (props.deleteButton) props.deleteButton(ann);
                    }}
                  >
                    <IonIcon icon={closeOutline} />
                  </IonButton>
                </>
              )}
            </IonItem>
            {ann.img && !!ann.img.length && <EvtPosterDisplay eventPoster={ann.img} />}
            {ann.organisation && <OrgFieldDisplay name={t("host") + " : "} org={ann.organisation} setViewOrg={props.setViewOrg} />}
            {/* {ann.validTill && <EvtDateDisplay eventDateType="single" eventDates={[{ start: ann.validTill, end: ann.validTill }]} />} */}
            {ann.validTill && (
              <IonItem lines="none">
                <IonText>
                  <b>{`Deadline : `}</b>
                  {` ${dayjs(ann.validTill).format("YYYY MMM DD - HH:mm")}`}
                </IonText>
              </IonItem>
            )}
            {ann.externalLink && (
              <IonItem lines="none">
                <IonText>
                  <b>{`Website / external link : `}</b>
                </IonText>
                <IonText
                  className="ion-margin-horizontal"
                  style={{
                    cursor: "pointer",
                    textTransform: "lowercase",
                    textDecoration: "underline",
                  }}
                  color="primary"
                  onClick={() => {
                    if (ann.externalLink?.startsWith("http")) {
                      window.open(ann.externalLink);
                    } else {
                      window.open(`http://${ann.externalLink}`);
                    }
                  }}
                >
                  {ann.externalLink}
                </IonText>
              </IonItem>
            )}

            {!!ann.description && (
              <IonItem lines="none" className="ion-margin-top">
                <IonText>
                  <b>{`Description : `}</b>
                  <p style={{ whiteSpace: "pre-wrap" }}>{`${ann.description}`}</p>
                </IonText>
              </IonItem>
            )}
            {!!ann.coOrg?.organizationName && <OrgFieldDisplay name={"Co-organiser : "} org={ann.coOrg} setViewOrg={props.setViewOrg} />}
            {!!ann.supportOrg?.organizationName && <OrgFieldDisplay name={"Support organisation : "} org={ann.supportOrg} setViewOrg={props.setViewOrg} />}
            {!!ann.funder && (
              <IonItem lines="none">
                <IonText>
                  <b>{`Funder : `}</b>
                  {ann.funder}
                </IonText>
              </IonItem>
            )}

            <IonItem lines="none">
              <IonText>
                <b>{`Announcement date : `}</b>
                {dayjs(ann.createdAt).format("YYYY MMM DD - HH:mm")}
              </IonText>
            </IonItem>
          </IonList>
          <br />
          <br />
        </IonGrid>
      </IonCard>
    </>
  );
};
