import dayjs from "dayjs";
import { IContactDev, IContactAdmin, IStaff, EventDateFormat, IEventDetails, EventDateFormatInput, IOrgDetails, IIntEvent, IEventDetailsInput } from "./variables";

export const defaultContactDev: IContactDev = { id: "", fromEmail: "", subject: "", message: "", userIp: "", needReply: false, createdAt: new Date() };
export const defaultContactAdmin: IContactAdmin = { id: "new", admin: null, subject: "", message: "", userIp: "", createdAt: new Date() };
export const defaultNewStaff: IStaff = {
  id: "",
  email: "",
  communityType: "local",
  district: [],
  organisation: [],
  role: "organiser",
  disabled: false,
};

const dateStart = dayjs().startOf("D").toDate();
export const defaultNewDate: EventDateFormat = { start: dateStart, end: dateStart };

export const defaultLocationDetail: IEventDetails = {
  eventId: "",
  eventName: "",
  eventNameChi: "",
  // eventDates: [defaultNewDate],
  eventDateType: "single",
  geolocation: { lat: null, lng: null },
  organisation: null,
  website: "",
  eventAddress: {
    streetNo: "",
    country: "",
    adminArea1: "",
    adminArea2: "",
    postalTown: "",
    route: "",
    postcode: "",
  },
  description: "",
  descriptionChi: "",
  createdAt: new Date(),
  coOrg: null,
  supportOrg: null,
  funder: "",
  updatedAt: new Date(),
  updatedBy: null,
  lastDatetime: new Date(),
  eventDates: [],
  eventPoster: [],
};
const dateStartInput = dayjs().startOf("D").toDate().toISOString();
export const defaultNewDateInput: EventDateFormatInput = { start: dateStartInput, end: dateStartInput };

export const defaultLocationDetailInput: IEventDetailsInput = {
  eventId: "",
  eventName: "",
  eventDates: [defaultNewDateInput],
  eventDateType: "single",
  geolocation: { lat: null, lng: null },
  organisation: null,
  website: "",
  eventAddress: {
    streetNo: "",
    country: "",
    adminArea1: "",
    adminArea2: "",
    postalTown: "",
    route: "",
    postcode: "",
  },
  description: "",
  coOrg: null,
  supportOrg: null,
  funder: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  updatedBy: null,
  lastDatetime: new Date(),
};

export const defaultOrg: IOrgDetails = {
  id: "new",
  districts: [],
  region: [],
  organizationName: "",
  email1: "",
  email1_label: "General email",
  email2: "",
  email2_label: "Email #2",
  email3: "",
  email3_label: "Email #3",
  email4: "",
  email4_label: "Email #4",
  website: "",
  facebook: "",
  instagram: "",
  twitter: "",
  telegram: "",
  youtube: "",
  eventbrite: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  communityType: "local",
  logo: "",
  description: "",
  address: "",
  phone: "",
  signal: "",
  website2: "",
  website3: "",
  whatsapp: "",
};

export const defaultIntEvt: IIntEvent = {
  eventId: "",
  eventName: "",
  eventNameChi: "",
  coOrg: null,
  supportOrg: null,
  funder: "",
  eventDates: [defaultNewDateInput],
  eventDateType: "single",
  organisation: null,
  website: "",
  description: "",
  descriptionChi: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  updatedBy: null,
  lastDatetime: new Date(),
  eventPoster: [],
  meetingLink: "",
};
