import React from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonLoading,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import dayjs from "dayjs";
import { chevronUpCircle, closeOutline, pencilOutline } from "ionicons/icons";
// import { Overlay } from "pigeon-maps";
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "app/slices/authSlice";
import { arrToStr } from "helpers/arrayToString";
import i18n from "i18n";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllIntEvts } from "../app/firebase";
import { IIntEvent, IOrgDetails } from "../app/variables";
import { Layout } from "../pages/Page";
import { OrgFieldDisplay, OrgModal } from "./MapContent";
import EvtPosterDisplay from "./EvtPosterDisplay";
import EvtDateDisplay from "./EvtDateDisplay";

function NetEvents() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [intEvents, setIntEvents] = useState<IIntEvent[]>([]);
  const [viewOrg, setViewOrg] = useState<IOrgDetails | null>(null);
  const [copied, setCopied] = useState(false);
  const contentRef = useRef<HTMLIonContentElement>(null);

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getAllIntEvts();
      if (res && res.msg === "success" && !!res.data.length) {
        setIntEvents(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  return (
    <Layout name={t("m-netEvt")}>
      <IonContent ref={contentRef}>
        <IonGrid>
          {intEvents.map((i, idx) => (
            <NetEventCard event={i} color={copied ? "success" : "primary"} setCopied={setCopied} key={i.eventId + "intEvent" + idx} viewOrg={viewOrg} setViewOrg={setViewOrg} />
          ))}
        </IonGrid>
        <IonFab slot="fixed" vertical="bottom" horizontal="end" className="ion-margin ion-padding">
          <IonFabButton onClick={() => contentRef.current?.scrollToTop(600)}>
            <IonIcon icon={chevronUpCircle} />
          </IonFabButton>
        </IonFab>
        <br />
        <br />
        <br />
        <OrgModal viewOrg={viewOrg} setViewOrg={setViewOrg} />
      </IonContent>
      <IonLoading isOpen={isLoading} message={"Loading"} />
    </Layout>
  );
}

export default NetEvents;

interface NetEventCardProps {
  event: IIntEvent;
  color?: string;
  setCopied: (args: boolean) => void;
  adminControl?: boolean;
  editButton?: () => void;
  deleteButton?: (args0: IIntEvent) => void;
  viewOrg?: IOrgDetails | null;
  setViewOrg?: (args0: IOrgDetails | null) => void;
}

export const NetEventCard = (props: NetEventCardProps) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const authUser = useAppSelector(selectAuthUser);
  const { event, color = "primary", setCopied, adminControl = false } = props;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${event.eventName} - ${event.eventDates[0].start}\n${event.meetingLink}`);
    setCopied(true);
  };

  return (
    <>
      <IonCard id={event.eventId} className="ion-padding-horizontal" style={{ transition: "all 0.8s" }}>
        <IonGrid>
          <IonList>
            <IonItem button lines="none" style={{ transition: "all 0.8s" }} onClick={() => router.push(`/net-events/edit/${event.eventId}`)}>
              <IonCol className="ion-text-center">
                <IonText>
                  <h5>{i18n.language === "en" ? <b>{event.eventName}</b> : <b>{!!event.eventNameChi ? event.eventNameChi : event.eventName}</b>}</h5>
                </IonText>
              </IonCol>

              {adminControl && (
                <>
                  <IonButton slot="end" color="primary" onClick={props.editButton}>
                    <IonIcon icon={pencilOutline} />
                  </IonButton>
                  <IonButton
                    className="ion-no-margin"
                    slot="end"
                    color="danger"
                    onClick={() => {
                      if (props.deleteButton) props.deleteButton(event);
                    }}
                  >
                    <IonIcon icon={closeOutline} />
                  </IonButton>
                </>
              )}
            </IonItem>
            {event.eventPoster && !!event.eventPoster.length && <EvtPosterDisplay eventPoster={event.eventPoster} />}
            {event.organisation && <OrgFieldDisplay name={t("host") + " :"} org={event.organisation} setViewOrg={props.setViewOrg} />}
            <EvtDateDisplay eventDateType={event.eventDateType} eventDates={event.eventDates} />
            <IonItem lines="none">
              <IonText>
                <b>Event / Meeting link:</b>
              </IonText>
              <IonText
                className="ion-margin-horizontal"
                style={{
                  cursor: "pointer",
                  textTransform: "lowercase",
                  textDecoration: "underline",
                }}
                color="primary"
                onClick={() => {
                  if (event.website?.startsWith("http")) {
                    window.open(event.website);
                  } else {
                    window.open(`http://${event.website}`);
                  }
                }}
              >
                {event.meetingLink}
              </IonText>
            </IonItem>
            {!!event.website && (
              <IonItem lines="none">
                <IonText>
                  <b>Website:</b>
                </IonText>
                <IonText
                  className="ion-margin-horizontal"
                  style={{
                    cursor: "pointer",
                    textTransform: "lowercase",
                    textDecoration: "underline",
                  }}
                  color="primary"
                  onClick={() => {
                    if (event.website?.startsWith("http")) {
                      window.open(event.website);
                    } else {
                      window.open(`http://${event.website}`);
                    }
                  }}
                >
                  {event.website}
                </IonText>
              </IonItem>
            )}
            {!!event.description && (
              <IonItem lines="none" className="ion-margin-top">
                <IonText>
                  <b>{t("eventDes") + " :"}</b>
                  {i18n.language === "en" ? (
                    <p style={{ whiteSpace: "pre-wrap" }}>{`${event.description}`}</p>
                  ) : (
                    <p style={{ whiteSpace: "pre-wrap" }}>{`${!!event.descriptionChi ? event.descriptionChi : event.description}`}</p>
                  )}
                </IonText>
              </IonItem>
            )}
            {!!event.coOrg?.organizationName && <OrgFieldDisplay name={"Co-organiser:"} org={event.coOrg} setViewOrg={props.setViewOrg} />}
            {!!event.supportOrg?.organizationName && <OrgFieldDisplay name={"Support organisation:"} org={event.supportOrg} setViewOrg={props.setViewOrg} />}
          </IonList>
          <br />
          {!!authUser && authUser.role === "super-admin" && adminControl && (
            <IonAccordionGroup>
              <IonAccordion value={`${event.eventId}`}>
                <IonItem slot="header">
                  <IonText>
                    <b>Last updated information</b>
                  </IonText>
                </IonItem>
                <IonItem slot="content" lines="none">
                  <b>Updated at: </b>
                  <IonText className="ml-1">{dayjs(event.updatedAt).format("YYYY-MM-DD HH:mm")}</IonText>
                </IonItem>
                <IonItem slot="content" lines="none">
                  <b>Email: </b>
                  <IonText className="ml-1">{event.updatedBy?.email}</IonText>
                </IonItem>
                <IonItem slot="content" lines="none">
                  <b>Organisation(s): </b>
                  <IonText className="ml-1">
                    <p>{arrToStr(!!event.updatedBy?.organisation.length ? event.updatedBy?.organisation : []) || ""}</p>
                  </IonText>
                </IonItem>
                {event.updatedBy?.communityType === "nationwide" ? (
                  <IonItem slot="content" lines="none">
                    <b>Community Type: </b>
                    <IonText className="ml-1">{event.updatedBy?.communityType}</IonText>
                  </IonItem>
                ) : (
                  <IonItem slot="content" lines="none">
                    <b>District: </b>
                    <IonText className="ml-1">{!!event.updatedBy?.district.length ? arrToStr(event.updatedBy?.district) : ""}</IonText>
                  </IonItem>
                )}
              </IonAccordion>
            </IonAccordionGroup>
          )}
          <br />
          <IonRow>
            <IonCol className="ion-text-end">
              <IonButton size="default" color={color} onClick={color === "primary" ? copyToClipboard : () => {}}>
                {color === "primary" ? "Copy event details" : "Copied to clipboard!"}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
    </>
  );
};
