import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { IStaff } from "../app/variables";
import { useAppSelector } from "app/hooks";
import { selectAuthUserIsInit, selectAuthUser } from "app/slices/authSlice";

interface Props {
  children: any;
}

const authPaths = [`login`, `sign-up`, 'forgot-password'];

const AuthCheck: React.FC<Props> = (props) => {
  const authUserIsInit: boolean = useAppSelector(selectAuthUserIsInit);
  const authUser: IStaff | null = useAppSelector(selectAuthUser);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!authUserIsInit) {
      return;
    }

    if (authPaths.filter((path) => location.pathname.includes(path)).length > 0) {
      if (authUser) {
        history.replace("/");
        return;
      }
    } else {
      if (!authUser) {
        history.replace("/login");
        return;
      }
    }
  }, [authUser, authUserIsInit, location, history]);

  return props.children;
};

export default AuthCheck;
