import { IonModal, IonContent, IonGrid, IonRow, IonCol, IonInput, IonChip, IonText, IonItem, IonLabel } from "@ionic/react";
import { IOrgDetails, IEventDetailsInput, IIntEvent, IAnnouncement } from "app/variables";
import { arrToStr } from "helpers/arrayToString";
import BackFooter from "./BackFooter";
import { ModalHeader } from "./InputComponents";
// import { OrgFieldTypes } from "./NewIntEvent";
import { useCallback, useState } from "react";

export type OrgSelectModalType = "event" | "int-event" | "announcement";
export type OrgFieldTypes = "host" | "co-org" | "support-org" | null;

export interface OrgSelectModalProps {
  orgSelectModalType: EvtOrgSelect | IntOrgSelect | AnnouncementOrgSelect;
  orgType: OrgFieldTypes;
  setOrgType: (args0: OrgFieldTypes) => void;
  allOrgs: IOrgDetails[];
}

export interface EvtOrgSelect {
  modalType: "event";
  newEvt: IEventDetailsInput;
  setNewEvt: (args0: IEventDetailsInput) => void;
}

export interface IntOrgSelect {
  modalType: "int-event";
  newIntEvt: IIntEvent;
  setNewIntEvt: (args0: IIntEvent) => void;
}

export interface AnnouncementOrgSelect {
  modalType: "announcement";
  newAnnouncement: IAnnouncement;
  setNewAnnouncement: (args0: IAnnouncement) => void;
}

export default function OrgSelectModal(props: OrgSelectModalProps) {
  // const { orgSelectModalType, orgType, setOrgType, orgSearch, setOrgSearch, allOrgs, newEvt, setNewEvt, newIntEvt, setNewIntEvt } = props;
  const { orgSelectModalType, orgType, setOrgType, allOrgs } = props;
  const [orgSearch, setOrgSearch] = useState("");

  const handleOnSelect = useCallback(
    (org: IOrgDetails) => {
      if (orgSelectModalType.modalType === "event") {
        const obj: IEventDetailsInput =
          orgType === "host"
            ? { ...orgSelectModalType.newEvt, organisation: org }
            : orgType === "co-org"
            ? { ...orgSelectModalType.newEvt, coOrg: org }
            : { ...orgSelectModalType.newEvt, supportOrg: org };
        orgSelectModalType.setNewEvt(obj);
      }

      if (orgSelectModalType.modalType === "int-event") {
        const obj: IIntEvent =
          orgType === "host"
            ? { ...orgSelectModalType.newIntEvt, organisation: org }
            : orgType === "co-org"
            ? { ...orgSelectModalType.newIntEvt, coOrg: org }
            : { ...orgSelectModalType.newIntEvt, supportOrg: org };
        orgSelectModalType.setNewIntEvt(obj);
      }

      if (orgSelectModalType.modalType === "announcement") {
        const obj: IAnnouncement =
          orgType === "host"
            ? { ...orgSelectModalType.newAnnouncement, organisation: org }
            : orgType === "co-org"
            ? { ...orgSelectModalType.newAnnouncement, coOrg: org }
            : { ...orgSelectModalType.newAnnouncement, supportOrg: org };
        orgSelectModalType.setNewAnnouncement(obj);
        setOrgType(null);
      }
      setOrgType(null);
    },
    [orgSelectModalType, orgType, setOrgType]
  );

  return (
    <IonModal isOpen={!!orgType} onDidDismiss={() => setOrgType(null)}>
      <ModalHeader title="Select organisation(s)" closeButton closeFunction={() => setOrgType(null)} />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonInput placeholder={"Filter organisation"} value={orgSearch} className="inputBorder" onIonChange={(e) => setOrgSearch(e.detail.value!)} />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {allOrgs
                .filter((i) => i.organizationName.toLowerCase().includes(orgSearch.toLowerCase()) || arrToStr(i.districts).toLowerCase().includes(orgSearch.toLowerCase()))
                .map((org) => (
                  <IonChip key={org.id} color="tertiary" onClick={() => handleOnSelect(org)}>
                    <IonText>{org.organizationName}</IonText>
                  </IonChip>
                ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <BackFooter onClick={() => setOrgType(null)} />
    </IonModal>
  );
}

export interface OrgSelectProps {
  name: string;
  value: string;
  onFocus: () => void;
}

export const OrgSelect = (props: OrgSelectProps) => {
  return (
    <IonItem>
      <IonLabel position="floating" className="font">
        {props.name}
      </IonLabel>
      <IonInput value={props.value} onIonFocus={props.onFocus} />
    </IonItem>
  );
};
