export const allRegions = [
  "Yorkshire and The Humber",
  "South West",
  "Scotland",
  "North East",
  "South East",
  "North West",
  "East Midlands",
  "Northern Ireland",
  "East of England",
  "Wales",
  "London",
  "West Midlands",
];
