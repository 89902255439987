import { IonAlert, IonButton, IonCol, IonContent, IonFooter, IonGrid, IonItem, IonLabel, IonList, IonLoading, IonModal, IonRow, IonText } from "@ionic/react";
import dayjs from "dayjs";
import { collection, deleteDoc, doc, getDocs, getFirestore, orderBy, query } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { IContactAdmin } from "../app/variables";
import { fromDB } from "../helpers/date";
import { Layout } from "../pages/Page";
import { MsgModalRow } from "./DevMessages";
import { ModalHeader } from "./InputComponents";
import { arrToStr } from "helpers/arrayToString";
import { useTranslation } from "react-i18next";

function ReadStaffEnquiries() {
  const [enquiries, setEnquiries] = useState<IContactAdmin[]>([]);
  const [viewEnquiry, setViewEnquiry] = useState<IContactAdmin | null>(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    const db = getFirestore();
    const q = query(collection(db, `staffEnquiries`), orderBy("createdAt", "desc"));
    try {
      const sss = await getDocs(q);
      let temp: IContactAdmin[] = [];
      sss.forEach((ss) => {
        temp.push({ id: ss.id, ...fromDB(ss.data()) } as IContactAdmin);
      });
      setEnquiries(temp);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  const handleDelete = useCallback(async () => {
    if (!viewEnquiry) return;
    setIsLoading(true);
    const db = getFirestore();
    try {
      await deleteDoc(doc(db, "staffEnquiries", viewEnquiry.id));
      const idx = enquiries.indexOf(viewEnquiry);
      let temp: IContactAdmin[] = [...enquiries];
      temp.splice(idx, 1);
      setEnquiries(temp);
      setViewEnquiry(null);
      setMessage("Deleted");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [enquiries, viewEnquiry]);

  return (
    <Layout name={t("m-ReadStaffEnq")}>
      <IonGrid>
        <IonList>
          {!enquiries.length ? (
            <IonRow>
              <IonCol className="ion-text-center">
                <IonText color="medium">NOTHING is a good thing!</IonText>
              </IonCol>
            </IonRow>
          ) : (
            enquiries.map((i) => (
              <IonItem key={i.id} button onClick={() => setViewEnquiry(i)}>
                <IonLabel>
                  <h3 className="ion-text-wrap">{i.admin ? i.admin.organisation : "Web User"}</h3>
                  <p>{i.subject}</p>
                  <p>{dayjs(i.createdAt).format("YYYY-MM-DD HH:mm")}</p>
                </IonLabel>
              </IonItem>
            ))
          )}
        </IonList>
      </IonGrid>
      <IonModal isOpen={!!viewEnquiry ? true : false} onDidDismiss={() => setViewEnquiry(null)}>
        <ModalHeader title={viewEnquiry?.subject || ""} closeButton closeFunction={() => setViewEnquiry(null)} />
        <IonContent>
          <IonGrid>
            <IonRow>
              <MsgModalRow name={"Email"} value={`${viewEnquiry?.admin?.email || ""}`} />
              {viewEnquiry && viewEnquiry.admin ? (
                <MsgModalRow name={"Organisation(s)"} value={viewEnquiry?.admin?.organisation ? arrToStr(viewEnquiry?.admin?.organisation) : ""} />
              ) : (
                <MsgModalRow name={"Organisation(s)"} value={"Web User"} />
              )}
              <MsgModalRow name={"District(s)"} value={viewEnquiry?.admin?.district ? arrToStr(viewEnquiry?.admin?.district) : ""} />
              <MsgModalRow name={"Subject"} value={viewEnquiry?.subject || ""} />
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonText>
                  <b>Message : </b>
                </IonText>
              </IonCol>
              <IonCol>
                <IonText style={{ whiteSpace: "pre-wrap" }}>{viewEnquiry?.message || ""}</IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonRow>
            <IonCol>
              <IonButton color="danger" onClick={handleDelete} expand="block">
                Delete
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonModal>
      <IonLoading isOpen={isLoading} message={"Loading"} />
      <IonAlert message={message} isOpen={!!message} buttons={[{ text: "OK", handler: () => setMessage("") }]} />
    </Layout>
  );
}

export default ReadStaffEnquiries;
