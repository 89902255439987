import { configureStore } from "@reduxjs/toolkit";
// import authUserReducer from './slices/authUserSlice'
import authSlice from "./slices/authSlice";
import orgSlice from "./slices/orgSlices";

export const store = configureStore({
  reducer: {
    authUser: authSlice,
    orgs: orgSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
